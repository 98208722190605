import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link as BaseLink} from 'react-router-dom';
import {typography, colors} from '../styles';

const baseStyles = {
  fontSize: typography.links.primary,
  fontWeight: typography.weight.bolder,
  textTransform: 'uppercase',
  textDecoration: 'none',
  letterSpacing: 0,
  border: 'none',
  backgroundColor: 'transparent',
};

export const Link = ({href, active, children, color, className}) => (
  <BaseLink
    to={href}
    style={{
      ...baseStyles,
      color: active ? colors.brand.darkBlue : color || colors.text.base,
    }}
    className={className}
  >
    {children}
  </BaseLink>
);

Link.propTypes = {
  href: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
};

export const Sublink = ({href, children}) => (
  <BaseLink
    to={href}
    style={{
      color: '#FFF',
      fontSize: typography.links.secondary,
      fontWeight: typography.weight.bold,
      textTransform: 'uppercase',
      display: 'block',
    }}
  >
    {children}
  </BaseLink>
);

Sublink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
};

export const Dropdown = ({name, children, active, color}) => {
  const [expanded, setExpanded] = useState(false);
  

  useEffect(() => {
    const closeDropdown = () => setExpanded(false);

    document.body.addEventListener('click', closeDropdown);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  return (
    <div className="dropdown d-inline-block">
      <button
        id={`${name}-dropdown-link`}
        className={`w-auto btn btn-link dropdown-toggle ${expanded && 'show'}`}
        type="button"
        onClick={() => setExpanded((current) => !current)}
        style={{
          ...baseStyles,
          color: active ? colors.brand.darkBlue : color || colors.text.base,
        }}
      >
        {name}
      </button>

      <ul
        className={`dropdown-menu ${expanded && 'show'}`}
        aria-labelledby={`${name}-dropdown-link`}
      >
        {children}
      </ul>
    </div>
  );
};

Dropdown.Link = ({href, children, active}) => (
  <li>
    <a
      className="dropdown-item"
      href={href}
      style={{
        color: active ? colors.brand.darkBlue : colors.text.base,
        fontWeight: typography.weight.bold,
      }}
    >
      {children}
    </a>
  </li>
);
