import DEFAULT_VEHICLES, {findArchetype} from './data/VEHICLES';
import DEFAULT_CHARGERS from './data/CHARGERS';
import {VehicleIdentificationError} from './components/DataErrorBoundary';

export default {
  vehicles: ({customVehicles, apiVehicles}) => [
    ...DEFAULT_VEHICLES,
    ...apiVehicles,
    ...customVehicles.map((vehicle) => ({
      category: 'On-Road',
      fossilFuelType: vehicle.fuelType,
      fossilMilesPerGallon: vehicle.mpg,
      ...vehicle,
    })),
  ],
  chargers: ({customChargers}) => [...DEFAULT_CHARGERS, ...customChargers],
  vehicleSets: ({vehicleSets, customVehicles, apiVehicles, customChargers}) => {
    const vehicles = [...DEFAULT_VEHICLES, ...apiVehicles, ...customVehicles];
    const chargers = [...DEFAULT_CHARGERS, ...customChargers];

    return vehicleSets.map((vehicleSet) => {
      const vehicle = vehicles.find(({id}) => id === vehicleSet.vehicle_id);

      if (!vehicle) {
        throw new VehicleIdentificationError(JSON.stringify(vehicleSet));
      }

      const archetype = findArchetype(vehicle);

      if (!archetype) {
        console.error(
          `Vehicle archetype with category [${vehicle.category}], type [${vehicle.type}], and subtype [${vehicle.subtype}] does not exist.`
        );
        throw new VehicleIdentificationError(JSON.stringify(vehicleSet));
      }

      const chargingWindows = vehicleSet.chargingWindows.map((window) => ({
        ...window,
        charger: chargers.find(({type}) => type === window.type),
      }));

      return {
        ...vehicleSet,
        vehicle: {
          ...vehicle,
          fossilMilesPerGallon: archetype.fossilMilesPerGallon,
          fossilGallonsPerHour: archetype.fossilGallonsPerHour,
          fossilFuelType: archetype.fossilFuelType,
        },
        archetype,
        chargingWindows,
      };
    });
  },
  chargerSets: ({chargerSets}) => {
    return chargerSets.map((chargerSet) => {
      const charger = DEFAULT_CHARGERS.find(
        (charger) => charger.type === chargerSet.chargerLevelInstalled
      );
      if (!charger) return chargerSet;

      return {
        ...chargerSet,
        charger,
      };
    });
  },
};
