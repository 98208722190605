import React from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {colors, typography} from '../styles';

export const Header = ({children, className, size = 2}) => (
  <div
    style={{
      textAlign: 'center',
      fontWeight: typography.weight.bold,
      fontSize: typography.headers[size],
    }}
    className={className}
  >
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.number,
};

export const Subheader = ({className, children}) => (
  <div
    style={{
      color: colors.grey.darker,
      textAlign: 'center',
      fontSize: typography.headers[4],
      margin: '20px 0',
    }}
    className={className}
  >
    {children}
  </div>
);

Subheader.propTypes = {
  children: PropTypes.node,
};

const STEP_STYLES = {
  height: '70px',
  width: '70px',
  borderRadius: '100%',
  color: colors.grey.darker,
  fontWeight: typography.weight.bold,
  fontSize: typography.headers[3],
  display: 'flex',
  justifyContent: 'center',
  border: '1px solid #DBDBDB',
  alignItems: 'center',
  margin: '20px auto',
};

export const Step = ({number, children}) => (
  <div>
    <div style={STEP_STYLES}>{number}</div>
    <div
      style={{
        display: 'block',
        color: colors.grey.darker,
        fontSize: typography.body.secondary,
        fontWeight: typography.weight.bold,
        textAlign: 'center',
      }}
    >
      {children}
    </div>
  </div>
);

Step.propTypes = {
  number: PropTypes.string,
  children: PropTypes.string,
};

export const StepWithTitle = ({number, title}) => (
  <Row className="w-100">
    <Col md="1" sm="2" xs="3">
      <span
        style={{
          ...STEP_STYLES,
          float: 'left',
          marginRight: '15px',
        }}
      >
        {number}
      </span>
    </Col>
    <Col md="11" sm="10" xs="9">
      <div className="d-flex flex-column h-100 justify-content-center">
        <h1
          style={{
            textTransform: 'uppercase',
            fontSize: typography.headers[3],
            fontWeight: typography.weight.normal,
          }}
        >
          {title}
        </h1>
      </div>
    </Col>
  </Row>
);

StepWithTitle.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
};
