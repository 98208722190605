import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import Label from './Label';
import Tooltip from '../layout/Tooltip';

const Select = ({label, value, tooltip, optionValues, optionNames, onChange, multiLine = false}) => {
  return (
    <>
      {label && (
        <Label>
          {label} {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </Label>
      )}
      <Dropdown className={multiLine ? 'multi-line-dropdown' : ''}>
        <Dropdown.Toggle>{value}</Dropdown.Toggle>

        <Dropdown.Menu>
          {optionValues.map((option, idx) => {
            return (
              <Dropdown.Item key={option.toString()} onSelect={() => onChange && onChange(option)}>
                {optionNames ? optionNames[idx] : option}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Select;

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionValues: PropTypes.array,
  optionNames: PropTypes.array,
  onChange: PropTypes.func,
};
