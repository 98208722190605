import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';

const SlideGasolinePrice = () => {
  const {gasolinePrice, setInput} = useInputs();

  return (
    <Slider
      label="Gasoline Price ($/Gal)"
      min={1}
      max={10}
      step={0.1}
      value={gasolinePrice}
      onChange={(gasolinePrice) => setInput({gasolinePrice})}
    />
  );
};

export default SlideGasolinePrice;
