import {useState, useEffect} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {get} from './api';
import startCase from 'lodash/startCase';

export default function useApiIncentives() {
  const {zipCode, loading: apiLoading} = useInputs();
  const [loading, setLoading] = useState(true);
  const [incentives, setIncentives] = useState([]);

  useEffect(() => {
    if (apiLoading) return;

    setLoading(true);
    get(`/incentives?postcode=${zipCode}&project_type=fleets`).then((res) => {
      setIncentives(
        res.data.incentives.map((incentive) => ({
          ...incentive,
          status: startCase(incentive.current_funding_status),
          eligible_organizations: incentive.eligible_organizations.map((str) => startCase(str)),
          is_eligible_to_specified_form_factors_only: incentive.is_eligible_to_specified_form_factors_only.map(
            (str) => (str === 'n/a' ? 'All' : startCase(str))
          ),
        }))
      );
      setLoading(false);
    });
  }, [zipCode, apiLoading]);

  return {loading, incentives};
}
