/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

/**
  This component DOES comply with the disabled rule above. The rule just doesn't realize it because 
  the input is brought in via the children prop.
 */

const Label = ({children, style = {}}) => (
  <label
    style={{
      marginTop: '20px',
      marginBottom: 0,
      fontSize: '12px',
      textTransform: 'uppercase',
      width: '100%',
      ...style,
    }}
    // TODO: set aria-describedby for ADA
  >
    {children}
  </label>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Label;
