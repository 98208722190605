import React from 'react';
import EvaluatedGrantCard from '../../../components/EvaluatedGrantCard';

const Incentives = ({evaluatedIncentives}) => {
  const eligibleIncentives = evaluatedIncentives
    .filter((incentive) => incentive.eligibility === 'eligible')
    .sort((a, b) => b.estimated_amount - a.estimated_amount);

  return (
    <>
      <div className="h2 text-center d-none d-print-block">Incentives</div>
      {eligibleIncentives.length > 0 && <p className="takeaway">Eligible Grants</p>}

      {eligibleIncentives.map((incentive, i) => (
        <EvaluatedGrantCard key={`${i}-${incentive.incentive_handle}`} {...incentive} isEligible />
      ))}

      <p className="takeaway mt-5">
        Ineligible Grants. For a complete list that includes typical amounts, please see the{' '}
        <a href="/grants">Grants</a> page.
      </p>
      {evaluatedIncentives
        .filter((incentive) => incentive.eligibility !== 'eligible')
        .sort(({grantor_name}) => (grantor_name === 'PG&E' ? -1 : 1))
        .map((incentive, i) => (
          <EvaluatedGrantCard key={`${i}-${incentive.incentive_handle}`} {...incentive} />
        ))}
    </>
  );
};

export default Incentives;
