import React from 'react';
import PropTypes from 'prop-types';
import {useToggle} from '@bellawatt/react-hooks';
import {colors, typography} from '../styles';
import {Caret} from '../icons';

const Header = ({children, closeable = false, isOpen, onClick}) => {
  return (
    <div
      style={{
        textTransform: 'uppercase',
        fontWeight: typography.weight.bold,
        fontSize: typography.cta,
        color: colors.text.base,
        display: 'block',
        border: 0,
        width: '100%',
        textAlign: 'left',
        padding: 0,
        backgroundColor: 'transparent',
        cursor: closeable ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      {children}
      {closeable && (
        <span className="float-right">
          <Caret direction={isOpen ? 'up' : 'down'} />
        </span>
      )}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.string,
  closeable: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

const InputCard = ({children, header, closeable = false, startOpen = false}) => {
  const [isOpen, toggleOpen] = useToggle(closeable ? startOpen : true);

  return (
    <div className="input-card">
      <Header onClick={closeable ? toggleOpen : null} isOpen={isOpen} closeable={closeable}>
        {header}
      </Header>
      <div>{isOpen && children}</div>
    </div>
  );
};

InputCard.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  closeable: PropTypes.bool,
};

export default InputCard;
