import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

const TextInput = ({label, hiddenLabel, value, onChange, style, readOnly = false, invalid = false}) => (
  <>
    {label && <Label>{label}</Label>}
    <input
      type="text"
      aria-label={label || hiddenLabel}
      readOnly={readOnly}
      value={value}
      style={style}
      className={`form-control ${invalid ? 'is-invalid' : ''}`}
      onChange={(e) => onChange && onChange(e.currentTarget.value)}
    />
  </>
);

TextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default TextInput;
