/* eslint-disable import/prefer-default-export */

export const typeConversions = {
  // Passenger vehicles:
  Sedan: 'Passenger',
  Crossover: 'Passenger',
  SUV: 'Passenger',
  Hatchback: 'Passenger',
  Coupe: 'Passenger',
  Minivan: 'Passenger',
  'Station Wagon': 'Passenger',

  // Non-Passenger vehicles
  transit_bus: 'Transit Bus',
  step_van: 'Step Van',
  pickup_truck: 'Pick-up Truck', // Note this picks up both `type='Commercial'` and `type='Passenger'`
  school_bus: 'School Bus',
  cutaway_chassis: 'Cutaway Chassis',
  utility_truck: 'Utility Truck',
  box_truck: 'Box Truck',
  refuse_truck: 'Refuse Truck',
  trailer_truck: 'Trailer Truck',
  terminal_tractor: 'Terminal Tractor',
  motor_coach: 'Motor Coach',
  forklift: 'Forklift',
  cargo_van: 'Cargo Van',
  shuttle_bus: 'Shuttle Bus',
  specialty: 'Specialty',
  bev_drive_system: 'BEV Drive System',
  tru: 'TRU',
};

export const passengerSubtypeConversions = {
  Sedan: 'Sedan',
  Crossover: 'SUV', // There is no crossover archetype, so including this conversion keeps all crossovers from the API within the app
  SUV: 'SUV',
  Hatchback: 'Hatchback',
  Coupe: 'Coupe',
  Minivan: 'Minivan',
  'Station Wagon': 'Station Wagon',
  pickup_truck: 'Light Duty', // Pickups are on both passanger and commercial vehicles. Commercial have no problem, but because we have to adjust when `type='Passenger'` we also pick up Light Duty here
};
