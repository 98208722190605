import { Chart } from 'chart.js';
import React from 'react';
import { colors } from '../styles';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import BaseGraph from './BaseGraph';

const TotalCostsWaterfallChart = ({
  electricVehicles,
  electricFuel,
  electricIncentives,
  electricOperations,
  lcfs,
  construction,
  fossilVehicles,
  fossilFuel,
  fossilOperations,
}) => {
  const electricCostData = [
    electricFuel,
    electricVehicles,
    electricOperations,
    - electricIncentives,
    - lcfs,
    construction,
  ];
  const fossilCostData = [fossilFuel, fossilVehicles, fossilOperations, 0,  0, 0];
  const costData = electricCostData.map((electricCost, i) => fossilCostData[i] - electricCost);

  const runningTotal = costData.reduce((totalArray, value, i) => {
    return [
      ...totalArray,
      i > 0 ? totalArray[i - 1] + value : value,
    ];
  }, []);
  const runningTotalPairs = runningTotal.map((total, i) => [i > 0 ? runningTotal[i - 1] : 0, total]);
  const subtotal = runningTotal[runningTotal.length - 1];

  const toolTipTotals = [...costData, subtotal];

  const data = {
    labels: ['Fuel', 'Vehicles', 'Operations', 'Grants',  'LCFS', 'Construction', 'EV Savings'],
    datasets: [
      {
        label: 'EVs is Cheaper',
        backgroundColor: runningTotalPairs.map(([start, end]) => start > end ? colors.grey.darker : colors.orange.base).concat(colors.green.darkest),
        data: runningTotalPairs.concat([[0, subtotal]]),
      },
    ],
  };

  return (
    <>
      <BaseGraph
        title="Savings from EV vs. Fossil Fuel"
        data={data}
        yFormatCallback={formatAsDollarsRounded}
        tooltipCallback={(_, __, {index}) => `${formatAsDollars(toolTipTotals[index])}`}
        xAxisOptions={{
          categoryPercentage: 0.95,
          barPercentage: 0.95,
        }}
        options={{
          legend: {labels: {
            filter: (legendItem) => legendItem.text !== 'hidden',
            generateLabels: function(chart) {
              const labels = Chart.defaults.global.legend.labels.generateLabels(chart);

              labels[0].fillStyle = colors.orange.base;
              labels.push({
                ...labels[0],
                fillStyle: colors.grey.darker,
                text: 'Fossil is Cheaper',
              });
              labels.push({
                ...labels[0],
                fillStyle: colors.green.darkest,
                text: 'EV Savings',
              });

              return labels;
            },
          }},
          tooltips: {
            callbacks: {
              afterBody: ([{index}]) => {
                return electricCostData[index] ? [
                  '',
                  `Electric: ${formatAsDollars(electricCostData[index])}`,
                  `Fossil: ${formatAsDollars(fossilCostData[index])}`
                ] : [];
              }
            }
          }
        }}
      />
    </>
  );
};

export default TotalCostsWaterfallChart;
