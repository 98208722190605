import times from 'lodash/times';
import moment from 'moment';
import {colors} from '../styles';

const ASSUMPTIONS = {
  HOUR_OF_DAY: times(24, (i) => i),

  HOUR_OF_DAY_LABELS: times(24, (i) => moment(i, 'H').format('h a')),

  WEEKDAYS: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],

  MONTHS: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],

  WEEKDAYS_AND_FULL_WEEK: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
    'Full Week',
  ],

  WEEKDAYS_LABELS: [
    'Mon',
    ...times(23, (i) => 'Mon ' + moment(i + 1, 'H').format('h a')),
    'Tue',
    ...times(23, (i) => 'Tue ' + moment(i + 1, 'H').format('h a')),
    'Wed',
    ...times(23, (i) => 'Wed ' + moment(i + 1, 'H').format('h a')),
    'Thu',
    ...times(23, (i) => 'Thu ' + moment(i + 1, 'H').format('h a')),
    'Fri',
    ...times(23, (i) => 'Fri ' + moment(i + 1, 'H').format('h a')),
    'Sat',
    ...times(23, (i) => 'Sat ' + moment(i + 1, 'H').format('h a')),
    'Sun',
    ...times(23, (i) => 'Sun ' + moment(i + 1, 'H').format('h a')),
  ],

  BEV_RATE_PEAK_TIMES: {
    start: 16,
    finish: 21,
  },

  TRU_IDLING_FACTOR: 0.75, // percent of the time a TRU is sitting idle

  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH: 2.68, // page 99 of https://www.pgecorp.com/corp_responsibility/reports/2021/assets/PGE_CRSR_2021.pdf
  DIESEL_EMISSIONS_IN_LBS_PER_GAL: 22.45, // https://www.eia.gov/environment/emissions/co2_vol_mass.php
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL: 17.86, // https://www.eia.gov/environment/emissions/co2_vol_mass.php
  LPG_EMISSIONS_IN_LBS_PER_GAL: 13.5, // https://www.engineeringtoolbox.com/co2-emission-fuels-d_1085.html
  CO2_TO_CO2E_FACTOR: 1.00364, // Source: Email from Maya Alunkal at PG&E

  OIL_SET_COLORS: [colors.grey.darker, colors.grey.base, colors.grey.lighter, colors.grey.lightest],

  VEHICLE_SET_COLORS: [
    colors.blue.darker,
    colors.blue.base,
    colors.blue.lighter,
    colors.blue.lightest,
  ],

  ELECTRICITY_SET_COLORS: [
    colors.orange.darker,
    colors.orange.base,
    colors.orange.lighter,
    colors.orange.lightest,
  ],

  COST_SET_COLORS: [
    colors.blue.darker,
    colors.blue.base,
    colors.blue.lighter,
    colors.blue.lightest,
  ],

  COST_OVERAGE_COLOR: '#f5c6cb',

  // Used exclusively for LCFS calcs
  LCFS_YEARS: [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
  LCFS_CREDIT_PRICE_IN_DOLLARS: 71,
  LCFS_EER_DIESEL: 5,
  LCFS_EER_GASOLINE: 3.4,
  LCFS_EER_LPG: 5, // Using 1:1 to diesel to match CARB guidance
  ENERGY_DENSITY_ELECTRICITY_IN_MJ_PER_KWH: 3.6,
  ENERGY_DENSITY_DIESEL_IN_MJ_PER_GAL: 134.47,
  ENERGY_DENSITY_GASOLINE_IN_MJ_PER_GAL: 119.53,
  ENERGY_DENSITY_LPG_IN_MJ_PER_GAL: 89.63,

  LCFS_COMPLIANCE_STANDARD: {
    diesel: {
      '2021': 91.660625,
      '2022': 90.405,
      '2023': 89.149375,
      '2024': 87.89375,
      '2025': 86.638125,
      '2026': 85.3825,
      '2027': 84.126875,
      '2028': 82.87125,
      '2029': 81.615625,
      '2030': 80.36,
    },
    regular: {
      '2021': 90.739,
      '2022': 89.496,
      '2023': 88.253,
      '2024': 87.01,
      '2025': 85.767,
      '2026': 84.524,
      '2027': 83.281,
      '2028': 82.038,
      '2029': 80.795,
      '2030': 79.552,
    },
    lpg: {
      // Per CARB Guidance, using Diesel as an equivalent
      '2021': 91.660625,
      '2022': 90.405,
      '2023': 89.149375,
      '2024': 87.89375,
      '2025': 86.638125,
      '2026': 85.3825,
      '2027': 84.126875,
      '2028': 82.87125,
      '2029': 81.615625,
      '2030': 80.36,
    },
  },
  GRID_INTENSITY_PATHWAYS: {
    names: ['California Grid', 'Carbon Neutral'],
    values: [82.92, 0],
  },
  YEAR: 2019,
};

export default ASSUMPTIONS;
