import React from 'react';
import Meta from '@bellawatt/meta-tags';
import {Row, Col} from 'react-bootstrap';
import PageLayout from '../layout/PageLayout';
import {Header, Subheader, StepWithTitle as Step} from '../layout/Headers';
import {BorderedText} from '../components/InfoCard';
import {Check} from '../icons';
import {colors} from '../styles';

const ElectrificationPlanning = () => (
  <PageLayout page="Electrification Planning">
    <Meta>
      <Meta.Title>PG&amp;E Fleets: Electrificiation Planning</Meta.Title>
      <Meta.Description>
        Installing charging infrastructure is easy and cost-effective with a little help from
        PG&amp;E's EV Fleet Program.
      </Meta.Description>
      <Meta.Image src="/images/home-background.jpg" />
    </Meta>
    <Row>
      <Col md={{span: 6, offset: 3}}>
        <Header>PG&amp;E&apos;s EV Fleet Electrification Planning</Header>
        <Subheader>
          Installing charging infrastructure is easy and cost-effective with a little help from
          PG&amp;E's EV Fleet Program.
          <br />
          <a
            href="https://www.pge.com/en_US/large-business/solar-and-vehicles/clean-vehicles/ev-fleet-program/ev-fleet-program.page"
            target="_blank"
            rel="noopener noreferrer"
            className="small"
          >
            See full program details →
          </a>
        </Subheader>
      </Col>
    </Row>

    <Row>
      <Col
        style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
        xl={{span: 8, offset: 2}}
      >
        <Step number="1" title="Check Your Eligibility" />
        <div className="card mb-3 w-100">
          <div className="row no-gutters">
            <div className="col-md-7">
              <div className="card-body" style={{padding: '40px 40px 20px 40px'}}>
                <p className="h4 card-title">Eligibility Requirements</p>
                <BorderedText borderBottom={false}>
                  &nbsp; <Check /> You are a PG&amp;E electric customer
                </BorderedText>
                <BorderedText borderBottom={false}>
                  &nbsp; <Check /> You own or lease your property
                </BorderedText>
                <BorderedText borderBottom={false}>
                  &nbsp; <Check /> You plan to operate at least 2 medium or heavy-duty electric
                  vehicles
                </BorderedText>
                <BorderedText borderBottom={false}>
                  For consumer light-duty EVs, go to{' '}
                  <a href="https://ev.pge.com/" target="_blank" rel="noopener noreferrer">
                    ev.pge.com
                  </a>
                </BorderedText>
                <BorderedText borderBottom={false}>
                  <a
                    href="https://www.pge.com/assets/pge/docs/clean-energy/electric-vehicles/PGE-EV-Fleet-Program-Terms-Conditions-Contract.pdf.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>EV Fleet Program Terms &amp; Conditions →</small>
                  </a>
                </BorderedText>
              </div>
            </div>
            <div
              className="col-md-5"
              style={{
                backgroundImage: 'url(/images/step-1-eligibility.jpg)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
      </Col>
    </Row>

    <Row className="mt-5">
      <Col
        style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
        xl={{span: 8, offset: 2}}
      >
        <Step number="2" title="Review Available Funding" />
        <div className="card mb-3 w-100">
          <div className="row no-gutters">
            <div className="col-md-7">
              <div className="card-body p-5">
                <p className="h4 card-title">Funding Options</p>
                <BorderedText borderBottom={false}>
                  <a href="/grants">Grants and incentives →</a>
                </BorderedText>
                <BorderedText borderBottom={false}>
                  <a href="/lcfs">Low Carbon Fuel Standard (LCFS) credits →</a>
                </BorderedText>
              </div>
            </div>
            <div
              className="col-md-5"
              style={{
                backgroundImage: 'url(/images/step-2-funding.jpg)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            />
          </div>
        </div>
      </Col>
    </Row>

    <Row className="mt-5">
      <Col
        style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
        xl={{span: 8, offset: 2}}
      >
        <Step number="3" title="Get Ready to Use The Fuel Savings Calculator" />
        <div className="card">
          <div className="row no-gutters">
            <div className="col-md-6">
              <div className="card-body p-5">
                <p className="h4 card-title">Vehicles &amp; Duty Cycle</p>
                <BorderedText borderBottom={false}>
                  Tell us about the type of vehicles that make up your EV fleet, and how many there
                  are of each.
                </BorderedText>
                <BorderedText borderBottom={false}>
                  Estimate miles traveled per vehicle on a typical workday.
                </BorderedText>
                <BorderedText borderBottom={false}>Select days of operation.</BorderedText>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-body p-5">
                <p className="h4 card-title">Charging &amp; Fuel</p>
                <BorderedText borderBottom={false}>
                  Define the hours you can charge and the charging equipment.
                </BorderedText>
                <BorderedText borderBottom={false}>
                  Select whether your electricity is sourced from the grid or is 100% clean.
                </BorderedText>
                <BorderedText borderBottom={false}>
                  For comparison purposes, set the price you pay for fuel.
                </BorderedText>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-body p-5">
                <p className="h4 card-title">Business EV Rate</p>
                <BorderedText borderBottom={false}>
                  Double-check that charging can be minimized from 4pm to 9pm to avoid high energy
                  charges.
                </BorderedText>
                <BorderedText borderBottom={false}>
                  Calculate the expected demand, and subscribe to the corresponding number of
                  blocks.
                </BorderedText>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="card card-body h-100 justify-content-center"
                style={{
                  backgroundColor: '#052136',
                  color: 'white',
                  textAlign: 'center',
                  padding: '40px 80px',
                }}
              >
                <div>
                  <p className="h3">Check the Fuel Calculator</p>
                  <p className="lead">See how much you could save by going electric.</p>
                  <a
                    href="/fuel-savings"
                    className="btn btn-primary"
                    style={{fontWeight: 'bold', padding: '10px 20px'}}
                  >
                    Calculate Fuel Savings
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row className="my-5">
      <Col
        style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
        xl={{span: 8, offset: 2}}
      >
        <Step number="4" title="Talk to an EV Fleet Specialist" />
        <div className="card">
          <div className="row no-gutters">
            <div className="col-md-6">
              <div className="card-body p-5">
                <p className="h4 card-title">Let Us Be Your Guide</p>
                <BorderedText borderBottom={false}>
                  Through PG&amp;E’s EV Fleet program, we help you understand and navigate the
                  process for electrifying your fleet. We provide substantial financial, logistical,
                  and construction support for going electric.
                </BorderedText>
                <BorderedText borderBottom={false}>
                  Understand the EV Fleet electrification{' '}
                  <a
                    href="https://www.pge.com/assets/pge/docs/clean-energy/electric-vehicles/EVFleetGuideElectrificationProcess.pdf.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    process and timeline →
                  </a>
                </BorderedText>
                <BorderedText borderBottom={false}>
                  Prepare to engage your decision-makers with our{' '}
                  <a
                    href="https://www.pge.com/assets/pge/docs/clean-energy/electric-vehicles/PGE-EV-Fleet-Guidebook.pdf.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    detailed guidebook →
                  </a>
                </BorderedText>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="card card-body h-100 justify-content-center"
                style={{
                  backgroundColor: colors.brand.darkBlue,
                  color: 'white',
                  textAlign: 'center',
                  padding: '40px 80px',
                }}
              >
                <div>
                  <p className="h3">Want some help?</p>
                  <p className="lead">
                    Our experts are standing by to help guide you through the entire process from
                    start to finish.
                  </p>
                  <a
                    href="https://energyinsight.secure.force.com/EVCustomerInterestFormPage?_ga=2.4549424.2068183774.1592832961-879105444.1577834572"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary"
                    style={{fontWeight: 'bold', padding: '10px 20px'}}
                  >
                    Schedule A Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </PageLayout>
);

export default ElectrificationPlanning;
