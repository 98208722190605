import sum from 'lodash/sum';
import calcFossilFuelCostForVehicleSet from './calcFossilFuelCostForVehicleSet';

const calcAnnualFossilFuelCost = (vehicleSets, dieselPrice, gasolinePrice, lpgPrice) =>
  sum(
    vehicleSets.map((set) =>
      calcFossilFuelCostForVehicleSet(set, dieselPrice, gasolinePrice, lpgPrice)
    )
  );

export default calcAnnualFossilFuelCost;
