import isHourInUse from './isHourInUse';
import getCharger from '../data/getCharger';
import A from '../data/ASSUMPTIONS';

const calcPlugSchedule = (chargers, vehicleSet) => {
  const rawPlugSchedule = A.HOUR_OF_DAY.map((hour) =>
    vehicleSet.chargingWindows.filter((chargingWindow) =>
      isHourInUse(hour, chargingWindow.start, chargingWindow.finish)
    )
  );

  return rawPlugSchedule.map((plug) =>
    plug.length >= 1
      ? {
          // If two chargers have overlapping hours, default to the first charger input and handle alarm state elesewhere
          chargingApproach: plug[0].chargingApproach,
          portKw: getCharger(chargers, plug[0].type).portKw,
        }
      : {chargingApproach: null, portKw: null}
  );
};

export default calcPlugSchedule;
