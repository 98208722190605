import React, {useState} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import BaseGraph from './BaseGraph';
import Select from '../inputs/Select';
import calcWeeklyLoadProfiles from '../calcs/calcWeeklyLoadProfiles';
import {formatVehicleName, formatAsKwh, formatChargerSetTitle} from '../utils/format';
import A from '../data/ASSUMPTIONS';
import times from 'lodash/times';
import calcChargerSetLoadProfile from '../calcs/calcChargerSetLoadProfile';
import uniqueLabels from '../utils/uniqueLabels';

const RateComparisonWeeklyLoadProfile = () => {
  // Default set to Tuesday because most Mondays start with no overnight charging
  const [weekdayIdx, setWeekdayIdx] = useState(1);
  const {
    vehicleSets,
    chargerSets,
    chargers,
    estimationApproach,
    chargingFacilityType,
  } = useInputs();

  const weeklyLoadProfiles =
    estimationApproach === 'Vehicles'
      ? calcWeeklyLoadProfiles(chargers, vehicleSets)
      : chargerSets.map((set) =>
          calcChargerSetLoadProfile(set, {timeRange: 'weekly', facilityType: chargingFacilityType})
        );

  const showFullWeek = weekdayIdx === 7; // 0-6 are Monday through Friday; 7 is 'Full Week'

  const sets = estimationApproach === 'Vehicles' ? vehicleSets : chargerSets;

  const data = {
    labels: showFullWeek ? A.WEEKDAYS_LABELS : A.HOUR_OF_DAY_LABELS,
    datasets: uniqueLabels(
      sets.map((set, setIdx) => ({
        label:
          estimationApproach === 'Vehicles' ? formatVehicleName(set) : formatChargerSetTitle(set),
        backgroundColor: A.ELECTRICITY_SET_COLORS[setIdx],
        borderColor: A.ELECTRICITY_SET_COLORS[setIdx],
        data: showFullWeek
          ? times(24 * 7, (hourOfWeek) => weeklyLoadProfiles[setIdx][hourOfWeek])
          : times(24, (hourOfDay) => weeklyLoadProfiles[setIdx][weekdayIdx * 24 + hourOfDay]),
      }))
    ),
  };

  return (
    <>
      <div style={{maxWidth: '300px', marginBottom: '2rem'}}>
        <Select
          label="Weekday Selected"
          value={A.WEEKDAYS_AND_FULL_WEEK[weekdayIdx]}
          optionValues={times(8, (i) => i)}
          optionNames={A.WEEKDAYS_AND_FULL_WEEK}
          onChange={(newWeekday) => {
            setWeekdayIdx(newWeekday);
          }}
        />
      </div>

      <BaseGraph
        type="bar"
        title="Site Load Profile (kWh)"
        data={data}
        stacked
        xAxisOptions={{
          ticks: {maxTicksLimit: showFullWeek ? 7 : 12},
          categoryPercentage: 1.0,
          barPercentage: 1.0,
        }}
        yFormatCallback={formatAsKwh}
        tooltipCallback={formatAsKwh}
      />
    </>
  );
};

export default RateComparisonWeeklyLoadProfile;
