import React from 'react';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import BaseGraph from './BaseGraph';
import { colors } from '../styles';

const TotalCostsBarChart = ({
  electricVehicles,
  electricFuel,
  electricIncentives,
  electricOperations,
  lcfs,
  construction,
  fossilVehicles,
  fossilFuel,
  fossilOperations,
}) => {
  const data = {
    labels: ['Fuel', 'Vehicles', 'Operations', 'Grants',  'LCFS', 'Construction'],
    datasets: [
      {
        label: 'Electric Vehicles',
        backgroundColor: colors.orange.base,
        data: [
          electricFuel,
          electricVehicles,
          electricOperations,
          - electricIncentives,
          - lcfs,
          construction,
        ],
      },
      {
        label: 'Fossil Fuel Vehicles',
        backgroundColor: colors.grey.darker,
        data: [fossilFuel, fossilVehicles , fossilOperations, 0,  0, 0],
      },
    ],
  };
  return (
    <>
      <BaseGraph
        title="Total Costs"
        data={data}
        yFormatCallback={formatAsDollarsRounded}
        tooltipCallback={formatAsDollars}
        options={{
          // tooltips: {
          //   filter: (tooltipItem) =>
          //     !(tooltipItem.index === 2 && tooltipItem.datasetIndex === fossilSets.length + 1),
          // },
          legend: {labels: {filter: (legendItem) => legendItem.text !== 'hidden'}},
        }}
      />
    </>
  );
};

export default TotalCostsBarChart;
