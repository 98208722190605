import React, {useState} from 'react';
import TotalCostsBarChart from './TotalCostsBarChart';
import TotalCostsWaterfallChart from './TotalCostsWaterfallChart';

const TotalCosts = (props) => {
  const [chart, setChart] = useState('bar');

  return (
    <>
      {chart === 'waterfall' ? (
        <TotalCostsWaterfallChart
          {...props}
        />
      ) : (
        <TotalCostsBarChart
          {...props}
          electricIncentives={props.electricIncentives}
        />
      )}

      <div className="d-flex justify-content-center my-3">
        <div className="btn-group">
          <button onClick={() => setChart('bar')} type="button" className={`btn ${chart === 'bar' ? 'btn-dark' : 'btn-outline-dark'}`}>Bar Chart</button>
          <button onClick={() => setChart('waterfall')} type="button" className={`btn ${chart === 'waterfall' ? 'btn-dark' : 'btn-outline-dark'}`}>Waterfall</button>
        </div>
      </div>
    </>
  )
};

export default TotalCosts;