import isHourInUse from './isHourInUse';
import A from '../data/ASSUMPTIONS';

const alarmMultipleChargersInUseAtOnce = (chargingWindows) => {
  const rawPlugSchedule = A.HOUR_OF_DAY.map((hour) =>
    chargingWindows.filter((chargingWindow) =>
      isHourInUse(hour, chargingWindow.start, chargingWindow.finish)
    )
  );

  return rawPlugSchedule.map((hour) => hour.length).some((count) => count >= 2);
};

export default alarmMultipleChargersInUseAtOnce;
