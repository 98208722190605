import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import AnnualFuelCosts from '../../../graphs/AnnualFuelCosts';
import LcfsRevenues from '../../../graphs/LcfsRevenues';
import calcAnnualFossilFuelCost from '../../../calcs/calcAnnualFossilFuelCost';
import {formatAsDollarsRounded} from '../../../utils/format';
import calcAnnualElectricityCost from '../../../calcs/calcAnnualElectricityCost';
import calcLcfsCreditsByYear from '../../../calcs/calcLcfsCreditsByYear';
import calcAnnualLcfsCredits from '../../../calcs/calcAnnualLcfsCredit';

const Financial = () => {
  const {
    vehicleSets,
    bevRateName,
    bevBlocks,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
    gridIntensityInGramsCO2ePerMJ,
    chargers,
  } = useInputs();

  const electricityCosts = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    true
  );
  const fossilFuelCosts = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );
  const savings = fossilFuelCosts - electricityCosts;

  const lcfsCredits = calcAnnualLcfsCredits(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );
  const lcfsCreditsByYear = calcLcfsCreditsByYear(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );

  return (
    <>
      <div className="h2 text-center d-none d-print-block">Financial</div>
      <p className="takeaway">
        Based on your selections, using electricity instead of fossil fuel saves{' '}
        <strong>{formatAsDollarsRounded(savings)}</strong> per year.
      </p>

      <AnnualFuelCosts overwriteRateInputs />

      <hr />
      <p className="takeaway">
        Switching to electricity will also allow you to generate Low Carbon Fuel Standard (LCFS)
        credits, which could generate <strong>{formatAsDollarsRounded(lcfsCredits)}</strong> per
        year.
      </p>
      <LcfsRevenues lcfsRevenues={lcfsCreditsByYear} />
      <p className="takeaway">
        For more on LCFS Credits, visit the <a href="/lcfs">LCFS calculator</a>.
      </p>
    </>
  );
};

export default Financial;
