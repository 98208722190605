import React, {useState} from 'react';
import Meta from '@bellawatt/meta-tags';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import startCase from 'lodash/startCase';
import Container from 'react-bootstrap/Container';
import Select from '../inputs/Select';
import PageLayout from '../layout/PageLayout';
import {Header, Subheader} from '../layout/Headers';
import Loading from '../components/Loading';
import DetailedGrantCard from '../components/DetailedGrantCard';
import useApiIncentives from '../utils/useApiIncentives';
import { useInputs } from '@bellawatt/use-inputs';
import ZipCode from '../inputs/ZipCode';

const GrantsAndIncentives = () => {
  const {zipCode, setInput} = useInputs();
  const {loading, incentives} = useApiIncentives();
  const [grantor, setGrantor] = useState('All');
  const [organization, setOrganization] = useState('All');
  const [status, setStatus] = useState('All');
  const [formFactor, setFormFactor] = useState('All');

  const filterIncentives = (incentives, omit = '') =>
    incentives.filter(
      (incentive) =>
        (omit === 'grantor' || grantor === 'All' || incentive.grantor_name === grantor) &&
        (omit === 'status' || status === 'All' || status === incentive.status) &&
        (omit === 'organization' ||
          organization === 'All' ||
          (incentive.eligible_organizations.length === 1 &&
            incentive.eligible_organizations[0] === 'All') ||
          incentive.eligible_organizations.indexOf(organization) !== -1) &&
        (omit === 'formFactor' ||
          formFactor === 'All' ||
          (incentive.is_eligible_to_specified_form_factors_only.length === 1 &&
            incentive.is_eligible_to_specified_form_factors_only[0] === 'All') ||
          incentive.is_eligible_to_specified_form_factors_only.indexOf(formFactor) !== -1)
    );

  const filteredIncentives = filterIncentives(incentives);

  const grantors = [
    'All',
    ...uniq(filterIncentives(incentives, 'grantor').map(({grantor_name}) => grantor_name)).sort(),
  ];
  const organizations = uniq(
    flatMap(
      filterIncentives(incentives, 'organization'),
      ({eligible_organizations}) => eligible_organizations
    )
      .map((organization) => startCase(organization))
      .sort()
  );
  const formFactors = uniq(
    flatMap(
      filterIncentives(incentives, 'formFactor'),
      ({is_eligible_to_specified_form_factors_only}) => is_eligible_to_specified_form_factors_only
    )
      .map((formFactor) => formFactor)
      .sort()
  );
  const statuses = [
    'All',
    ...uniq(filterIncentives(incentives, 'status').map(({status}) => status)).sort(),
  ];

  return (
    <PageLayout page="Grants">
      <Container>
        <Meta>
          <Meta.Title>PG&amp;E Fleets: Grants &amp; Incentives</Meta.Title>
          <Meta.Description>
            The Grants &amp; Incentives available to PG&amp;E Fleets cusomters.
          </Meta.Description>
          <Meta.Image src="/images/home-background.jpg" />
        </Meta>
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <Header>Grants and Incentives</Header>
            <Subheader>
              The grants and incentives below are available to PG&amp;E customers. See the{' '}
              <a href="/tco">Total Costs Calculator</a> for more, including how these grants total
              for your own fleet electrification project.
            </Subheader>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md="3" xs="6">
            <Select
              label="Vehicle Type"
              value={formFactor}
              onChange={setFormFactor}
              optionValues={formFactors}
              readOnly={loading}
            />
          </Col>
          <Col md="3" xs="6">
            <Select
              label="Grantor"
              optionValues={grantors}
              value={grantor}
              onChange={setGrantor}
              readOnly={loading}
            />
          </Col>
          <Col md="3" xs="6">
            <Select
              label="Industry"
              optionValues={organizations}
              value={organization}
              onChange={setOrganization}
              readOnly={loading}
            />
          </Col>
          <Col md="3" xs="6">
            <Select
              label="Status"
              optionValues={statuses}
              optionNames={statuses.map(status => status === 'Funded' ? 'Accepting Applications' : status)}
              value={status === 'Funded' ? 'Accepting Applications' : status}
              onChange={setStatus}
              readOnly={loading}
            />
          </Col>
        </Row>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="h3 my-5 text-center">
              Showing <strong>{filteredIncentives.length}</strong> of{' '}
              <strong>{incentives.length}</strong> incentives for &nbsp;
              <ZipCode
                defaultValue={zipCode}
                onChange={zipCode => setInput({zipCode})}
                style={{
                  display: 'inline',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  backgroundColor: 'transparent',
                  width: 'auto',
                  maxWidth: '10ch', // 5 characters + padding
                }} 
              />
            </div>
            {filteredIncentives.map((incentive) => (
              <Row key={incentive.incentive_handle}>
                <Col>
                  <DetailedGrantCard {...incentive} />
                </Col>
              </Row>
            ))}
          </>
        )}
      </Container>
    </PageLayout>
  );
};

export default GrantsAndIncentives;
