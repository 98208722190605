import React from 'react';
import Slider from '../Slider';

const SlideMilesPerWorkday = ({value, onChange}) => (
  <Slider
    label="Avg. Miles (Per Vehicle Per Day)"
    min={10}
    max={500}
    step={10}
    value={value}
    onChange={onChange}
  />
);

export default SlideMilesPerWorkday;
