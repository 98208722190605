import React from 'react';
import PropTypes from 'prop-types';
import BaseChart from 'react-chartjs-2';
import merge from 'lodash/merge';

const backupFormat = (value) => value;

const backupTooltip = (value, label) => `${label}: ${value}`;

const BaseGraph = ({
  options,
  title,
  tooltip,
  stacked = false,
  type = 'bar',
  xAxisOptions = {},
  yAxisOptions = {},
  yFormatCallback = backupFormat,
  tooltipCallback = backupTooltip,
  pointRadius = 0,
  ...props
}) => {
  const baseOptions = {
    legend: {
      align: 'end',
      labels: {
        boxWidth: 15,
      },
      onClick(event, legendItem) {},
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          return tooltipCallback(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
            data.datasets[tooltipItem.datasetIndex].label || '',
            tooltipItem
          );
        },
      },
    },
    elements: {
      point: {
        radius: pointRadius,
      },
    },
    borderWidth: 0,
    scales: {
      xAxes: [
        merge(
          {
            stacked,
            gridLines: {
              display: false,
            },
          },
          xAxisOptions
        ),
      ],
      yAxes: [
        merge(
          {
            stacked,
            ticks: {
              fontSize: 16,
              autoSkipPadding: 40,
              beginAtZero: true,
              callback: yFormatCallback,
            },
          },
          yAxisOptions
        ),
      ],
    },
  };

  const mergedOptions = merge(baseOptions, options);

  return (
    <>
      <p
        className="title-for-graphs-and-tables"
        style={{marginBottom: options && options.legend && options.legend.display ? 0 : '1rem'}}
      >
        {title}
      </p>
      <div className="mx-sm-2">
        <BaseChart {...props} type={type} options={mergedOptions} />
      </div>
    </>
  );
};

BaseGraph.propTypes = {
  type: PropTypes.string,
  options: PropTypes.object,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  stacked: PropTypes.bool,
  xAxisOptions: PropTypes.object,
  yFormatCallback: PropTypes.func,
  tooltipCallback: PropTypes.func,
};

export default BaseGraph;
