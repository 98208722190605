import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import defaults from '../../../defaults';

const ResetInputs = () => {
  const {setInput} = useInputs();

  const resetInputs = () => {
    if (!window.confirm('Are you sure you want to reset all your inputs?')) return;

    setInput(defaults);
  };

  return (
    <p className="text-center">
      <button
        type="button text-center"
        onClick={resetInputs}
        className="btn btn-outline-danger"
      >
        Reset Inputs
      </button>
    </p>
  );
};

export default ResetInputs;
