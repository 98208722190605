import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SlideVehicleCount from '../inputs/components/SlideVehicleCount';
import SlideMilesPerWorkday from '../inputs/components/SlideMilesPerWorkday';
import ChooseWorkdays from '../inputs/components/ChooseWorkdays';

const EnterVehicleDetails = ({changeParam, vehicleCount, milesPerWorkday, workdays}) => {
  return (
    <>
      <Row>
        <Col xs="12" sm>
          <SlideVehicleCount
            value={vehicleCount}
            onChange={(vehicleCount) => changeParam({vehicleCount})}
          />
        </Col>
        <Col xs="12" sm>
          <SlideMilesPerWorkday
            value={milesPerWorkday}
            onChange={(milesPerWorkday) => changeParam({milesPerWorkday})}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ChooseWorkdays value={workdays} onChange={(workdays) => changeParam({workdays})} />
        </Col>
      </Row>
    </>
  );
};

EnterVehicleDetails.header = 'Enter Vehicle Details';

EnterVehicleDetails.propTypes = {
  vehicleCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  milesPerWorkday: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workdays: PropTypes.array,
  changeParam: PropTypes.func,
};

export default EnterVehicleDetails;
