import React, {useState} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../inputs/TextInput';
import Slider from '../inputs/Slider';
import Select from '../inputs/Select';
import {titleCase} from '../utils/format';
import SelectVehicleCategory from '../inputs/components/SelectVehicleCategory';
import SelectVehicleSubType from '../inputs/components/SelectVehicleSubType';
import SelectVehicleType from '../inputs/components/SelectVehicleType';
import ARCHETYPES from '../data/VEHICLES';

const SelectVehicleCustom = ({changeParam, defaultVehicle, cancel}) => {
  const {setInput, customVehicles, vehicles} = useInputs();

  const [archetype, setArchetype] = useState(
    ARCHETYPES.find(({category, type, subtype}) => category === defaultVehicle.category && type === defaultVehicle.type && subtype === defaultVehicle.subtype)
  );
  const changeArchetype = (vehicleId) => {
    const newArchetype = ARCHETYPES.find(({id}) => id === vehicleId);
    
    setArchetype(newArchetype);
    setCustomVehicle(({name, image_filename, id}) => ({
      ...newArchetype,
      name,
      image_filename,
      id,
    }));
  }

  const [customVehicle, setCustomVehicle] = useState({
    ...archetype,
    name: 'Custom',
    image_filename: '',
    id: `custom_${Math.random().toString(36).slice(2)}`,
  });

  const isOnRoad = customVehicle.category === 'On-Road';
  const [error, setError] = useState(null);

  const categoryFilteredVehicles = ARCHETYPES.filter((vehicle) => vehicle.category === archetype.category);
  const categoryTypeFilteredVehicles = categoryFilteredVehicles.filter(
    (vehicle) => vehicle.type === archetype.type
  );

  const customParamChange = (change) => {
    setCustomVehicle((current) => ({...current, ...change}));
  };

  const createCustomVehicle = () => {
    if (!customVehicle.name) {
      setError('Custom vehicles must have a unique name!');
      return;
    }

    if (vehicles.some(({category, type, subtype, name}) => category === customVehicle.category && type === customVehicle.type && subtype === customVehicle.subtype && name === customVehicle.name)) {
      setError(
        `There is already a vehicle called ${customVehicle.name} in this category. Please choose a new name!`
      );
      return;
    }

    setInput({customVehicles: [...customVehicles, customVehicle]});
    changeParam({vehicle_id: customVehicle.id});
    cancel();
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <SelectVehicleCategory
            value={archetype.category}
            onChange={(newCategory) => {
              changeArchetype(
                vehicles.find((vehicle) => vehicle.category === newCategory).id
              );
            }}
          />
        </Col>
        <Col sm={6}>
          <SelectVehicleType
            value={archetype.type}
            vehicles={categoryFilteredVehicles}
            onChange={(newType) => {
              changeArchetype(
                categoryFilteredVehicles.find((vehicle) => vehicle.type === newType).id
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <SelectVehicleSubType
            value={archetype.subtype}
            vehicles={categoryTypeFilteredVehicles}
            onChange={(newSubtype) =>
              changeArchetype(
                categoryTypeFilteredVehicles.find(
                  (vehicle) => vehicle.subtype === newSubtype
                ).id
              )}
            />
        </Col>
        <Col sm={6}>
          <TextInput
            label="Name"
            value={customVehicle.name}
            onChange={name => customParamChange({name})}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          {isOnRoad ? (
            <Slider
              value={customVehicle.rangeInMiles}
              label="Vehicle Range (miles)"
              min={50}
              max={400}
              step={5}
              onChange={(rangeInMiles) => customParamChange({rangeInMiles})}
            />
          ) : (
            <Slider
              value={customVehicle.rangeInHours}
              label="Vehicle Range (hours)"
              min={1}
              max={50}
              step={0.5}
              onChange={(rangeInHours) => customParamChange({rangeInHours})}
            />
          )}
        </Col>
        <Col sm={6}>
          <Slider
            value={customVehicle.batteryCapacityInKwh}
            label="Vehicle Capacity (in kWH)"
            min={50}
            max={1500}
            step={10}
            onChange={(batteryCapacityInKwh) => customParamChange({batteryCapacityInKwh})}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Select
            label="Fuel Type"
            value={titleCase(customVehicle.fossilFuelType)}
            onChange={(fossilFuelType) => customParamChange({fossilFuelType})}
            optionValues={isOnRoad ? ['diesel', 'regular'] : ['diesel', 'regular', 'lpg']}
            optionNames={['Diesel', 'Regular', 'Lpg']}
          />
        </Col>
        <Col sm={6}>
          {isOnRoad ? (
            <Slider
              value={customVehicle.fossilMilesPerGallon}
              label="Miles per Gallon"
              min={0}
              max={25}
              onChange={(fossilMilesPerGallon) => customParamChange({fossilMilesPerGallon})}
            />
          ) : (
            <Slider
              value={customVehicle.fossilGallonsPerHour}
              label="Gallons per Hour"
              min={0}
              max={10}
              step={0.5}
              onChange={(fossilGallonsPerHour) => customParamChange({fossilGallonsPerHour})}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <button type="button" className="btn btn-secondary" onClick={cancel}>
            Cancel
          </button>
          <button type="button" onClick={createCustomVehicle} className="mx-2 btn btn-primary">
            Save
          </button>
        </Col>
      </Row>
      <Row>
        <Col className="text-right text-danger">{error}</Col>
      </Row>
    </>
  );
};

SelectVehicleCustom.header = 'Select a Vehicle';

SelectVehicleCustom.propTypes = {
  defaultVehicle: PropTypes.object,
  changeParam: PropTypes.func,
  cancel: PropTypes.func,
};

export default SelectVehicleCustom;
