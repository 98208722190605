import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import times from 'lodash/times';
import getBevRateLevel from '../../data/getBevRateLevel';
import calcIdealBevBlocks from '../calcIdealBevBlocks';
import calcIdealBevRateName from '../calcIdealBevRateName';

const calcBevCosts = (weeklyLoadProfile, bevRateName, bevBlocks, overwriteRateInputs) => {
  const rateName = overwriteRateInputs ? calcIdealBevRateName(weeklyLoadProfile) : bevRateName;
  const rateBlocks = overwriteRateInputs
    ? calcIdealBevBlocks(rateName, weeklyLoadProfile)
    : bevBlocks;

  const bevRate = getBevRateLevel(rateName);

  // Energy:
  const weeklyEnergyCostProfile = weeklyLoadProfile.map((load, idx) => {
    const hourOfDay = idx % 24;
    const period = bevRate.periods.find((period) => period.hours.includes(hourOfDay));
    const energyCharge = period.charge + bevRate.crsTotalChargePerKwh;
    return load * energyCharge;
  });

  const annualEnergyCost = 365.25 * (sum(weeklyEnergyCostProfile) / 7);

  // Subscription (aka Demand):
  const demandInKw = Math.max(...weeklyLoadProfile);
  const subscribedKw = rateBlocks * bevRate.blockSizeInKw;
  const overageKw = Math.max(0, demandInKw - subscribedKw);

  const monthlySubscriptionCost = bevRate.subscriptionChargePerBlock * rateBlocks;
  const monthlyOverageCost = bevRate.subscriptionOverageChargePerKw * overageKw;

  const monthlyDemandCost = monthlySubscriptionCost + monthlyOverageCost;

  // divide monthly energy kW by the monthly cost to find the average monthly $/kW
  const energyUnits = ((sum(weeklyLoadProfile) / 7) * 365.25) / 12;
  const energyCost = annualEnergyCost / 12;
  const energyCharge = energyCost / energyUnits;

  const rateComponents = [
    {
      name: 'Energy ($ per kWh)',
      charge: energyCharge,
      units: energyUnits,
      cost: energyCost,
    },
    {
      name: 'Subscription ($ per block)',
      charge: bevRate.subscriptionChargePerBlock,
      units: rateBlocks,
      cost: monthlySubscriptionCost,
    },
    {
      name: 'Overage ($ per kW)',
      charge: bevRate.subscriptionOverageChargePerKw,
      units: overageKw,
      cost: monthlyOverageCost,
    },
  ];

  return {
    weeklyEnergy: times(7 * 24, (i) =>
      sum(weeklyEnergyCostProfile.filter((_, idx) => idx >= i * 24 && idx <= (i + 1) * 24 - 1))
    ),
    monthlyEnergy: annualEnergyCost / 12,
    monthlySubscription: monthlySubscriptionCost,
    monthlyOverage: monthlyOverageCost,
    components: rateComponents,
    total: Math.round(12 * monthlyDemandCost + annualEnergyCost),
  };
};

calcBevCosts.propTypes = {
  weeklyLoadProfile: PropTypes.array,
  bevRateName: PropTypes.string,
  bevBlocks: PropTypes.number,
};

export default calcBevCosts;
