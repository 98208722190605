import calcFullYearBatterySchedule from './calcFullYearBatterySchedule';

const calcTotalYearlyVehicleSetLoadProfile = ({vehicleSets, chargers}) => {
  // hourly charged kWh for every vehicle
  const schedules = vehicleSets.map((set) =>
    calcFullYearBatterySchedule(set, chargers).map((hour) => hour.chargedKwh)
  );

  return !schedules.length
    ? Array(8760).fill(0)
    : schedules.reduce((sums, current) => sums.map((value, i) => value + current[i]));
};

export default calcTotalYearlyVehicleSetLoadProfile;
