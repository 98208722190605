import calcChargerSetLoadProfile from './calcChargerSetLoadProfile';

const calcTotalChargerSetLoadProfile = ({chargerSets, timeRange, facilityType}) => {
  const schedules = chargerSets.map((set) =>
    calcChargerSetLoadProfile(set, {timeRange, facilityType})
  );
  return !schedules.length
    ? Array((timeRange === 'yearly' ? 365 : 7) * 24).fill(0)
    : schedules.reduce((sums, current) => sums.map((value, i) => value + current[i]));
};

export default calcTotalChargerSetLoadProfile;
