import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import without from 'lodash/without';
import Label from '../Label';
import CheckButton from '../CheckButton';
import A from '../../data/ASSUMPTIONS';

const ChooseWorkdays = ({value, onChange}) => {
  const toggleDay = (dayIndex) => {
    if (value.includes(dayIndex)) {
      onChange(without(value, dayIndex));
    } else {
      onChange([...value, dayIndex]);
    }
  };

  const COL_PADDING = {paddingLeft: '5px', paddingRight: '5px'};

  return (
    <>
      <Label>Days in Operation</Label>
      <Row className="my-2 px-2">
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(1)} onClick={() => toggleDay(1)}>
            {A.WEEKDAYS[0]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(2)} onClick={() => toggleDay(2)}>
            {A.WEEKDAYS[1]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(3)} onClick={() => toggleDay(3)}>
            {A.WEEKDAYS[2]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(4)} onClick={() => toggleDay(4)}>
            {A.WEEKDAYS[3]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(5)} onClick={() => toggleDay(5)}>
            {A.WEEKDAYS[4]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(6)} onClick={() => toggleDay(6)}>
            {A.WEEKDAYS[5]}
          </CheckButton>
        </Col>
        <Col className="workday-btn" style={COL_PADDING}>
          <CheckButton checked={value.includes(0)} onClick={() => toggleDay(0)}>
            {A.WEEKDAYS[6]}
          </CheckButton>
        </Col>
      </Row>
    </>
  );
};

ChooseWorkdays.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default ChooseWorkdays;
