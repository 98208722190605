import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {times} from 'lodash';
import BaseGraph from './BaseGraph';
import {formatAsCents, formatAsDollarsPerKwh} from '../utils/format';
import A from '../data/ASSUMPTIONS';
import getBevRateLevel from '../data/getBevRateLevel';
import {colors} from '../styles';

const BevHourlyEnergyCharges = () => {
  const {bevRateName} = useInputs();
  const bevRate = getBevRateLevel(bevRateName);

  const data = {
    labels: A.HOUR_OF_DAY_LABELS,
    datasets: [
      {
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        label: '$ / kWh',
        data: times(24, (i) => bevRate.periods.find((period) => period.hours.includes(i)).charge),
        backgroundColor: colors.brand.blue,
        borderColor: colors.brand.blue,
        steppedLine: true,
        borderWidth: 0,
      },
    ],
  };

  return (
    <BaseGraph
      title="BEV Hourly Energy Charges"
      type="bar"
      data={data}
      stacked
      yFormatCallback={formatAsCents}
      tooltipCallback={formatAsDollarsPerKwh}
      xAxisOptions={{ticks: {maxTicksLimit: 12}}}
    />
  );
};

export default BevHourlyEnergyCharges;
