import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import {Row, Col} from 'react-bootstrap';
import PageLayout from '../layout/PageLayout';
import Card from '../components/InputCard';
import Electricity from './FuelSavingsCalculator/components/Electricity';
import SelectBevRateName from '../inputs/components/SelectBevRateName';
import SlideBevBlocks from '../inputs/components/SlideBevBlocks';
import VehicleSets from '../inputs/VehicleSets';
import calcTotalWeeklyLoadProfile from '../calcs/calcTotalWeeklyLoadProfile';
import DisplayVehicleSetAlarms from '../components/DisplayVehicleSetAlarms';
import Loading from '../components/Loading';
import {DEFAULT_VEHICLE_SET} from '../defaults';
import FirstVehicleModal from '../components/FirstVehicleModal';


const BevRateCalculator = () => {
  const {vehicleSets, chargers, loading, setInput} = useInputs();
  const hasVehicles = vehicleSets && vehicleSets.length > 0;

  if (loading) {
    return (
      <PageLayout page="Business Calculator">
        <Loading />
      </PageLayout>
    );
  }

  const weeklyLoadProfile = calcTotalWeeklyLoadProfile(chargers, vehicleSets);
  return (
    <PageLayout page="Business Calculator">
      <Meta>
        <Meta.Title>PG&amp;E Fleets: Business EV Rate Calculator</Meta.Title>
        <Meta.Description>
          The Business EV Rate was specifically designed to reduce EV charging costs for businesses
          and public organizations.
        </Meta.Description>
        <Meta.Image src="/images/home-background.jpg" />
      </Meta>
      <FirstVehicleModal
        isOpen={vehicleSets.length === 0}
        handleClose={() => setInput({vehicleSets: [DEFAULT_VEHICLE_SET]})}
        saveAndClose={(newVehicleSet) => setInput({vehicleSets: [newVehicleSet]})}
      />
      <Row className="mt-5">
        <Col lg={{span: 10, offset: 1}} style={{padding: '0 3rem 3rem 3rem'}}>
          <h2 className="text-center">Business EV Rate Calculator</h2>

          <p>
            Since May 1, 2020, the Business EV Rate has been available to customers for entrollment.
          </p>
          <p>
            The rate was specifically designed to reduce EV charging costs for businesses and public
            organizations, including DC Fast Chargers (DCFC) and charging lots, public transit,
            workplaces, class 3-8 vehicles such as trucks and freight, and multifamily dwellings.
          </p>
          <p>
            The Business EV rate is unique in that it eliminates demand charges to accommodate the
            "peaky" nature of EV charging demand to make vehicle electrification more cost
            advantageous compared to gas vehicle fleets.
          </p>
          <p>
            Please see the{' '}
            <a
              href="https://www.pge.com/en_US/small-medium-business/energy-alternatives/clean-vehicles/ev-charge-network/electric-vehicle-rate-plans.page"
              target="_blank"
              rel="noopener noreferrer"
            >
              official PG&amp;E Business EV page
            </a>{' '}
            or its formal tariff sheet{' '}
            <a href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_BEV.pdf">here</a>
            .
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <Row>
            <Col>
              <Card header="Vehicles" closeable startOpen>
                <VehicleSets />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card header="BEV Rate" closeable startOpen>
                <SelectBevRateName maxKw={Math.max(...weeklyLoadProfile)} />
                <SlideBevBlocks maxKw={Math.max(...weeklyLoadProfile)} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8}>
          <DisplayVehicleSetAlarms vehicleSets={vehicleSets}>
            <Card>{hasVehicles && <Electricity />}</Card>
          </DisplayVehicleSetAlarms>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default BevRateCalculator;
