import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap/';
import {useInputs} from '@bellawatt/use-inputs';
import Button from '../layout/Button';
import GreyDiv from '../layout/GreyDiv';
import {Trash} from '../icons';
import SlideChargerCount from '../inputs/components/SlideChargerCount';
import {DEFAULT_CHARGER_SET} from '../defaults';
import Select from '../inputs/Select';
import {typography} from '../styles';
import {formatChargerSetTitle} from '../utils/format';
import ChooseWorkdays from '../inputs/components/ChooseWorkdays';
import ChooseWorkmonths from '../inputs/components/ChooseWorkmonths';
import TIME_PERIODS from '../utils/timePeriods';

const NewChargerSetModal = ({isOpen, handleClose, chargerSet, saveAndClose, discardAndClose}) => {
  const [newChargerSet, setNewChargerSet] = useState({...DEFAULT_CHARGER_SET, ...chargerSet});
  const {chargers, chargingFacilityType} = useInputs();
  const {
    numChargersInstalled,
    chargerLevelInstalled,
    workdays,
    workmonths,
    chargersPerTimePeriod,
  } = newChargerSet;

  const changeParam = (obj) => {
    setNewChargerSet((current) => ({...current, ...obj}));
  };

  // make sure each period doesn't exceed the total number of chargers
  const changeNumChargersInstalled = (numChargers) => {
    const newTimePeriods = Object.keys(chargersPerTimePeriod).reduce(
      (accum, currPeriodKey) => ({
        ...accum,
        [currPeriodKey]: Math.min(numChargers, chargersPerTimePeriod[currPeriodKey]),
      }),
      {}
    );

    changeParam({chargersPerTimePeriod: newTimePeriods});
    changeParam({numChargersInstalled: +numChargers});
  };

  const changeChargersPerTimePeriod = (periodKey, numChargers) => {
    const newTimePeriods = {
      ...chargersPerTimePeriod,
      [periodKey]: numChargers,
    };

    changeParam({
      chargersPerTimePeriod: newTimePeriods,
    });
  };

  return (
    <>
      <Modal animation={false} show={isOpen} onHide={handleClose} size="lg">
        <Modal.Header closeButton style={{borderBottom: 0}}>
          <p className="h5">{formatChargerSetTitle(newChargerSet)} Chargers</p>
        </Modal.Header>
        <Modal.Body style={{padding: '0 2rem'}}>
          <GreyDiv header="# of Chargers Installed">
            <Row>
              <Col xs={12} sm={6}>
                <SlideChargerCount
                  value={numChargersInstalled}
                  onChange={changeNumChargersInstalled}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Select
                  label="Charger"
                  tooltip="The higher the voltage, the faster the charge"
                  optionValues={chargers.map((charger) => charger.type)}
                  value={chargerLevelInstalled}
                  onChange={(chargerLevelInstalled) => changeParam({chargerLevelInstalled})}
                />
              </Col>
            </Row>
          </GreyDiv>
          <GreyDiv>
            <Row>
              <Col xs={12}>
                <ChooseWorkmonths
                  value={workmonths}
                  onChange={(workmonths) => changeParam({workmonths})}
                />
              </Col>
              <Col xs={12}>
                <ChooseWorkdays value={workdays} onChange={(workdays) => changeParam({workdays})} />
              </Col>
            </Row>
          </GreyDiv>
          {chargingFacilityType === 'Custom' && (
            <GreyDiv header="Custom Charger Activity">
              <Row>
                {TIME_PERIODS.map((period) => (
                  <Col xs={12} md={6} key={period.key}>
                    <SlideChargerCount
                      label={
                        <span>
                          # of Chargers: <b>{period.label}</b>
                        </span>
                      }
                      value={chargersPerTimePeriod[period.key]}
                      maxChargers={numChargersInstalled}
                      onChange={(value) => changeChargersPerTimePeriod(period.key, +value)}
                    />
                  </Col>
                ))}
              </Row>
            </GreyDiv>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row style={{width: '100%'}}>
            <Col className="text-left mb-3 mb-sm-0" sm="6" xs="12">
              <Button
                type="link"
                style={{paddingLeft: 0}}
                onClick={() => discardAndClose(newChargerSet)}
              >
                <Trash /> Delete Charger Set
              </Button>
            </Col>
            <Col className="text-right">
              <Button style={{width: '100%'}} type="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  width: '100%',
                  fontSize: typography.cta,
                  fontWeight: typography.weight.bolder,
                  padding: '10px 20px',
                  marginLeft: '10px',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => saveAndClose(newChargerSet)}
              >
                Save{' '}
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NewChargerSetModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  saveAndClose: PropTypes.func,
  discardAndClose: PropTypes.func,
  chargerSet: PropTypes.object,
};

export default NewChargerSetModal;
