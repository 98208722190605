import React from 'react';

// TODO: get generic charger image
const DEFAULT_CHARGER_IMAGE_URL =
  'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png';

const ChargerImage = ({charger}) => {
  return (
    <img
      style={{width: '180px'}}
      className="img-fluid"
      src={charger ? charger.imageUrl : DEFAULT_CHARGER_IMAGE_URL}
      alt={charger.type}
    />
  );
};

export default ChargerImage;
