import React from 'react';
import {formatAsDollarsRounded} from '../utils/format';
import CustomVehicle from './CustomVehicle';

const GridData = ({label, children}) => (
  <div className="d-flex flex-row justify-content-between">
    <div>{label}</div>
    <div className="font-weight-bold">{children}</div>
  </div>
);

const electricEffiencyLabels = {
  'kwh per hour': 'hours/kWh',
  'kwh per mile': 'miles/kWh',
  'kwh per 100 miles': 'miles/kWh', // PLACEHOLDER while API returns different units for passanger and commercial vehicles
};

const getElectricEfficiencyLabel = (unit) => electricEffiencyLabels[unit] || unit;

const getElectricEfficiency = (electricEfficiency, unit) => {
  if (unit === 'kwh per hour') {
    return (1 / electricEfficiency).toFixed(2);
  }

  // PLACEHOLDER while API returns different units for passanger and commercial vehicles
  if (unit === 'kwh per 100 miles') {
    return (100 / electricEfficiency).toFixed(2);
  }

  return (1 / electricEfficiency).toFixed(2);
};

const VehicleCard = ({
  make,
  model,
  trim,
  fuel,
  batteryCapacityInKwh,
  weightClass,
  imageUrl,
  category,
  rangeInMiles,
  rangeInHours,
  electricEfficiency,
  electricEfficiencyUnit,
  msrp,
  msrpIsEstimated,
}) => (
  <div className="card mb-4">
    <div className="card-body">
      <h4 className="card-title mb-1 d-flex flex-row justify-content-between">
        {make}
        <img
          src={fuel === 'PHEV' ? '/images/phev.png' : '/images/bev.png'}
          alt=""
          style={{height: '20px'}}
        />
      </h4>
      <h6 className="card-subtitle text-muted">
        {model} {trim}
      </h6>

      <div className="d-flex align-items-center justify-content-center" style={{height: '200px'}}>
        {imageUrl ? (
          <img className="img-fluid p-2" src={imageUrl} alt={`${make} ${model} ${trim}`} />
        ) : (
          <CustomVehicle />
        )}
      </div>

      <GridData label="Electric Range">
        {category === 'On-Road' ? `${rangeInMiles} miles` : `${rangeInHours} hours`}
      </GridData>
      <GridData label="Weight Class">{weightClass}</GridData>
      <GridData label={getElectricEfficiencyLabel(electricEfficiencyUnit)}>
        {getElectricEfficiency(electricEfficiency, electricEfficiencyUnit)}
      </GridData>
      <GridData label="Battery">{batteryCapacityInKwh} kWh</GridData>
      <GridData label={msrpIsEstimated ? 'Estimated' : 'MSRP'}>
        {formatAsDollarsRounded(msrp)}
      </GridData>
    </div>
  </div>
);

export default VehicleCard;
