import React from 'react';
import InputCard from '../../components/InputCard';
import Select from '../Select';

const SelectRateApplicability = ({value, onChange}) => (
  <InputCard header="Rate Applicability" closeable startOpen>
    <Select
      label="Only Show Applicable Rates"
      value={value}
      onChange={onChange}
      optionValues={['Yes', 'No']}
    />
    <p className="small mt-2 mb-0">
      Depending on your demand, you may be eligible for different rates.
    </p>
  </InputCard>
);

export default SelectRateApplicability;
