import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {Table} from 'react-bootstrap';

import calcTotalKwh from '../../../calcs/calcTotalKwh';
import calcGallonsOfFossilFuel from '../../../calcs/calcGallonsOfFossilFuel';
import A from '../../../data/ASSUMPTIONS';
import {formatAsThousands} from '../../../utils/format';

const Emissions = () => {
  const {vehicleSets, chargers} = useInputs();

  const totalGallonsOfDiesel = calcGallonsOfFossilFuel(vehicleSets, 'diesel');
  const totalGallonsOfGasoline = calcGallonsOfFossilFuel(vehicleSets, 'regular');
  const totalGallonsOfLpg = calcGallonsOfFossilFuel(vehicleSets, 'lpg');
  const totalKwh = calcTotalKwh(chargers, vehicleSets);

  const TreesDiv = () => {
    const height = '1.25rem';
    const margin = '1rem';
    return (
      <div>
        <img src="/images/tree.png" alt="" style={{height, marginLeft: margin}} />
        <img src="/images/tree.png" alt="" style={{height, marginLeft: margin}} />
        <img src="/images/tree.png" alt="" style={{height, marginLeft: margin}} />
      </div>
    );
  };

  const totalCo2Emissions =
    totalGallonsOfDiesel * A.DIESEL_EMISSIONS_IN_LBS_PER_GAL +
    totalGallonsOfGasoline * A.GASOLINE_EMISSIONS_IN_LBS_PER_GAL +
    totalGallonsOfLpg * A.LPG_EMISSIONS_IN_LBS_PER_GAL;
  const co2EmissionsFromEvCharging = (totalKwh * A.ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH) / 1000;
  const co2EmissionsReducedInLbs = totalCo2Emissions - co2EmissionsFromEvCharging;
  const ghgEmissionsReducedInLbs = co2EmissionsReducedInLbs * A.CO2_TO_CO2E_FACTOR;

  return (
    <>
      <div className="h2 text-center d-none d-print-block">Emissions</div>
      <p className="takeaway">While saving money, you will also be saving the environment.</p>
      <div className="table-responsive">
        <Table bordered className="chart-js-styles-for-tables">
          <thead>
            <tr>
              <th>Item</th>
              <th>Diesel</th>
              <th>Gasoline</th>
              <th>LPG</th>
              <th style={{width: '30%'}}>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Fossil Fuel Volume</th>
              <td>{formatAsThousands(totalGallonsOfDiesel)} gal</td>
              <td>{formatAsThousands(totalGallonsOfGasoline)} gal</td>
              <td>{formatAsThousands(totalGallonsOfLpg)} gal</td>
              <td>
                For each vehicle set, we applied each vehicle's mpg to the annual miles driven.
              </td>
            </tr>
            <tr>
              <th>
                Fossil Fuel CO<sub>2</sub> Emissions
              </th>
              <td>
                {A.DIESEL_EMISSIONS_IN_LBS_PER_GAL} lbs CO<sub>2</sub> / gal
              </td>
              <td>
                {A.GASOLINE_EMISSIONS_IN_LBS_PER_GAL} lbs CO<sub>2</sub> / gal
              </td>
              <td>
                {A.LPG_EMISSIONS_IN_LBS_PER_GAL} lbs CO<sub>2</sub> / gal
              </td>
              <td>
                <a
                  href="https://www.eia.gov/environment/emissions/co2_vol_mass.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source: U.S. Energy Information Administration
                </a>
              </td>
            </tr>
            <tr>
              <th>
                CO<sub>2</sub> Emissions by Fossil Fuel
              </th>
              <td>
                {formatAsThousands(totalGallonsOfDiesel * A.DIESEL_EMISSIONS_IN_LBS_PER_GAL)} lbs
              </td>
              <td>
                {formatAsThousands(totalGallonsOfGasoline * A.GASOLINE_EMISSIONS_IN_LBS_PER_GAL)}{' '}
                lbs
              </td>
              <td>{formatAsThousands(totalGallonsOfLpg * A.LPG_EMISSIONS_IN_LBS_PER_GAL)} lbs</td>
              <td>
                "Fossil Fuel Volume" * "CO<sub>2</sub> Emissions In Lbs/Gal"
              </td>
            </tr>
            <tr>
              <th>
                Total CO<sub>2</sub> Emissions
              </th>
              <td colSpan="3" className="text-center">
                {formatAsThousands(totalCo2Emissions)} lbs CO<sub>2</sub>
              </td>
              <td>
                "Diesel CO<sub>2</sub> Emissions" + "Gasoline CO<sub>2</sub> Emissions"
              </td>
            </tr>
            <tr>
              <th>Electricity Equivalent</th>
              <td colSpan="3" className="text-center">
                {formatAsThousands(totalKwh / 1000)} MWh
              </td>
              <td>
                The amount of electricity required to replace the selected fleet of fossil fuel
                vehicles one-to-one with electric vehicles.
              </td>
            </tr>
            <tr>
              <th>
                PG&amp;E Electricity CO<sub>2</sub> Emissions
              </th>
              <td colSpan="3" className="text-center">
                {A.ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH} lbs/MWh
              </td>
              <td>
                <a
                  href="https://www.pgecorp.com/corp_responsibility/reports/2021/assets/PGE_CRSR_2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source: PG&amp;E (see page 99)
                </a>
              </td>
            </tr>
            <tr>
              <th>
                CO<sub>2</sub> Emissions from EV charging
              </th>
              <td colSpan="3" className="text-center">
                {formatAsThousands(co2EmissionsFromEvCharging)} lbs CO <sub>2</sub>
              </td>
              <td>
                "Electricity Equivalent" * "PG&amp;E Electricity CO<sub>2</sub> Emissions"
              </td>
            </tr>
            <tr>
              <th>
                CO<sub>2</sub> Emissions Reduced
              </th>
              <td colSpan="3" className="text-center">
                {formatAsThousands(co2EmissionsReducedInLbs)} lbs CO<sub>2</sub>
              </td>
              <td>
                "Total Emissions from Fossil Fuels" - "CO<sub>2</sub> Emissions for Equivalent EVs"
              </td>
            </tr>
            <tr>
              <th>CO2 to GHG conversion</th>
              <td colSpan="3" className="text-center">
                {A.CO2_TO_CO2E_FACTOR}
              </td>
              <td>Conversion based on global warming potential of the primary greenhouse gases</td>
            </tr>
            <tr>
              <th>GHG Emissions Reduced</th>
              <td colSpan="3" className="text-center">
                {formatAsThousands(ghgEmissionsReducedInLbs)} lbs GHG
              </td>
              <td>
                CO<sub>2</sub> Emissions Reduced converted to GHG
              </td>
            </tr>
            <tr>
              <th>GHG Emissions Reduced (Simplified)</th>
              <td colSpan="3" className="text-center">
                <strong>{formatAsThousands(ghgEmissionsReducedInLbs / 2000)} tons GHG</strong>
              </td>
              <td>A US ton is equivalent to 2,000 lbs</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="text-center mt-5">
        <TreesDiv />
        <p className="lead mt-2 mb-1">
          A tree absorbs{' '}
          <a
            href="https://www.arborday.org/trees/treefacts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            48 pounds of CO<sub>2</sub> per year
          </a>
          , which makes this configuration equivalent to planting{' '}
          <strong>
            {formatAsThousands(ghgEmissionsReducedInLbs / 48)}
            &nbsp;trees
          </strong>{' '}
          annually.
        </p>
        <TreesDiv />
      </div>
    </>
  );
};

export default Emissions;
