import calcMilesPerYear from './calcMilesPerYear';
import calcHoursPerYear from './calcHoursPerYear';

const calcGallonsOfFossilFuelForVehicleSet = (vehicleSet) => {
  const {vehicle} = vehicleSet;

  return vehicle.category === 'On-Road'
    ? calcMilesPerYear(vehicleSet) / vehicle.fossilMilesPerGallon
    : calcHoursPerYear(vehicleSet) * vehicle.fossilGallonsPerHour; // Note that gallons are in the numerator here, hence multiplication rather than division
};

export default calcGallonsOfFossilFuelForVehicleSet;
