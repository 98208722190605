import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useInputs} from '@bellawatt/use-inputs';
import {DEFAULT_VEHICLE_SET} from '../defaults';
import NewVehicleSetModal from '../components/NewVehicleSetModal';
import {colors, typography} from '../styles';
import {Pencil} from '../icons';
import VehicleImage from '../graphs/VehicleImage';
import {isEqual} from 'lodash';
import {formatVehicleName} from '../utils/format';

const MAX_VEHICLE_SETS = 4;

const VehicleSet = ({idx, set, onClick}) => (
  <div
    onClick={onClick}
    role="button"
    onKeyUp={(e) => e.key === 'Enter' && onClick()}
    tabIndex={idx}
    style={{
      width: '100%',
      cursor: 'pointer',
      border: `1px solid ${colors.grey.lighter}`,
      borderRadius: '4px',
      padding: '20px',
      margin: '20px 0',
    }}
    className="hover-vehicle-set-card"
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div
          style={{
            fontSize: typography.headers[5],
            fontWeight: typography.weight.bold,
          }}
        >
          {set.vehicleCount}x {formatVehicleName(set)}
        </div>
      </div>
      <div className="pencil">
        <Pencil />
      </div>
    </div>

    <div style={{display: 'flex', alignItems: 'center'}}>
      <div style={{width: '35%'}}>
        <VehicleImage vehicle={set.vehicle} />
      </div>
      <div style={{width: '75%', margin: '15px', fontSize: typography.label}}>
        {set.archetype.category === 'On-Road' && (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{color: '#00000080'}}>Miles per vehicle</div>
            <div style={{fontWeight: typography.weight.bold}}>{set.milesPerWorkday}</div>
          </div>
        )}
        {set.archetype.category !== 'On-Road' && (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{color: '#00000080'}}>Hours per vehicle</div>
            <div style={{fontWeight: typography.weight.bold}}>{set.hoursPerWorkday}</div>
          </div>
        )}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{color: '#00000080'}}>Days Operating</div>
          <div style={{fontWeight: typography.weight.bold}}>
            {isEqual(set.workdays, [1, 2, 3, 4, 5])
              ? 'Weekdays'
              : set.workdays
                  .sort()
                  .map((day) => moment(day, 'd').format('dd'))
                  .join(', ')}
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{color: '#00000080'}}>Charging:&nbsp;</div>
          <div style={{fontWeight: typography.weight.bold}}>
            {set.chargingWindows
              .map(
                (chargingWindow) =>
                  `${moment(chargingWindow.start, 'H').format('ha')} - ${moment(
                    chargingWindow.finish,
                    'H'
                  ).format('ha')}`
              )
              .join(', ')}
          </div>
        </div>
      </div>
    </div>
  </div>
);

VehicleSet.propTypes = {
  idx: PropTypes.number,
  vehicleCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  milesPerWorkday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chargingWindows: PropTypes.array,
  onClick: PropTypes.func,
  category: PropTypes.string,
  type: PropTypes.string,
  subtype: PropTypes.string,
  workdays: PropTypes.array,
};

const VehicleSets = () => {
  const {vehicleSets, setInput} = useInputs();
  const [selectedVehicleSet, setSelectedVehicleSet] = useState(null);

  const saveAndClose = (newVehicle) => {
    if (newVehicle.idx !== -1) {
      const newVehicleSets = vehicleSets.slice();
      newVehicleSets[newVehicle.idx] = newVehicle;
      setInput({vehicleSets: newVehicleSets});
    } else {
      setInput({vehicleSets: vehicleSets.concat(newVehicle)});
    }
    setSelectedVehicleSet(null);
  };

  const discardAndClose = (vehicle) => {
    if (vehicle.idx !== -1) {
      setInput({vehicleSets: vehicleSets.filter((_, idx) => idx !== vehicle.idx)});
    }

    setSelectedVehicleSet(null);
  };

  return (
    <>
      {vehicleSets.map((vehicleSet, idx) => (
        <VehicleSet
          set={vehicleSet}
          key={idx}
          onClick={() => setSelectedVehicleSet({...vehicleSet, idx})}
        />
      ))}
      {selectedVehicleSet && (
        <NewVehicleSetModal
          vehicleSet={selectedVehicleSet}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          isOpen
          handleClose={() => setSelectedVehicleSet(null)}
        />
      )}
      {vehicleSets.length < MAX_VEHICLE_SETS && (
        <button
          type="button"
          onClick={() => setSelectedVehicleSet({...DEFAULT_VEHICLE_SET})}
          className="btn btn-link pl-0"
        >
          + Add a new vehicle
        </button>
      )}
    </>
  );
};

export default VehicleSets;
