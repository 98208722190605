import React from 'react';
import PropTypes from 'prop-types';
import {Arrow} from '../../../icons';

const MobileTabs = ({tabs, currentTab, onChange}) => {
  const currentTabIndex = tabs.indexOf(currentTab);

  const goLeft = () => {
    if (currentTabIndex === 0) {
      onChange(tabs[tabs.length - 1]);
    } else {
      onChange(tabs[currentTabIndex - 1]);
    }
  };

  const goRight = () => {
    onChange(tabs[(currentTabIndex + 1) % tabs.length]);
  };

  return (
    <div className="mobile-tabs d-print-none">
      <div className="nav-link arrow">
        <button
          onClick={goLeft}
          type="button"
          className="btn btn-faint"
          style={{fontSize: '30px'}}
          aria-label="Left Arrow"
        >
          <Arrow direction="left" />
        </button>
      </div>
      <div className="nav-link tab">{tabs[currentTabIndex]}</div>
      <div className="nav-link arrow">
        <button
          onClick={goRight}
          type="button"
          className="btn btn-faint"
          style={{fontSize: '30px'}}
          aria-label="Right Arrow"
        >
          <Arrow direction="right" />
        </button>
      </div>
    </div>
  );
};

MobileTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MobileTabs;
