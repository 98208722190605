import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import times from 'lodash/times';
import flatMap from 'lodash/flatMap';
import BaseGraph from './BaseGraph';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import calcResaleValue from '../calcs/calcResaleValue/calcResaleValue';
import ASSUMPTIONS from '../data/ASSUMPTIONS';

const createLabel = (vehicle) => vehicle.name === 'Generic' ? `${vehicle.type} (${vehicle.subtype})` : vehicle.name;

const DepreciationAndResale = () => {
  const {vehicleSets, yearsOfOperation} = useInputs();

  const vehicleSetDepreciation = flatMap(vehicleSets, (vehicleSet) => ([{
    label: createLabel(vehicleSet.vehicle),
    purchaseCosts: vehicleSet.vehicle.msrp,
    resaleValue: calcResaleValue(
      vehicleSet.vehicle.msrp,
      vehicleSet.vehicle.duty,
      yearsOfOperation
    ),
    stack: 'Electric',
  }, {
    label: `${createLabel(vehicleSet.vehicle)} Archetype`,
    purchaseCosts: vehicleSet.archetype.fossilFuelMsrp,
    resaleValue: calcResaleValue(
      vehicleSet.archetype.fossilFuelMsrp,
      vehicleSet.archetype.duty,
      yearsOfOperation
    ),
    stack: 'Fossil',
  }]));

  const data = {
    labels: times(yearsOfOperation, (year) => `Year ${year + 1}`),
    datasets: vehicleSetDepreciation.map(({label, purchaseCosts, resaleValue, stack}, i) => {
      const depreciationSlope = (resaleValue - purchaseCosts) / yearsOfOperation;
      const stackColors = stack === 'Electric' ? ASSUMPTIONS.ELECTRICITY_SET_COLORS : ASSUMPTIONS.OIL_SET_COLORS;

      return {
        label,
        stack,
        data: times(yearsOfOperation, (year) => depreciationSlope * (year + 1) + purchaseCosts),
        backgroundColor: stackColors[i % stackColors.length],
      };
    }),
  };

  return (
    <BaseGraph
      title="Depreciation And Resale"
      type="bar"
      data={data}
      yFormatCallback={formatAsDollarsRounded}
      tooltipCallback={formatAsDollars}
      pointRadius={3}
      stacked
    />
  );
};

export default DepreciationAndResale;
