import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import {colors} from '../styles';
import feedbackService from '../services/feedbackService';

const FeedbackModal = ({isOpen, close}) => {
  const [sent, setSent] = useState(false);
  const [goal, setGoal] = useState('');
  const [achievedGoal, setAchievedGoal] = useState(null);
  const [suggestions, setSuggestions] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);

  const submitFeedback = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    setHasError(false);

    const data = {
      goal,
      goal_achieved: achievedGoal,
      suggestions,
    };
    // Remove null data
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);

    try {
      await feedbackService(data);

      setIsSaving(false);
      setSent(true);
      setGoal('');
      setSuggestions('');
      setAchievedGoal(null);
    } catch (e) {
      setIsSaving(false);
      setHasError(true);
      throw e;
    }
  };

  const someDataExists = goal.length > 0 || achievedGoal !== null || suggestions.length > 0;
  const isSubmittable = !isSaving && someDataExists;

  return (
    <Modal show={isOpen} onHide={close} size="sm">
      <Modal.Header closeButton>Feedback</Modal.Header>
      <Modal.Body>
        {sent ? (
          <div>Thanks for your feedback!</div>
        ) : (
          <>
            {hasError && (
              <Alert variant="danger">
                There was an error sending your feedback! Please try again later.
              </Alert>
            )}
            <Form onSubmit={submitFeedback}>
              <Form.Group>
                <Form.Label>What is your goal of visiting fleets.pge.com?</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={goal}
                  onChange={(e) => setGoal(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Did you achieve the goal of your visit?</Form.Label>
                <Form.Check
                  type="radio"
                  name="achievedGoal"
                  id="achievedGoal-true"
                  value="true"
                  checked={achievedGoal === true}
                  onChange={() => setAchievedGoal(true)}
                  label="Yes"
                />

                <Form.Check
                  type="radio"
                  name="achievedGoal"
                  id="achievedGoal-false"
                  value="false"
                  checked={achievedGoal === false}
                  onChange={() => setAchievedGoal(false)}
                  label="No"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>How would you improve this tool?</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  name="feedback_suggestions"
                  id="Feedback_suggestions"
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                />
              </Form.Group>
              <button
                type="submit"
                className="btn btn-secondary float-right"
                disabled={!isSubmittable}
              >
                {isSaving ? 'Submitting...' : 'Submit'}
              </button>
            </Form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

const Feedback = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FeedbackModal isOpen={modalOpen} close={() => setModalOpen(false)} />
      <button
        type="button"
        className="d-none d-md-block d-print-none"
        style={{
          zIndex: 99,
          backgroundColor: colors.blue.base,
          color: colors.white,
          position: 'fixed',
          top: '30%',
          padding: '10px 20px',
          transformOrigin: 'left top 0',
          transform: 'rotate(270deg)',
          border: 0,
        }}
        onClick={() => setModalOpen(true)}
      >
        Feedback
      </button>
    </>
  );
};

export default Feedback;
