import {useInputs} from '@bellawatt/use-inputs';
import useApiIncentives from './useApiIncentives';
import {useState, useEffect} from 'react';
import {post} from './api';
import keyBy from 'lodash/keyBy';
import groupByChargers from '../calcs/groupByChargers';
import {ConfiguredHoneybadger} from '../components/ErrorBoundary';

const yesNoToTrueFalse = (yesNo) => yesNo.toLowerCase() === 'yes';

export default function useEvaluatedApiIncentives() {
  const {
    vehicleSets,
    isTaxExempt,
    isDAC,
    organizationType,
    yearsOfOperation,
    zipCode,
    btmConstructionCost,
    btmEvseCost,
    isPublic,
    replacesIceVehicle,
    loading: inputsLoading,
  } = useInputs();
  const [loadingEvaluated, setLoadingEvaluated] = useState(true);
  const [error, setError] = useState(false);
  const [evaluatedIncentives, setEvaluatedIncentives] = useState([]);
  const {loading: loadingIncentives, incentives} = useApiIncentives();
  const chargers = inputsLoading ? [] : groupByChargers(vehicleSets);

  const isTransitParkingFacility =
    inputsLoading || vehicleSets.some(({vehicle}) => vehicle.formFactor === 'transit_bus');

  const payload = inputsLoading
    ? {}
    : {
        project: {
          project_type: 'fleets',
          locationEnum: isTransitParkingFacility
            ? 'transit_parking_facility'
            : 'workplace_facility',
          estimated_installation_costs: btmConstructionCost,
          projected_cost: btmConstructionCost + btmEvseCost,
          years_of_operation: yearsOfOperation,

          organization: {
            organization_type: organizationType,
            is_tax_exempt: yesNoToTrueFalse(isTaxExempt),
            is_public: yesNoToTrueFalse(isPublic),
          },

          site: {
            address: {
              postcode: zipCode,
              region: 'CA',
              power_supplier: {
                name: 'PGE',
              },
            },
            in_a_dac: yesNoToTrueFalse(isDAC),
          },

          chargers: chargers.map((charger) => ({
            count: charger.minimumRequired,
            identifier: charger.type,
            is_open_to_public: false,
            is_networked: true,
            details: {
              type: 'charger',
              is_energy_star_certified: true,
              purchase_price: charger.estimatedCost,
              charger_power_level: charger.portKw,
              number_of_connections: charger.ports,
              charger_type: charger.chargerType,
            },
          })),

          vehicles: vehicleSets.map(({vehicle, vehicleCount}) => ({
            count: vehicleCount,
            type: 'vehicle',
            details: {
              type: 'vehicle',
              subtype: vehicle.subtype,
              make: vehicle.make || 'Custom',
              model: vehicle.model,
              trim: vehicle.trim,
              fuel: vehicle.fuel || 'bev',
              weight_class: vehicle.weightClass,
              model_year: vehicle.year,
              vehicle_type: vehicle.type,
              form_factor: vehicle.formFactor,
              msrp: vehicle.msrp,
              purchase_price: vehicle.msrp,
              lift_capacity: vehicle.liftCapacity,
              battery_capacity: vehicle.batteryCapacityInKwh,
              years_of_operation: yearsOfOperation,
              replaces_ice_vehicle: yesNoToTrueFalse(replacesIceVehicle),
              identifier: vehicle.id,
              lease_length: null,
              eligible_for_ca_rebate: vehicle.eligibleForCaRebate,
            },
          })),
        },
      };

  useEffect(() => {
    if (inputsLoading) {
      return;
    }

    setLoadingEvaluated(true);
    post('/project', payload)
      .then((res) => {
        setEvaluatedIncentives(res.data.evaluations.incentives);
        setLoadingEvaluated(false);
      })
      .catch((err) => {
        console.error(err);
        ConfiguredHoneybadger.notify(err);
        setError(true);
        setLoadingEvaluated(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(payload)]);

  const loading = loadingEvaluated || loadingIncentives;

  let combinedIncentives = [];

  if (!loading) {
    const keyedIncentives = keyBy(incentives, 'incentive_handle');
    combinedIncentives = evaluatedIncentives
      .filter(({handle}) => keyedIncentives[handle])
      .map((incentive) => ({
        ...incentive,
        ...keyedIncentives[incentive.handle],
      }));
  }

  return {loading, error, evaluatedIncentives: combinedIncentives};
}
