import calcWeeklyLoadProfiles from './calcWeeklyLoadProfiles';
import times from 'lodash/times';

const calcTotalWeeklyLoadProfile = (chargers, vehicleSets) => {
  const weeklyLoadProfiles = calcWeeklyLoadProfiles(chargers, vehicleSets);

  // possible refactor opportunity: is there a functional way of writing this?
  const totalWeeklyLoadProfile = times(24 * 7, (i) => {
    let hourlyLoad = 0;
    for (let j = 0; j < weeklyLoadProfiles.length; j++) {
      hourlyLoad += weeklyLoadProfiles[j][i];
    }
    return hourlyLoad;
  });

  return totalWeeklyLoadProfile;
};

export default calcTotalWeeklyLoadProfile;
