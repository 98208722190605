import React from 'react';
import Insurance from '../../../graphs/Insurance';
import Maintenance from '../../../graphs/Maintenance';

const Operations = () => {
  return (
    <>
      <div className="h2 text-center d-none d-print-block">Operations</div>
      <div className="title-for-graphs-and-tables text-center">Maintenance</div>
      <p className="takeaway">
        The annual cost to maintain vehicles varies between electric and gas-powered vehicles, and escalates as the vehicles age.
      </p>
      <Maintenance />

      <div className="my-5" />
      <div className="title-for-graphs-and-tables text-center">Insurance</div>
      <p className="takeaway">
        The cost to insure a vehicle depends on the vehicle purchase price and the state.
      </p>
      <Insurance />
    </>
  );
};

export default Operations;
