import PropTypes from 'prop-types';
import {times, sum, max, min} from 'lodash';
import calcPlugSchedule from '../calcPlugSchedule';
import ASSUMPTIONS from '../../data/ASSUMPTIONS';

const calcBatterySchedule = (chargers, vehicleSet, applyMaxCharging) => {
  const {vehicle} = vehicleSet;
  const plugSchedule = calcPlugSchedule(chargers, vehicleSet);

  const kwhPerMile = vehicle.batteryCapacityInKwh / vehicle.rangeInMiles;
  const kwhPerHour = vehicle.batteryCapacityInKwh / vehicle.rangeInHours;
  const idling = vehicle.category === 'TRU' ? 1 - ASSUMPTIONS.TRU_IDLING_FACTOR : 1;
  const kwhPerDay =
    vehicle.category === 'On-Road'
      ? vehicleSet.milesPerWorkday * kwhPerMile
      : vehicleSet.hoursPerWorkday * kwhPerHour;

  const hoursOfCharging = plugSchedule.filter((plug) => plug.portKw).length;
  const hoursAwayFromChargers = 24 - hoursOfCharging;
  const consumedKwhPerHour = (kwhPerDay * idling) / hoursAwayFromChargers;
  const evenPowerDrawInKwhPerHour = kwhPerDay / hoursOfCharging;

  let vehicleBatterySchedule = times(7 * 24, () => ({
    chargeAtHourStart: null,
    chargeAtHourEnd: null,
    kwhPowerDrawn: null,
    kwhConsumed: null,
  }));

  for (let idx = 0; idx < vehicleBatterySchedule.length; idx++) {
    const hour = idx % 24;
    const day = Math.floor(idx / 24) === 6 ? 0 : Math.floor(idx / 24) + 1;
    // optional to-do: incorporate isBevPeak?
    // const isBevPeak = hour >= A.BEV_RATE_PEAK_TIMES.start && hour < A.BEV_RATE_PEAK_TIMES.finish;
    const isOperating = vehicleSet.workdays.includes(day);
    const {chargingApproach} = plugSchedule[hour];

    const chargeAtHourStart =
      idx === 0 ? vehicle.batteryCapacityInKwh : vehicleBatterySchedule[idx - 1].chargeAtHourEnd;

    const availablePowerInKw =
      applyMaxCharging || chargingApproach === 'Max'
        ? plugSchedule[hour].portKw
        : Math.min(plugSchedule[hour].portKw, evenPowerDrawInKwhPerHour); // even if approach is "even", the available power shouldn't surpass the charger's kW availability

    const actualPowerDrawInKwh = chargingApproach
      ? Math.min(availablePowerInKw, vehicle.batteryCapacityInKwh - chargeAtHourStart)
      : 0;

    const kwhConsumed = isOperating && !chargingApproach ? consumedKwhPerHour : 0;

    const chargeAtHourEnd = Math.min(
      vehicle.batteryCapacityInKwh,
      chargeAtHourStart + actualPowerDrawInKwh - kwhConsumed
    );

    vehicleBatterySchedule[idx].chargeAtHourStart = chargeAtHourStart;
    vehicleBatterySchedule[idx].chargeAtHourEnd = chargeAtHourEnd;
    vehicleBatterySchedule[idx].kwhPowerDrawn = actualPowerDrawInKwh;
    vehicleBatterySchedule[idx].kwhConsumed = kwhConsumed;
  }

  const batterySchedule = vehicleBatterySchedule.map((schedule) => ({
    chargeAtHourStart: schedule.chargeAtHourStart * vehicleSet.vehicleCount,
    chargeAtHourEnd: schedule.chargeAtHourEnd * vehicleSet.vehicleCount,
    kwhPowerDrawn: schedule.kwhPowerDrawn * vehicleSet.vehicleCount,
    kwhConsumed: schedule.kwhConsumed * vehicleSet.vehicleCount,
  }));

  return {
    weeklyBatterySchedule: batterySchedule,
    totals: {
      chargeAtHourStart: sum(batterySchedule.map((schedule) => schedule.chargeAtHourStart)),
      chargeAtHourEnd: sum(batterySchedule.map((schedule) => schedule.chargeAtHourEnd)),
      kwhPowerDrawn: sum(batterySchedule.map((schedule) => schedule.kwhPowerDrawn)),
      kwhConsumed: sum(batterySchedule.map((schedule) => schedule.kwhConsumed)),
    },
    minStateOfCharge:
      min(batterySchedule.map((schedule) => schedule.chargeAtHourStart)) /
      max(batterySchedule.map((schedule) => schedule.chargeAtHourStart)),
  };
};

calcBatterySchedule.propTypes = {
  vehicleSet: PropTypes.object,
};

export default calcBatterySchedule;
