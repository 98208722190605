import React from 'react';
import times from 'lodash/times';
import BaseGraph from './BaseGraph';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import A from '../data/ASSUMPTIONS';
import {colors} from '../styles';

const LcfsRevenues = ({lcfsRevenues}) => {
  const data = {
    labels: A.LCFS_YEARS,
    datasets: [
      {
        label: 'Value of LCFS Credits', // TODO: do not display label in graph
        data: lcfsRevenues,
        borderWidth: 1,
        borderColor: '#dbdbdb',
        backgroundColor: () =>
          ['white', colors.green.darkest].concat(
            ...times(A.LCFS_YEARS.length - 2, () => colors.green.lighter)
          ),
      },
    ],
  };

  return (
    <BaseGraph
      title="Estimated LCFS Revenues"
      data={data}
      stacked
      options={{legend: {display: false}}}
      yFormatCallback={formatAsDollarsRounded}
      tooltipCallback={formatAsDollars}
    />
  );
};

export default LcfsRevenues;
