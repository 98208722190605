import calcGallonsOfFossilFuelForVehicleSet from './calcGallonsOfFossilFuelForVehicleSet';

const calcFossilFuelCostForVehicleSet = (
  vehicleSet,
  dieselPrice,
  gasolinePrice,
  lpgPrice
) => {
  const {vehicle} = vehicleSet;

  const gallons = calcGallonsOfFossilFuelForVehicleSet(vehicleSet);

  const priceDictionary = {
    diesel: dieselPrice,
    regular: gasolinePrice,
    lpg: lpgPrice,
  };
  const price = priceDictionary[vehicle.fossilFuelType];

  return price * gallons;
};

export default calcFossilFuelCostForVehicleSet;
