import calcResaleValue from './calcResaleValue/calcResaleValue';
import {sum, times, unzip} from 'lodash';
import calcAnnualFossilFuelCost from './calcAnnualFossilFuelCost';
import {calcFossilMaintenanceCostByYear} from './calcMaintenanceCostByYear';
import calcAnnualInsuranceCost from './calcAnnualInsuranceCost';

const calcFossilTcoByYear = (
  yearsOfOperation,
  vehicleSets,
  dieselPrice,
  gasolinePrice,
  lpgPrice
) => {
  const vehicleCosts = sum(
    vehicleSets.map(({archetype, vehicleCount}) => vehicleCount * archetype.fossilFuelMsrp)
  );

  const resaleValues = sum(
    vehicleSets.map(
      ({archetype, vehicleCount}) =>
        vehicleCount * calcResaleValue(archetype.fossilFuelMsrp, archetype.duty, yearsOfOperation)
    )
  );

  const annualFossilFuelCost = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const maintenance = unzip(
    vehicleSets.map((vehicleSet) => calcFossilMaintenanceCostByYear({vehicleSet, yearsOfOperation}))
  ).map((arr) => sum(arr));

  const insurance = sum(
    vehicleSets.map(({archetype}) => calcAnnualInsuranceCost(archetype.fossilFuelMsrp))
  );

  return times(yearsOfOperation, (year) => {
    const annualCosts = annualFossilFuelCost + maintenance[year] + insurance;

    if (year === 0) {
      return vehicleCosts + annualCosts;
    }

    if (year === yearsOfOperation - 1) {
      return annualCosts - resaleValues;
    }

    return annualCosts;
  });
};

export default calcFossilTcoByYear;
