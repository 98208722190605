import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {Link} from 'react-router-dom';
import {Alert} from 'react-bootstrap';

import AnnualFuelCosts from '../../../graphs/AnnualFuelCosts';
import BevHourlyEnergyCharges from '../../../graphs/BevHourlyEnergyCharges';
import WeeklyBatterySchedule from '../../../graphs/WeeklyBatterySchedule';
import WeeklyLoadProfile from '../../../graphs/WeeklyLoadProfile';
import BevCostComponents from '../../../graphs/BevCostComponents';

import calcBevCosts from '../../../calcs/calcBevCosts/calcBevCosts';
import calcAnnualElectricityCost from '../../../calcs/calcAnnualElectricityCost';
import calcAnnualFossilFuelCost from '../../../calcs/calcAnnualFossilFuelCost';
import calcTotalWeeklyLoadProfile from '../../../calcs/calcTotalWeeklyLoadProfile';
import calcIdealBevRateName from '../../../calcs/calcIdealBevRateName';
import calcIdealBevBlocks from '../../../calcs/calcIdealBevBlocks';

import {formatAsDollars, formatAsDollarsRounded} from '../../../utils/format';

const Fuel = ({overwriteRateInputs}) => {
  const {
    vehicleSets,
    bevRateName,
    bevBlocks,
    chargers,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
  } = useInputs();

  const weeklyLoadProfile = calcTotalWeeklyLoadProfile(chargers, vehicleSets);

  const rateName = overwriteRateInputs ? calcIdealBevRateName(weeklyLoadProfile) : bevRateName;
  const rateBlocks = overwriteRateInputs
    ? calcIdealBevBlocks(rateName, weeklyLoadProfile)
    : bevBlocks;

  const annualElecticityCost = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    true
  );

  const annualFossilFuelCost = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const bevCosts = calcBevCosts(    weeklyLoadProfile,
    rateName,
    rateBlocks,
    overwriteRateInputs)

  const savings = annualFossilFuelCost - annualElecticityCost;

  const windowIsBevPage = window.location.pathname === '/bev-rate';

  return (
    <>
      <div className="h2 text-center d-none d-print-block">Fuel</div>
      <p className="takeaway">
        Based on your selections, using electricity instead of fossil fuel saves{' '}
        <strong>{formatAsDollarsRounded(savings)}</strong> per year.
      </p>

      <AnnualFuelCosts overwriteRateInputs />
      {!windowIsBevPage && (
        <Alert
          className="text-center mt-5"
          style={{backgroundColor: '#b8d7ed', padding: '20px 20px 0 20px'}}
        >
          <p>
            To maximize BEV rate inputs, we have set your rate to <strong>{rateName}</strong>, with
            a subscription level of <strong>{rateBlocks} blocks</strong>. Check out the{' '}
            <Link to="/bev-rate">Business EV Rate Calculator</Link> to explore your options.
          </p>
        </Alert>
      )}
      <p className="takeaway">
        The total monthly cost would be{' '}
        <strong>{formatAsDollars(annualElecticityCost / 12)}</strong>, which includes the cost to
        recharge to full and the subscription charges
        {windowIsBevPage && ` (as input on the left-hand side)`}.
      </p>
      <BevCostComponents electricityCosts={bevCosts} />

      <hr />
      <p className="takeaway">
        Every mile driven depletes the battery of electric vehicles. Over the course of a week, the
        vehicle batteries will follow the schedule below:
      </p>
      <WeeklyBatterySchedule />

      <hr />
      <p className="takeaway">
        The site's electricity load will be the aggregate of all vehicles and their charging
        patterns, as shown for each weekday:
      </p>
      <WeeklyLoadProfile vehicleSets={vehicleSets} />

      <hr />
      <p className="takeaway">
        Electricity rates are designed to encourage charging at certain hours. PG&amp;E recently
        created the Business EV Rate with fleets in mind. To minimize costs, configure the vehicles
        to charge during the cheapest hours.
      </p>
      <BevHourlyEnergyCharges />
    </>
  );
};

export default Fuel;
