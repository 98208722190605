import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import {Row, Col} from 'react-bootstrap';
import PageLayout from '../layout/PageLayout';
import Card from '../components/InputCard';
import SlideBevBlocks from '../inputs/components/SlideBevBlocks';
import SelectVoltage from '../inputs/components/SelectVoltage';
import VehicleSets from '../inputs/VehicleSets';
import calcTotalWeeklyLoadProfile from '../calcs/calcTotalWeeklyLoadProfile';
import Loading from '../components/Loading';
import {DEFAULT_VEHICLE_SET} from '../defaults';
import FirstVehicleModal from '../components/FirstVehicleModal';
import RateComparisonChart from '../graphs/RateComparisonChart';
import SelectVehiclesOrChargers from '../inputs/components/SelectVehiclesOrChargers';
import ChargerSets from '../inputs/ChargerSets';
import RateBreakdownTable from '../graphs/RateBreakdownTable';
import calcTotalChargerSetLoadProfile from '../calcs/calcTotalChargerSetLoadProfile';
import ChargingFacilityType from '../inputs/components/ChargingFacilityType';
import RateComparisonWeeklyLoadProfile from '../graphs/RateComparisonWeeklyLoadProfile';
import SelectRateApplicability from '../inputs/components/SelectRateApplicability';
import RateComparisonSelectBevRateName from '../inputs/components/RateComparisonSelectBevRateName';
import RateElementDescriptions from '../components/RateElementDescriptions';

const RateComparisonPage = () => {
  const {
    vehicleSets,
    chargerSets,
    chargingFacilityType,
    estimationApproach,
    onlyShowApplicableRates,
    chargers,
    loading,
    setInput,
  } = useInputs();

  if (loading) {
    return (
      <PageLayout page="Rate Comparison">
        <Loading />
      </PageLayout>
    );
  }

  const weeklyLoadProfile =
    estimationApproach === 'Vehicles'
      ? calcTotalWeeklyLoadProfile(chargers, vehicleSets)
      : calcTotalChargerSetLoadProfile({
          chargerSets,
          timeRange: 'weekly',
          facilityType: chargingFacilityType,
        });

  return (
    <PageLayout page="Rate Comparison">
      <Meta>
        <Meta.Title>PG&amp;E Fleets: Rate Comparison</Meta.Title>
        <Meta.Description>
          The Business EV Rate was specifically designed to reduce EV charging costs for businesses
          and public organizations.
        </Meta.Description>
        <Meta.Image src="/images/home-background.jpg" />
      </Meta>
      <FirstVehicleModal
        isOpen={vehicleSets.length === 0}
        handleClose={() => setInput({vehicleSets: [DEFAULT_VEHICLE_SET]})}
        saveAndClose={(newVehicleSet) => setInput({vehicleSets: [newVehicleSet]})}
      />
      <Row className="mt-5">
        <Col lg={{span: 10, offset: 1}} style={{padding: '0 3rem 3rem 3rem'}}>
          <h2 className="text-center">Rate Comparison</h2>

          <p>
            The Business EV Rate was specifically designed to reduce EV charging costs for
            businesses and public organizations. This page compares the Business EV Rate to other
            applicable commercial rates based on maximum demand, such as{' '}
            <a
              href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_B-6.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              B-6
            </a>
            ,{' '}
            <a
              href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_B-10.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              B-10
            </a>
            ,{' '}
            <a
              href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_B-19.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              B-19
            </a>
            , and{' '}
            <a
              href="https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_B-20.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              B-20
            </a>
            .
          </p>
          <p>
            For comparison purposes, rate add-ons such as Peak-Day Pricing and Standby Charges are
            not included.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <Row>
            <Col>
              <SelectVehiclesOrChargers
                value={estimationApproach}
                onChange={(estimationApproach) => setInput({estimationApproach})}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {estimationApproach === 'Chargers' && (
                <Card header="Charging Utilization by Facility Type" closeable startOpen>
                  <ChargingFacilityType />
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {estimationApproach === 'Vehicles' && (
                <Card header="Vehicles" closeable startOpen>
                  <VehicleSets />
                </Card>
              )}
              {estimationApproach === 'Chargers' && (
                <Card header="Chargers" closeable startOpen>
                  <ChargerSets />
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SelectRateApplicability
                value={onlyShowApplicableRates ? 'Yes' : 'No'}
                onChange={(value) => setInput({onlyShowApplicableRates: value === 'Yes'})}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card header="BEV Rate" closeable startOpen>
                <RateComparisonSelectBevRateName maxKw={Math.max(...weeklyLoadProfile)} />
                <SlideBevBlocks maxKw={Math.max(...weeklyLoadProfile)} />
                <SelectVoltage />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={8}>
          <Card>
            <br />
            <RateComparisonChart />

            <RateBreakdownTable />

            <RateElementDescriptions />

            <br />
            <RateComparisonWeeklyLoadProfile />
            <div className="mt-5">
              <p className="lead">For more information, please visit:</p>
              <a
                href="https://www.pge.com/en_US/small-medium-business/energy-alternatives/clean-vehicles/ev-charge-network/electric-vehicle-rate-plans.page?WT.mc_id=Vanity_businessevrate"
                target="_blank"
                rel="noreferrer noopener"
              >
                Business Electric Vehicle Rate Plan
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.pge.com/pge_global/common/pdfs/solar-and-vehicles/ev-charge-network/BusinessEVrate-fs.pdf"
              >
                Charge Your EV for Less
              </a>
              <br />
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.pge.com/tariffs/index.page"
              >
                Current Rate Schedules
              </a>
            </div>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default RateComparisonPage;
