import calcLcfsCreditsByYear from './calcLcfsCreditsByYear';

const calcAnnualLcfsCredit = (chargers, vehicleSets, gridIntensityInGramsCO2ePerMJ) => {
  const lcfsCreditsByYear = calcLcfsCreditsByYear(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );

  // refactor opportunity: make dynamic for '2021'
  return lcfsCreditsByYear[1];
};

export default calcAnnualLcfsCredit;
