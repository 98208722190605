/* eslint-disable camelcase */
import React, {useState} from 'react';
import Truncate from 'react-truncate';
import {colors} from '../styles';
import {formatAsDollars} from '../utils/format';

const EvaluatedGrantCard = ({
  incentive_name,
  grantor_name,
  eligibility_rationale,
  incentive_url,
  incentive_application_url,
  eligibility,
  estimated_amount,
  isEligible,
}) => {
  const [truncated, setTruncated] = useState(true);

  const Ellipsis = () => (
    <span>
      ...{' '}
      <button
        onClick={() => setTruncated(false)}
        className="d-inline btn btn-xs btn-link p-0"
        type="button"
      >
        more
      </button>
    </span>
  );

  return (
    <div
      className="card my-2"
      style={{
        borderRadius: 0,
        borderLeft: `5px solid ${isEligible ? colors.brand.blue : colors.grey.lighter}`,
      }}
    >
      <div className="card-body p-sm-4">
        <div className="d-flex text-uppercase justify-content-between mb-4 mt-1">
          <div className="font-weight-500">{grantor_name}</div>
          <div>
            <span className="d-none d-sm-block">
              Status / <strong>{eligibility}</strong>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9 mb-3">
            {isEligible && estimated_amount > 0 && (
              <p className="h4 mb-4">
                <span>{formatAsDollars(estimated_amount)}</span>
              </p>
            )}
            <div className="font-weight-500 h5 mb-4">{incentive_name}</div>
            <div>
              <div className="font-weight-500">Rationale:</div>
              <Truncate lines={truncated && 4} ellipsis={<Ellipsis />}>
                {eligibility_rationale}
              </Truncate>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="row">
              <div className="col text-right">
                <div className="text-uppercase font-weight-500 mb-3">Resources</div>
                {incentive_url && (
                  <div className="mb-2">
                    <a href={incentive_url} target="_blank" rel="noopener noreferrer">
                      More Information
                    </a>
                  </div>
                )}
                {incentive_application_url && (
                  <div className="mb-2">
                    <a href={incentive_application_url} target="_blank" rel="noopener noreferrer">
                      Application
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluatedGrantCard;
