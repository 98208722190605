import {useInputs} from '@bellawatt/use-inputs';
import React from 'react';
import moment from 'moment';
import VehicleImage from '../../../graphs/VehicleImage';
import Arrow from '../../../icons/Arrow';
import groupByChargers from '../../../calcs/groupByChargers';

const ChargersToVehicles = () => {
  const {vehicleSets} = useInputs();

  const chargingSets = groupByChargers(vehicleSets);

  return (
    <div className="table-responsive">
      <table className="table table-borderless">
        <thead className="border-bottom">
          <tr>
            <th colSpan="2">Charger Type</th>
            <th colSpan="3">Vehicles</th>
            <th>Time Range</th>
          </tr>
        </thead>
        {chargingSets.map(({type, start, finish, minimumRequired, ports, vehicles}, i) => (
          <tbody key={i}>
            <tr>
              <td width="33%" className="align-middle" rowSpan="0">
                <strong>{type}</strong>
                <br />
                {ports === 1 ? 'Single Port' : 'Dual Port'}
                <br />
                <strong>Minimum Req: {minimumRequired}</strong>
              </td>
              <td className="align-middle" rowSpan="0">
                <Arrow direction="right" />
              </td>
            </tr>

            {vehicles.map((vehicle, j) => (
              <tr className="border-bottom" key={j}>
                <td className="align-middle">
                  <VehicleImage vehicle={vehicle.vehicle} />
                </td>
                <td className="align-middle text-muted">
                  <span className="text-nowrap">
                    x{vehicle.vehicleCount} {vehicle.type}
                  </span>{' '}
                  <span className="text-nowrap">{vehicle.subtype}</span>
                </td>
                <td className="align-middle">
                  <Arrow direction="right" />
                </td>
                <td className="align-middle text-nowrap">
                  {moment(start, 'H').format('ha')} - {moment(finish, 'H').format('ha')}
                </td>
              </tr>
            ))}
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default ChargersToVehicles;
