import React from 'react';

const Plus = () => (
<svg width="47px" height="48px" viewBox="0 0 47 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Plus</title>
    <g id="PGE-Fleets_Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Wizard-Intro" transform="translate(-232.000000, -408.000000)">
            <g id="Group-8" transform="translate(103.000000, 125.000000)">
                <g id="Group-7" transform="translate(125.000000, 204.000000)">
                    <g id="Group-2" transform="translate(4.000000, 10.500000)">
                        <g id="icn-add" transform="translate(0.000000, 69.000000)">
                            <g id="Group-Copy" fill="#00527C">
                                <circle id="Oval" cx="23.5" cy="23.5" r="23.5"></circle>
                            </g>
                            <g id="ic_cancel-2" transform="translate(7.621622, 7.621622)" stroke="#FFFFFF" strokeLinecap="round" strokeWidth="2">
                                <g id="ic_cancel" transform="translate(15.878378, 16.177784) rotate(-315.000000) translate(-15.878378, -16.177784) translate(5.081081, 5.380487)">
                                    <g id="cross">
                                        <g transform="translate(4.410661, 4.410661)">
                                            <line x1="0" y1="0" x2="12.4752322" y2="12.4752322" id="Line"></line>
                                            <line x1="12.3498498" y1="0" x2="1.56698145e-15" y2="12.3498498" id="Line"></line>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Plus;