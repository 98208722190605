import calcBatterySchedule from './calcBatterySchedule/calcBatterySchedule';

const calcWeeklyLoadProfiles = (chargers, vehicleSets) => {
  return vehicleSets.map((set) => {
    const batterySchedule = calcBatterySchedule(chargers, set).weeklyBatterySchedule;
    const powerDrawnInKwh = batterySchedule.map((schedule) => schedule.kwhPowerDrawn);

    return powerDrawnInKwh;
  });
};

export default calcWeeklyLoadProfiles;
