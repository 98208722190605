import A from '../../data/ASSUMPTIONS';

const calcLcfsDollarsPerKwh = (gridIntensityInGramsCO2ePerMJ, originalFuel, year) => {
  const complianceStandardInGramsCO2ePerMJ =
    A.LCFS_COMPLIANCE_STANDARD[originalFuel][year.toString()];

  const eerDictionary = {
    diesel: A.LCFS_EER_DIESEL,
    regular: A.LCFS_EER_GASOLINE,
    lpg: A.LCFS_EER_LPG,
  };

  const eer = eerDictionary[originalFuel];

  return year <= 2030
    ? ((complianceStandardInGramsCO2ePerMJ * eer - gridIntensityInGramsCO2ePerMJ) *
        A.LCFS_CREDIT_PRICE_IN_DOLLARS *
        A.ENERGY_DENSITY_ELECTRICITY_IN_MJ_PER_KWH) /
        1e6
    : 0;
};

export default calcLcfsDollarsPerKwh;
