import React, {useEffect} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';
import BEV from '../../data/BEV';
import {formatAsThousands} from '../../utils/format';

const SlideBevBlocks = ({maxKw}) => {
  const {bevBlocks, bevRateName, setInput} = useInputs();

  const currentSubscription = BEV.tariff_elements.pge_subscription_rate.find(
    (subscription) => subscription.name === bevRateName
  );
  const subscribedKw = bevBlocks * currentSubscription.blockSizeInKw;
  const recommendedBlocks = Math.ceil(maxKw / currentSubscription.blockSizeInKw);

  useEffect(() => {
    setInput({bevBlocks: recommendedBlocks});
  }, [bevRateName, recommendedBlocks]); //eslint-disable-line

  return (
    <>
      <Slider
        label="BEV # of Blocks"
        min={1}
        max={
          currentSubscription.maxApplicableKwh
            ? currentSubscription.maxApplicableKwh / currentSubscription.blockSizeInKw
            : 100
        }
        value={bevBlocks}
        onChange={(bevBlocks) => setInput({bevBlocks})}
      />
      <div style={{padding: '20px', backgroundColor: '#f5f5f5'}}>
        <p>
          <strong>What is a block?</strong>
        </p>
        <p>
          A block is a subscribeable unit of energy, and is unique to the PG&amp;E BEV rate. Blocks
          for {bevRateName} are measured in <strong>{currentSubscription.blockSizeInKw} kW</strong>{' '}
          increments.
        </p>
        <p>
          You&apos;ve selected <strong>{bevBlocks} blocks</strong>, which is the equivalent of
          subscribing for <strong>{subscribedKw} kW</strong>.
        </p>
        <p>
          Based on the selected vehicles and charging windows, your expected demand is{' '}
          <strong>{formatAsThousands(maxKw)} kW</strong>, which is the equivalent of{' '}
          <strong>{recommendedBlocks} blocks.</strong>
        </p>
      </div>
    </>
  );
};

export default SlideBevBlocks;
