const calcKwhPerDay = (vehicleSet) => {
  const {
    vehicle: {
      batteryCapacityInKwh,
      rangeInMiles,
      rangeInHours,
      category,
      idlingFactor = 0, // if this is constant, it should be added to vehicles via the computed property or on fetch or whatever
    },
    milesPerWorkday,
    hoursPerWorkday,
    vehicleCount,
  } = vehicleSet;

  const kwhPerMile = batteryCapacityInKwh / rangeInMiles;
  const kwhPerHour = batteryCapacityInKwh / (rangeInHours || 1);
  const idling = 1 - idlingFactor;
  const kwhPerDay =
    category === 'On-Road' ? milesPerWorkday * kwhPerMile : hoursPerWorkday * kwhPerHour;

  return vehicleCount * kwhPerDay * idling;
};

export default calcKwhPerDay;
