// source: https://docs.google.com/spreadsheets/d/1gh6FE8xkA4OZ8WvDUtpuW6WJnwHePU-X2kPa95WwWkE/edit#gid=1674745959

const VEHICLES = [
  {
    id: 'tru_tru_invisible',
    name: 'Generic',
    category: 'TRU',
    type: 'TRU',
    subtype: 'Cargo Van Invisible',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Cargo_Van_Invisible_TRU.png',
    rangeInHours: 2,
    formFactor: 'tru',
    liftCapacity: 0,
    gvwr: null,
    weightClass: 0,
    batteryCapacityInKwh: 5.5,
    msrp: 16500,
    maintenanceCostPerHour: 0.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 16500,
    fossilMaintenanceCostPerHour: 1.5,
    fossilGallonsPerHour: 0.55,
    eligibleForCaRebate: false,
  },
  {
    id: 'tru_tru_roof_mount',
    name: 'Generic',
    category: 'TRU',
    type: 'TRU',
    subtype: 'Cargo Van Roof Mount',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Cargo_Van_ Roof_Mount_TRU.png',
    rangeInHours: 1,
    formFactor: 'tru',
    liftCapacity: 0,
    gvwr: null,
    weightClass: 0,
    batteryCapacityInKwh: 5.5,
    msrp: 15500,
    maintenanceCostPerHour: 0.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 15300,
    fossilMaintenanceCostPerHour: 1.5,
    fossilGallonsPerHour: 0.55,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_bev_drive_system_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'BEV Drive System',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_BEV_Drive_System.png',
    rangeInMiles: 150,
    formFactor: 'bev_drive_system',
    liftCapacity: 0,
    gvwr: 40000,
    weightClass: 8,
    batteryCapacityInKwh: 193,
    msrp: 285000,
    maintenanceCostPerMile: 0.15,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 118000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.4,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_bev_drive_system_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'BEV Drive System',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_BEV_Drive_System.png',
    rangeInMiles: 200,
    formFactor: 'bev_drive_system',
    liftCapacity: 0,
    gvwr: 19250,
    weightClass: 5,
    batteryCapacityInKwh: 138,
    msrp: 159000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 89000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_box_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Box Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Box_Truck.png',
    rangeInMiles: 172,
    formFactor: 'box_truck',
    liftCapacity: 0,
    gvwr: 44000,
    weightClass: 8,
    batteryCapacityInKwh: 272,
    msrp: 282000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 118000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.4,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_box_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Box Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Box_Truck.png',
    rangeInMiles: 163,
    formFactor: 'box_truck',
    liftCapacity: 0,
    gvwr: 22100,
    weightClass: 5,
    batteryCapacityInKwh: 153,
    msrp: 185000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 89000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cargo_van_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cargo Van',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cargo_Van.png',
    rangeInMiles: 160,
    formFactor: 'cargo_van',
    liftCapacity: 0,
    gvwr: 23500,
    weightClass: 6,
    batteryCapacityInKwh: 128,
    msrp: 220000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 147000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 6.7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cargo_van_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cargo Van',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cargo_Van.png',
    rangeInMiles: 177,
    formFactor: 'cargo_van',
    liftCapacity: 0,
    gvwr: 13293,
    weightClass: 3,
    batteryCapacityInKwh: 101,
    msrp: 48800,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 48000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cutaway_chassis_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cutaway_Chassis.png',
    rangeInMiles: 150,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 6500,
    weightClass: 2,
    batteryCapacityInKwh: 118,
    msrp: 110000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 83000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cutaway_chassis_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cutaway_Chassis.png',
    rangeInMiles: 162,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 15530,
    weightClass: 4,
    batteryCapacityInKwh: 125,
    msrp: 140000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 104000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cutaway_chassis_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cutaway Chassis',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Cutaway_Chassis.png',
    rangeInMiles: 170,
    formFactor: 'cutaway_chassis',
    liftCapacity: 0,
    gvwr: 33000,
    weightClass: 7,
    batteryCapacityInKwh: 138,
    msrp: 250000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 186000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_motor_coach_double_decker',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Double Decker',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Motor_Coach.png',
    rangeInMiles: 200,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 51375,
    weightClass: 8,
    batteryCapacityInKwh: 380,
    msrp: 1050000,
    maintenanceCostPerMile: 0.7,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 706000,
    fossilMaintenanceCostPerMile: 1.05,
    fossilMilesPerGallon: 5,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_motor_coach_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Motor_Coach.png',
    rangeInMiles: 124,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 17300,
    weightClass: 5,
    batteryCapacityInKwh: 121,
    msrp: 210000,
    maintenanceCostPerMile: 0.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 156000,
    fossilMaintenanceCostPerMile: 0.9,
    fossilMilesPerGallon: 7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_motor_coach_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Motor Coach',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Motor_Coach.png',
    rangeInMiles: 224,
    formFactor: 'motor_coach',
    liftCapacity: 0,
    gvwr: 51300,
    weightClass: 8,
    batteryCapacityInKwh: 466,
    msrp: 790000,
    maintenanceCostPerMile: 0.6,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 588000,
    fossilMaintenanceCostPerMile: 1,
    fossilMilesPerGallon: 6,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_pick_up_truck_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Pickup_Truck.png',
    rangeInMiles: 315,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 9000,
    weightClass: 2,
    batteryCapacityInKwh: 158,
    msrp: 70600,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 53000,
    fossilMaintenanceCostPerMile: 0.17,
    fossilMilesPerGallon: 19,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_pick_up_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Pickup_Truck.png',
    rangeInMiles: 271,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 19500,
    weightClass: 5,
    batteryCapacityInKwh: 157,
    msrp: 116000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 63000,
    fossilMaintenanceCostPerMile: 0.17,
    fossilMilesPerGallon: 9,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_pick_up_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Pick-up Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Pickup_Truck.png',
    rangeInMiles: 335,
    formFactor: 'pickup_truck',
    liftCapacity: 0,
    gvwr: 30000,
    weightClass: 8,
    batteryCapacityInKwh: 125,
    msrp: 140000,
    maintenanceCostPerMile: 0.14,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 100000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_refuse_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Refuse Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Refuse_Truck.png',
    rangeInMiles: 101,
    formFactor: 'refuse_truck',
    liftCapacity: 0,
    gvwr: 66000,
    weightClass: 8,
    batteryCapacityInKwh: 302,
    msrp: 500000,
    maintenanceCostPerMile: 2.05,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 353000,
    fossilMaintenanceCostPerMile: 3.07,
    fossilMilesPerGallon: 1.7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_refuse_truck_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Refuse Truck',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Refuse_Truck.png',
    rangeInMiles: 125,
    formFactor: 'refuse_truck',
    liftCapacity: 0,
    gvwr: 26000,
    weightClass: 6,
    batteryCapacityInKwh: 221,
    msrp: 198000,
    maintenanceCostPerMile: 1.5,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 142000,
    fossilMaintenanceCostPerMile: 2.25,
    fossilMilesPerGallon: 4.3,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_school_bus_type_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type A',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_A_School_Bus.png',
    rangeInMiles: 125,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 16463,
    weightClass: 5,
    batteryCapacityInKwh: 130,
    msrp: 238000,
    maintenanceCostPerMile: 0.2,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 177000,
    fossilMaintenanceCostPerMile: 0.24,
    fossilMilesPerGallon: 6,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_school_bus_type_c',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type C',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_C_School_Bus.png',
    rangeInMiles: 130,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 31200,
    weightClass: 7,
    batteryCapacityInKwh: 190,
    msrp: 350000,
    maintenanceCostPerMile: 0.21,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 236000,
    fossilMaintenanceCostPerMile: 0.25,
    fossilMilesPerGallon: 5,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_school_bus_type_d',
    name: 'Generic',
    category: 'On-Road',
    type: 'School Bus',
    subtype: 'Type D',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Type_D_School_Bus.png',
    rangeInMiles: 130,
    formFactor: 'school_bus',
    liftCapacity: 0,
    gvwr: 34760,
    weightClass: 8,
    batteryCapacityInKwh: 175,
    msrp: 400000,
    maintenanceCostPerMile: 0.22,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 294000,
    fossilMaintenanceCostPerMile: 0.26,
    fossilMilesPerGallon: 4,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_sedan_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Sedan',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Sedan.png',
    rangeInMiles: 335,
    formFactor: 'sedan',
    liftCapacity: 0,
    gvwr: 4200,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 35000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 29400,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_shuttle_bus_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Shuttle Bus',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Shuttle_Bus.png',
    rangeInMiles: 112,
    formFactor: 'shuttle_bus',
    liftCapacity: 0,
    gvwr: 16000,
    weightClass: 4,
    batteryCapacityInKwh: 115,
    msrp: 216000,
    maintenanceCostPerMile: 0.12,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 160000,
    fossilMaintenanceCostPerMile: 0.35,
    fossilMilesPerGallon: 7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_shuttle_bus_passenger_van',
    name: 'Generic',
    category: 'On-Road',
    type: 'Shuttle Bus',
    subtype: 'Passenger Van',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Passenger_Van_Shuttle_Bus.png',
    rangeInMiles: 200,
    formFactor: 'shuttle_bus',
    liftCapacity: 0,
    gvwr: 12461,
    weightClass: 3,
    batteryCapacityInKwh: 104,
    msrp: 154000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 115000,
    fossilMaintenanceCostPerMile: 0.32,
    fossilMilesPerGallon: 16,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_step_van_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Step Van',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Step_Van.png',
    rangeInMiles: 163,
    formFactor: 'step_van',
    liftCapacity: 0,
    gvwr: 9370,
    weightClass: 2,
    batteryCapacityInKwh: 100,
    msrp: 55000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 41000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_step_van_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Step Van',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Step_Van.png',
    rangeInMiles: 200,
    formFactor: 'step_van',
    liftCapacity: 0,
    gvwr: 15000,
    weightClass: 3,
    batteryCapacityInKwh: 127,
    msrp: 162000,
    maintenanceCostPerMile: 0.13,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 120000,
    fossilMaintenanceCostPerMile: 0.2,
    fossilMilesPerGallon: 6.3,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_suv_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'SUV',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/SUV.png',
    rangeInMiles: 318,
    formFactor: 'suv',
    liftCapacity: 0,
    gvwr: 5800,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 45000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 42000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 22.7,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_hatchback_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Hatchback',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Hatchback.png',
    rangeInMiles: 275,
    formFactor: 'hatchback',
    liftCapacity: 0,
    gvwr: 4200,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 35000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 29400,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 22.7,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_crossover_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Crossover',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Crossover.png',
    rangeInMiles: 314,
    formFactor: 'crossover',
    liftCapacity: 0,
    gvwr: 5793,
    weightClass: 1,
    batteryCapacityInKwh: 85,
    msrp: 52068,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 42000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 22.7,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_trailer_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Trailer Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Trailer_Truck.png',
    rangeInMiles: 250,
    formFactor: 'trailer_truck',
    liftCapacity: 0,
    gvwr: 63842,
    weightClass: 8,
    batteryCapacityInKwh: 445,
    msrp: 380000,
    maintenanceCostPerMile: 0.15,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 177000,
    fossilMaintenanceCostPerMile: 0.18,
    fossilMilesPerGallon: 5,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_transit_bus_double_decker',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Double Decker',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Double_Decker_Transit_Bus.png',
    rangeInMiles: 250,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 33000,
    weightClass: 8,
    batteryCapacityInKwh: 600,
    msrp: 1050000,
    maintenanceCostPerMile: 0.7,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 823000,
    fossilMaintenanceCostPerMile: 1.05,
    fossilMilesPerGallon: 6,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_transit_bus_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Mass_Transit_Bus.png',
    rangeInMiles: 186,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 36578,
    weightClass: 8,
    batteryCapacityInKwh: 376,
    msrp: 720000,
    maintenanceCostPerMile: 0.6,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 588000,
    fossilMaintenanceCostPerMile: 1,
    fossilMilesPerGallon: 6,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_transit_bus_medium_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Medium Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Transit_Bus.png',
    rangeInMiles: 150,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 25500,
    weightClass: 6,
    batteryCapacityInKwh: 160,
    msrp: 210000,
    maintenanceCostPerMile: 0.55,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 165000,
    fossilMaintenanceCostPerMile: 0.9,
    fossilMilesPerGallon: 6,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_transit_bus_articulated_bus',
    name: 'Generic',
    category: 'On-Road',
    type: 'Transit Bus',
    subtype: 'Articulated Bus',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Articulated_Bus.png',
    rangeInMiles: 153,
    formFactor: 'transit_bus',
    liftCapacity: 0,
    gvwr: 60000,
    weightClass: 8,
    batteryCapacityInKwh: 508,
    msrp: 1170000,
    maintenanceCostPerMile: 0.75,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 882000,
    fossilMaintenanceCostPerMile: 1.2,
    fossilMilesPerGallon: 5,
    eligibleForCaRebate: false,
  },
  {
    id: 'off_road_forklift_high_capacity',
    name: 'Generic',
    category: 'Off-Road',
    type: 'Forklift',
    subtype: 'High Capacity: 10,001 - 16,000 lbs',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Heavy_Duty_Forklift.png',
    rangeInHours: 18,
    formFactor: 'forklift',
    liftCapacity: 13000,
    gvwr: null,
    weightClass: 6,
    batteryCapacityInKwh: 88,
    msrp: 77000,
    maintenanceCostPerHour: 0.12,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 83000,
    fossilMaintenanceCostPerHour: 0.19,
    fossilGallonsPerHour: 0.58,
    eligibleForCaRebate: false,
  },
  {
    id: 'off_road_forklift_medium_capacity',
    name: 'Generic',
    category: 'Off-Road',
    type: 'Forklift',
    subtype: 'Medium Capacity: 6,001 - 10,000 lbs',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Forklift.png',
    rangeInHours: 18,
    formFactor: 'forklift',
    liftCapacity: 8000,
    gvwr: null,
    weightClass: 3,
    batteryCapacityInKwh: 32.4,
    msrp: 56800,
    maintenanceCostPerHour: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 42000,
    fossilMaintenanceCostPerHour: 0.16,
    fossilGallonsPerHour: 0.8,
    eligibleForCaRebate: false,
  },
  {
    id: 'off_road_forklift_low_capacity',
    name: 'Generic',
    category: 'Off-Road',
    type: 'Forklift',
    subtype: 'Low Capacity: 4,000 - 6,000 lbs',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Forklift.png',
    rangeInHours: 18,
    formFactor: 'forklift',
    liftCapacity: 4750,
    gvwr: null,
    weightClass: 2,
    batteryCapacityInKwh: 25,
    msrp: 37000,
    maintenanceCostPerHour: 0.08,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 29400,
    fossilMaintenanceCostPerHour: 0.14,
    fossilGallonsPerHour: 0.7,
    eligibleForCaRebate: false,
  },
  {
    id: 'off_road_terminal_tractor_heavy_duty',
    name: 'Generic',
    category: 'Off-Road',
    type: 'Terminal Tractor',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Terminal_Tractor.png',
    rangeInHours: 30,
    formFactor: 'terminal_tractor',
    liftCapacity: 0,
    gvwr: null,
    weightClass: 8,
    batteryCapacityInKwh: 177,
    msrp: 270000,
    maintenanceCostPerHour: 1.15,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 153000,
    fossilMaintenanceCostPerHour: 3.84,
    fossilGallonsPerHour: 1.63,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_coupe_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Coupe',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Coupe.png',
    rangeInMiles: 325,
    formFactor: 'coupe',
    liftCapacity: 0,
    gvwr: 5100,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 45000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 40000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_station_wagon_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Station Wagon',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Station_Wagon.png',
    rangeInMiles: 335,
    formFactor: 'station wagon',
    liftCapacity: 0,
    gvwr: 4200,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 45000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 40000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_minivan_n_a',
    name: 'Generic',
    category: 'On-Road',
    type: 'Passenger',
    subtype: 'Minivan',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Minivan.png',
    rangeInMiles: 325,
    formFactor: 'minivan',
    liftCapacity: 0,
    gvwr: 5800,
    weightClass: 1,
    batteryCapacityInKwh: 88,
    msrp: 35000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'regular',
    fossilFuelMsrp: 29400,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 24,
    eligibleForCaRebate: true,
  },
  {
    id: 'on_road_specialty_mobile_outreach_vehicle',
    name: 'Generic',
    category: 'On-Road',
    type: 'Specialty',
    subtype: 'Bucket Truck',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Mobile_Outreach_Vehicle.png',
    rangeInMiles: 105,
    formFactor: 'specialty',
    liftCapacity: 0,
    gvwr: 26000,
    weightClass: 6,
    batteryCapacityInKwh: 127,
    msrp: 198000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 147000,
    fossilMaintenanceCostPerMile: 0.24,
    fossilMilesPerGallon: 7,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_utility_truck_heavy_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Utility Truck',
    subtype: 'Heavy Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Bucket_Truck.png',
    rangeInMiles: 130,
    formFactor: 'utility_truck',
    liftCapacity: 0,
    gvwr: 54600,
    weightClass: 8,
    batteryCapacityInKwh: 336,
    msrp: 457000,
    maintenanceCostPerMile: 0.16,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 339000,
    fossilMaintenanceCostPerMile: 0.22,
    fossilMilesPerGallon: 4.4,
    eligibleForCaRebate: false,
  },
  {
    id: 'on_road_cargo_van_light_duty',
    name: 'Generic',
    category: 'On-Road',
    type: 'Cargo Van',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cargo_Van.png',
    rangeInMiles: 115,
    formFactor: 'cargo_van',
    liftCapacity: 0,
    gvwr: 5233,
    weightClass: 1,
    batteryCapacityInKwh: 42,
    msrp: 79000,
    maintenanceCostPerMile: 0.1,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 71000,
    fossilMaintenanceCostPerMile: 0.15,
    fossilMilesPerGallon: 7.2,
    eligibleForCaRebate: false,
  },
  {
    id: 'off_road_tractor_light_duty_medium_usage',
    name: 'Generic',
    category: 'Off-Road',
    type: 'tractor',
    subtype: 'Light Duty',
    imageUrl:
      'https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Tractor.png',
    rangeInHours: 5,
    formFactor: 'tractor',
    liftCapacity: 0,
    gvwr: null,
    weightClass: 2,
    batteryCapacityInKwh: 22,
    msrp: 33000,
    maintenanceCostPerHour: 0.12,
    fossilFuelType: 'diesel',
    fossilFuelMsrp: 28300,
    fossilMaintenanceCostPerHour: 0.5,
    fossilGallonsPerHour: 0.912,
    eligibleForCaRebate: false,
  },
];

export const findArchetype = (vehicle) =>
  VEHICLES.find(
    ({category, type, subtype}) =>
      category === vehicle.category && type === vehicle.type && subtype === vehicle.subtype
  );

export default VEHICLES;
