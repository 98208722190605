import calcBatterySchedule from './calcBatterySchedule/calcBatterySchedule';
import sum from 'lodash/sum';

const calcTotalKwh = (chargers, vehicleSets) => {
  const weeklyBatterySchedule = vehicleSets.map((set) =>
    calcBatterySchedule(chargers, set).weeklyBatterySchedule.map(
      (schedule) => schedule.kwhPowerDrawn
    )
  );
  const weeklyKwh = sum(weeklyBatterySchedule.map((schedule) => sum(schedule)));

  return (weeklyKwh * 365.25) / 7;
};

export default calcTotalKwh;
