const multifamilyLoadProfile = [
  63.8,
  57.8,
  49.0,
  42.3,
  34.6,
  24.0,
  19.6,
  19.1,
  25.9,
  38.3,
  40.7,
  35.8,
  32.5,
  36.6,
  26.2,
  21.1,
  13.5,
  12.5,
  14.6,
  15.9,
  18.5,
  48.5,
  64.6,
  67.6,
];

export default multifamilyLoadProfile;
