import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import InputCard from '../../components/InputCard';

// const options = ['Vehicles and their Chargers', 'Chargers with Unspecified Vehicles'];
const options = ['Vehicles', 'Chargers'];

const SelectVehiclesOrChargers = ({value, onChange}) => (
  <InputCard header="Select An Estimation Approach" closeable startOpen>
    <Select
      label="Base Calculations Upon"
      value={value}
      onChange={onChange}
      optionValues={options}
      // We could filter through all vehicles for categories, but we want this exact order so we'd be hardcoding them anway
    />
    <p className="mt-2 mb-0 small">
      {value === 'Vehicles'
        ? 'Vehicles are known and chargers are assigned on a 1:1 to each vehicle.'
        : 'Vehicles are unknown and we assign utilization of each charger purchased.'}
    </p>
  </InputCard>
);

SelectVehiclesOrChargers.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SelectVehiclesOrChargers.options = options;

export default SelectVehiclesOrChargers;
