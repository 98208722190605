import React from 'react';
import InputCard from './InputCard';

export default function RateElementDescriptions() {
  return (
    <div className="mt-5">
      <InputCard header="Rate Element Descriptions" closeable>
        <p className="mt-4">
          Your <b>monthly subscription/fixed</b> charge is based on your subscription level and
          maximum monthly EV charging kilowatt (kW) consumption. This can be adjusted throughout the
          month as needed to avoid overage fees.
        </p>
        <p>
          <b>Overage</b> fees apply if your actual demand consumption (kW) exceeds your subscription
          level. You will be charged an overage fee of two times the cost of one kW for each kW you
          are over. It is more economical to pay the overage fee than increase the subscription
          level if the amount of additional demand is less than half of the subscription block.
        </p>
        <p>
          In addition to your monthly subscription charge, your rate is based on how much{' '}
          <b>energy</b> in kWh you use and when you use it. Charging is most affordable midday when
          PG&E has higher levels of renewable energy generation.
        </p>
        <p>
          The <b>demand</b> charge is a measurement of the highest usage of electricity in any
          single 15-minute period during a monthly billing cycle. Demand is measured in kilowatts
          (kW).
        </p>
      </InputCard>
    </div>
  );
}
