import React from 'react';
import {useToggle} from '@bellawatt/react-hooks';

const Bubble = ({children}) => (
  <div
    style={{
      position: 'absolute',
      padding: '10px',

      alignItems: 'center',
      textTransform: 'none',
      fontSize: '14px',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, .8)',
      color: '#FFF',
      maxWidth: '400px',
      minWidth: '200px',
      zIndex: 99,
      whiteSpace: 'normal',
    }}
  >
    {children}
  </div>
);

const Tooltip = ({children}) => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <span onMouseEnter={toggle} onMouseLeave={toggle}>
        <svg
          className="bi bi-info-circle"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          transform="translate(0,-1)"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>
      </span>
      {isOpen && <Bubble>{children}</Bubble>}
    </>
  );
};

export default Tooltip;
