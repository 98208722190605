import calcGallonsOfFossilFuel from './calcGallonsOfFossilFuel';
import calcTotalKwh from './calcTotalKwh';
import A from '../data/ASSUMPTIONS';

const calcCO2SavedInTons = (chargers, vehicleSets) => {
  const totalGallonsOfDiesel = calcGallonsOfFossilFuel(vehicleSets, 'diesel');
  const totalGallonsOfGasoline = calcGallonsOfFossilFuel(vehicleSets, 'regular');
  const totalGallonsOfLpg = calcGallonsOfFossilFuel(vehicleSets, 'lpg');

  const totalKwh = calcTotalKwh(chargers, vehicleSets);
  const totalFossilEmissions =
    totalGallonsOfDiesel * A.DIESEL_EMISSIONS_IN_LBS_PER_GAL +
    totalGallonsOfGasoline * A.GASOLINE_EMISSIONS_IN_LBS_PER_GAL +
    totalGallonsOfLpg * A.LPG_EMISSIONS_IN_LBS_PER_GAL;

  return Math.round(
    (A.CO2_TO_CO2E_FACTOR *
      (totalFossilEmissions - (totalKwh * A.ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH) / 1000)) /
      2000 // 1 ton === 2000 lbs
  );
};

export default calcCO2SavedInTons;
