import A from '../data/ASSUMPTIONS';
import calcBatterySchedule from './calcBatterySchedule/calcBatterySchedule';
import calcLcfsDollarsPerKwh from './lcfs/calcLcfsDollarsPerKwh';
import {sum} from 'lodash';

const calcLcfsCreditsByYear = (chargers, vehicleSets, gridIntensityInGramsCO2ePerMJ) => {
  return A.LCFS_YEARS.map((year) => {
    const lcfsRevenuesBySet = vehicleSets.map((set) => {
      const {vehicle} = set;
      const batterySchedule = calcBatterySchedule(chargers, set).weeklyBatterySchedule;
      const powerDrawnInKwh = batterySchedule.map((schedule) => schedule.kwhPowerDrawn);
      const totalKwh = (sum(powerDrawnInKwh) * 365.25) / 7;

      return (
        totalKwh *
        calcLcfsDollarsPerKwh(gridIntensityInGramsCO2ePerMJ, vehicle.fossilFuelType, year)
      );
    });

    return sum(lcfsRevenuesBySet);
  });
};

export default calcLcfsCreditsByYear;
