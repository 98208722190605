import React from 'react';
import uniq from 'lodash/uniq';
import Select from '../Select';

const SelectVehicleSubType = ({value, onChange, vehicles}) => (
  <Select
    label="Subtype"
    value={value}
    multiLine
    optionValues={uniq(vehicles.map((vehicle) => vehicle.subtype))}
    onChange={onChange}
  />
);

export default SelectVehicleSubType;
