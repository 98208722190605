import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import {Link as BaseLink} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Sublink, Dropdown} from './Links';
import StickyFooter from './StickyFooter';
import {colors} from '../styles';

export const PageHeader = ({page, transparent = false}) => {
  return (
    <Row
      className="no-gutters"
      style={{
        maxWidth: '1800px',
        height: '93px',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        ...(transparent && {alignSelf: 'flex-end'}),
      }}
    >
      <Col xs="3">
        {!transparent && (
          <BaseLink to="/">
            <img
              className="m-2"
              style={{height: '69px', width: 'auto'}}
              src="/images/logo.png"
              alt="PG&amp;E Logo"
            />
          </BaseLink>
        )}
      </Col>
      <Col xs="9" className="links text-right">
        <Dropdown
          name="Information"
          active={['Electrification Planning', 'Vehicles', 'Grants'].indexOf(page) > -1}
          color={transparent && colors.white}
        >
          <Dropdown.Link href="/plan" active={page === 'Electrification Planning'}>
            Planning
          </Dropdown.Link>
          <Dropdown.Link href="/grants" active={page === 'Grants'}>
            Grants
          </Dropdown.Link>
          <Dropdown.Link href="/vehicle-catalog" active={page === 'Vehicles'}>
            Vehicles
          </Dropdown.Link>
        </Dropdown>
        <Dropdown
          name="Calculators"
          active={
            [
              'Fuel Savings Calculator',
              'Total Cost of Ownership',
              'Business Calculator',
              'LCFS Calculator',
            ].indexOf(page) > -1
          }
          color={transparent && colors.white}
        >
          <Dropdown.Link href="/fuel-savings" active={page === 'Fuel Savings Calculator'}>
            Fuel Savings
          </Dropdown.Link>
          <Dropdown.Link href="/tco" active={page === 'Total Cost of Ownership'}>
            Total Costs
          </Dropdown.Link>
          <Dropdown.Link href="/bev-rate" active={page === 'Business Calculator'}>
            BEV Rate
          </Dropdown.Link>
          <Dropdown.Link href="/rate-comparison" active={page === 'Rate Comparison'}>
            Rate Comparison
          </Dropdown.Link>
          <Dropdown.Link href="/lcfs" active={page === 'LCFS Calculator'}>
            LCFS
          </Dropdown.Link>
        </Dropdown>
      </Col>
    </Row>
  );
};

PageHeader.propTypes = {
  page: PropTypes.string,
  transparent: PropTypes.bool,
};

export const PageFooter = () => (
  <Row className="footer">
    <Col
      style={{
        backgroundColor: colors.blue.darker,
      }}
    >
      <Row>
        <Col md={{span: 8, offset: 2}}>
          <Row className="pt-4" style={{borderTop: '1px solid #979797'}}>
            <Col md="auto">
              <Sublink href="/">Home</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/plan">Electrification Planning</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/fuel-savings">Fuel Savings Calculator</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/tco">Total Cost of Ownership Calculator</Sublink>
            </Col>
          </Row>
          <Row style={{padding: '10px 0'}}>
            <Col md="auto">
              <Sublink href="/lcfs">LCFS Calculator</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/bev-rate">BEV Rate Calculator</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/grants">Grants</Sublink>
            </Col>
            <Col md="auto">
              <Sublink href="/vehicle-catalog">Vehicles</Sublink>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <p className="small" style={{color: '#848b93'}}>EV solutions provided by ZappyRide, a J.D. Power® business.</p>
              <p className="small" style={{color: '#848b93'}}>Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice. Neither J.D. Power® nor BrightDrop provides tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction.</p>
              <p className="small" style={{color: '#848b93'}}>
                Fleet Savings Calculator provides illustrative estimations throughout and does not
                guarantee the accuracy of any costs, savings, hardware specifications, or incentives
                estimates. Please refer to referenced sources and original equipment manufacturers
                for up to date costs, hardware specifications, and incentives information.
              </p>
              <p className="small" style={{color: '#848b93'}}>
                “PG&amp;E” refers to Pacific Gas and Electric Company, a subsidiary of PG&amp;E
                Corporation. ©2020 Pacific Gas and Electric Company. All rights reserved. These
                offerings are funded by California utility customers and administered by PG&amp;E
                under the auspices of the California Public Utilities Commission.
              </p>
              <p className="small" style={{color: '#848b93'}}>
                This site protects your information in accordance with PG&amp;E's{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: '#848b93', textDecoration: 'underline'}}
                  href="https://www.pge.com/en_US/about-pge/company-information/privacy-policy/privacy.page"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <p className="small text-center" style={{color: '#848b93'}}>
                &copy; {new Date().getFullYear()} Pacific Gas and Electric Company
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
);

const PageLayout = ({children, page}) => (
  <Container fluid style={{overflow: 'hidden'}}>
    <PageHeader page={page} />
    <Container fluid style={{padding: '40px 0 0 0', maxWidth: '1400px'}}>
      {children}
    </Container>
    <StickyFooter />
    <PageFooter />
  </Container>
);

PageLayout.propTypes = {
  children: PropTypes.node,
  page: PropTypes.string,
};

export default PageLayout;
