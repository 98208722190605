import React from 'react';
import DepreciationAndResale from '../../../graphs/DepreciationAndResale';
import VehiclesWithTheirChargersTable from '../../../graphs/VehiclesWithTheirChargersTable';
import ChargersToVehicles from '../../FuelSavingsCalculator/components/ChargersToVehicles';

const Vehicles = () => {
  return (
    <>
      <div className="h2 text-center d-none d-print-block">Vehicles</div>
      <p className="takeaway">
        The selected vehicles and their corresponding details is shown below.
      </p>
      <VehiclesWithTheirChargersTable includeMSRP />

      <div className="my-5" />
      <div className="title-for-graphs-and-tables text-center">Resale</div>
      <p className="takeaway">
        The value of vehicles depreciate over time. This tool assumes a straight-line depreciation,
        and does not account for any tax treatment of depreciation.
      </p>
      <DepreciationAndResale />

      <div className="my-5" />
      <div className="title-for-graphs-and-tables text-center">Vehicle Charging Times</div>
      <p className="takeaway">
        Chargers are most profitable if they can be shared by vehicles. The minimum charging
        equipment for this scenario is shown below.
      </p>
      <ChargersToVehicles />
    </>
  );
};

export default Vehicles;
