import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';
import calcDieselGallonsFromKwh from '../../calcs/calcDieselGallonsFromKwh';
import {formatAsThousands} from '../../utils/format';

const SlideKwhDisplacedForLcfs = () => {
  const {kwhDisplacedForLcfs, setInput} = useInputs();

  return (
    <Slider
      label="Energy Displaced Per Year"
      min={10000}
      max={10000000}
      step={10000}
      value={kwhDisplacedForLcfs}
      valFormat={formatAsThousands}
      subText={
        <span>
          <strong>{formatAsThousands(kwhDisplacedForLcfs)}</strong> kWh; equivalent of{' '}
          <strong>{formatAsThousands(calcDieselGallonsFromKwh(kwhDisplacedForLcfs))}</strong>{' '}
          gallons of diesel
        </span>
      }
      onChange={(kwhDisplacedForLcfs) => setInput({kwhDisplacedForLcfs})}
    />
  );
};

export default SlideKwhDisplacedForLcfs;
