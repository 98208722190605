import calcMilesPerYear from './calcMilesPerYear';
import calcHoursPerYear from './calcHoursPerYear';
import times from 'lodash/times';
import sum from 'lodash/sum';
import unzip from 'lodash/unzip';

// =if(YEAR=1,   MILES_PER_YEAR*COST_PER_MILE,   MILES_PER_YEAR*COST_PER_MILE*(CONST_A*YEAR^4 - CONST_B*YEAR^3 + 0.126*YEAR^2 - 0.1762*YEAR + 1.0563))
// =if(YEAR=1,   MILES_PER_YEAR*COST_PER_MILE,   MILES_PER_YEAR*COST_PER_MILE*(0.0002*YEAR^4 - 0.0065*YEAR^3 + 0.0547*YEAR^2 + 0.1258*YEAR + 0.7821))
const ELECTRIC_VALUES = [0.0004, -0.0128, 0.126, -0.1762, 1.0536];
const FOSSIL_VALUES = [0.0002, -0.0065, 0.0547, 0.1258, 0.7821];

export const calcMaintenanceCostByYear = ({vehicleSet, yearsOfOperation, fossil = false}) => {
  const vehicle = fossil ? vehicleSet.archetype : vehicleSet.vehicle;
  const isOnRoad = vehicle.category === 'On-Road';
  const maintenanceCostPerUnit =
    vehicle[
      `${fossil ? 'fossilMaintenance' : 'maintenance'}Cost${isOnRoad ? 'PerMile' : 'PerHour'}`
    ];
  const CALC_VALUES = fossil ? FOSSIL_VALUES : ELECTRIC_VALUES;
  const usePerYear = isOnRoad ? calcMilesPerYear(vehicleSet) : calcHoursPerYear(vehicleSet);

  return times(yearsOfOperation, (i) => {
    const year = i + 1;
    const baseCalculation = usePerYear * maintenanceCostPerUnit;

    if (year === 1) {
      return baseCalculation;
    }

    const yearAdjustments = times(
      CALC_VALUES.length,
      (j) => CALC_VALUES[j] * Math.pow(year, CALC_VALUES.length - 1 - j)
    );

    return baseCalculation * sum(yearAdjustments);
  });
};

export const calcFossilMaintenanceCostByYear = (args) =>
  calcMaintenanceCostByYear({...args, fossil: true});

export const calcTotalMaintenanceCost = (args) =>
  unzip(calcMaintenanceCostByYear({...args})).map((arr) => sum(arr));

export default calcMaintenanceCostByYear;
