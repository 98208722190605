import React from 'react';
import Slider from '../Slider';

const SlideHoursPerWorkday = ({value, onChange}) => (
  <Slider
    label="Avg. Hours (Per Vehicle Per Day)"
    min={1}
    max={24}
    step={1}
    value={value}
    onChange={onChange}
  />
);

export default SlideHoursPerWorkday;
