import React, {useState} from 'react';
import TextInput from './TextInput';
import { ELIGIBLE_ZIP_CODES } from '../data/ZIP_CODES';

const ZipCode = ({defaultValue, onChange, ...props}) => {
  const [temp, _setTemp] = useState(defaultValue);
  const [invalidMessage, setInvalidMessage] = useState(null);

  const setTemp = (value) => {
    if (value.length > 5) {
      return;
    }

    _setTemp(value);

    const isValid = value.length === 5 && ELIGIBLE_ZIP_CODES.includes(parseInt(value));

    if (isValid) {
      setInvalidMessage(null);
      onChange(value);
    } else {
      setInvalidMessage(
        value.length !== 5 ?
          'Zip codes must be 5 digits long.' :
          'Your zip code is outside of our eligibility range.'
      )
    }
  };

  return (
    <>
      <TextInput 
        {...props} 
        value={temp} 
        onChange={setTemp} 
        invalid={!! invalidMessage}
        hiddenLabel="Zip Code"
      />
      {invalidMessage && (
        <div className="invalid-feedback">{invalidMessage}</div>
      )}
    </>
  );
};

export default ZipCode;
