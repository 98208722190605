const workplaceLoadProfile = [
  35.5,
  29.8,
  26.3,
  22.5,
  23.0,
  28.0,
  49.7,
  93.9,
  178.3,
  207.1,
  225.5,
  210.9,
  197.9,
  191.1,
  188.1,
  174.3,
  157.6,
  134.9,
  111.8,
  91.3,
  84.3,
  71.0,
  51.1,
  41.7,
];

export default workplaceLoadProfile;
