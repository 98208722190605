import React, {useState} from 'react';
import times from 'lodash/times';
import feedbackService from '../services/feedbackService';

const RecommendationDropdown = () => {
  const [value, setValue] = useState('');
  const submitRecommendation = async (recommendation) => {
    setValue(recommendation);
    await feedbackService({npsScore: recommendation});
  };

  return (
    <select
      value={value}
      onChange={(e) => submitRecommendation(e.currentTarget.value)}
      style={{width: '45px', height: '20px', padding: 0}}
      className="form-control"
    >
      <option value="" disabled>
        -
      </option>
      {times(11, (i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    </select>
  );
};

export default RecommendationDropdown;
