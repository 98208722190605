import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useInputs} from '@bellawatt/use-inputs';
import {DEFAULT_CHARGER_SET} from '../defaults';
import {colors, typography} from '../styles';
import {Pencil} from '../icons';
import {formatChargerSetTitle} from '../utils/format';
import NewChargerSetModal from '../components/NewChargerSetModal';
import ChargerImage from '../graphs/ChargerImage';
import {isEqual} from 'lodash';
import moment from 'moment';

const MAX_CHARGER_SETS = 4;

const ChargerSet = ({idx, set, onClick}) => (
  <div
    onClick={onClick}
    role="button"
    onKeyUp={(e) => e.key === 'Enter' && onClick()}
    tabIndex={idx}
    style={{
      width: '100%',
      cursor: 'pointer',
      border: `1px solid ${colors.grey.lighter}`,
      borderRadius: '4px',
      padding: '20px',
      margin: '20px 0',
    }}
    className="hover-vehicle-set-card"
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div
          style={{
            fontSize: typography.headers[5],
            fontWeight: typography.weight.bold,
          }}
        >
          {formatChargerSetTitle(set)}
        </div>
      </div>
      <div className="pencil">
        <Pencil />
      </div>
    </div>

    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <div style={{width: '35%'}}>
        <ChargerImage charger={set.charger} />
      </div>
      <div style={{width: '75%', margin: '15px', fontSize: typography.label}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{color: '#00000080'}}>Days Operating</div>
          <div style={{fontWeight: typography.weight.bold}}>
            {isEqual(set.workdays, [1, 2, 3, 4, 5])
              ? 'Weekdays'
              : set.workdays
                  .sort()
                  .map((day) => moment(day, 'd').format('dd'))
                  .join(', ')}
          </div>
        </div>
      </div>
    </div>
  </div>
);

ChargerSet.propTypes = {
  idx: PropTypes.number,
  set: PropTypes.object,
  onClick: PropTypes.func,
};

const ChargerSets = () => {
  const {chargerSets, setInput} = useInputs();
  const [selectedChargerSet, setSelectedChargerSet] = useState(null);

  const saveAndClose = (newCharger) => {
    if (newCharger.idx !== -1) {
      const newChargerSets = chargerSets.slice();
      newChargerSets[newCharger.idx] = newCharger;
      setInput({chargerSets: newChargerSets});
    } else {
      setInput({chargerSets: chargerSets.concat(newCharger)});
    }
    setSelectedChargerSet(null);
  };

  const discardAndClose = (charger) => {
    if (charger.idx !== -1) {
      setInput({chargerSets: chargerSets.filter((_, idx) => idx !== charger.idx)});
    }

    setSelectedChargerSet(null);
  };

  return (
    <>
      {chargerSets.map((chargerSet, idx) => (
        <ChargerSet
          set={chargerSet}
          key={idx}
          onClick={() => setSelectedChargerSet({...chargerSet, idx})}
        />
      ))}
      {selectedChargerSet && (
        <NewChargerSetModal
          chargerSet={selectedChargerSet}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          isOpen
          handleClose={() => setSelectedChargerSet(null)}
        />
      )}
      {chargerSets.length < MAX_CHARGER_SETS && (
        <button
          type="button"
          onClick={() => setSelectedChargerSet({...DEFAULT_CHARGER_SET})}
          className="btn btn-link pl-0"
        >
          + Add a new charger set
        </button>
      )}
    </>
  );
};

export default ChargerSets;
