import React from 'react';
import {typography} from '../styles';
import TrackableLink from './TrackableLink';

const TalkToAPGESpecialist = () => (
  <TrackableLink
    event="Talk to a PG&E EV Specialist"
    category="Clicked to speak to EV Specialist"
    href="https://energyinsight.secure.force.com/EVCustomerInterestFormPage?_ga=2.4549424.2068183774.1592832961-879105444.1577834572"
    className="btn btn-primary"
    style={{
      fontSize: typography.cta,
      fontWeight: typography.weight.bolder,
      padding: '10px 20px',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
      borderStyle: 'solid',
    }}
  >
    Talk to a PG&amp;E EV Specialist
  </TrackableLink>
);

export default TalkToAPGESpecialist;
