export const ELIGIBLE_ZIP_CODES = [
  92304,
  92365,
  92704,
  93062,
  93101,
  93105,
  93110,
  93111,
  93117,
  93201,
  93203,
  93204,
  93206,
  93210,
  93212,
  93214,
  93219,
  93224,
  93230,
  93232,
  93234,
  93239,
  93240,
  93241,
  93242,
  93243,
  93245,
  93249,
  93250,
  93251,
  93252,
  93254,
  93256,
  93263,
  93265,
  93266,
  93268,
  93274,
  93276,
  93277,
  93280,
  93282,
  93286,
  93287,
  93291,
  93292,
  93301,
  93302,
  93303,
  93304,
  93305,
  93306,
  93307,
  93308,
  93309,
  93311,
  93312,
  93313,
  93314,
  93330,
  93401,
  93402,
  93405,
  93406,
  93420,
  93421,
  93422,
  93423,
  93424,
  93426,
  93427,
  93428,
  93429,
  93430,
  93432,
  93433,
  93434,
  93435,
  93436,
  93437,
  93438,
  93440,
  93441,
  93442,
  93443,
  93444,
  93445,
  93446,
  93449,
  93450,
  93451,
  93452,
  93453,
  93454,
  93455,
  93456,
  93458,
  93460,
  93461,
  93463,
  93464,
  93465,
  93466,
  93516,
  93523,
  93526,
  93527,
  93555,
  93558,
  93561,
  93562,
  93601,
  93602,
  93603,
  93604,
  93606,
  93607,
  93608,
  93609,
  93610,
  93611,
  93612,
  93614,
  93615,
  93616,
  93618,
  93619,
  93620,
  93621,
  93622,
  93623,
  93624,
  93625,
  93626,
  93627,
  93628,
  93630,
  93631,
  93633,
  93634,
  93635,
  93636,
  93637,
  93638,
  93639,
  93640,
  93641,
  93643,
  93644,
  93645,
  93646,
  93647,
  93648,
  93650,
  93651,
  93652,
  93653,
  93654,
  93656,
  93657,
  93660,
  93662,
  93664,
  93665,
  93666,
  93667,
  93668,
  93669,
  93670,
  93673,
  93675,
  93701,
  93702,
  93703,
  93704,
  93705,
  93706,
  93710,
  93711,
  93720,
  93721,
  93722,
  93723,
  93725,
  93726,
  93727,
  93728,
  93730,
  93737,
  93741,
  93901,
  93905,
  93906,
  93907,
  93908,
  93915,
  93920,
  93921,
  93922,
  93923,
  93924,
  93925,
  93926,
  93927,
  93928,
  93930,
  93932,
  93933,
  93940,
  93943,
  93950,
  93953,
  93954,
  93955,
  93960,
  93962,
  94002,
  94003,
  94005,
  94010,
  94014,
  94015,
  94018,
  94019,
  94020,
  94021,
  94022,
  94024,
  94025,
  94026,
  94027,
  94028,
  94030,
  94034,
  94037,
  94038,
  94040,
  94041,
  94043,
  94044,
  94060,
  94061,
  94062,
  94063,
  94065,
  94066,
  94070,
  94074,
  94080,
  94085,
  94086,
  94087,
  94088,
  94089,
  94101,
  94102,
  94103,
  94104,
  94105,
  94107,
  94108,
  94109,
  94110,
  94111,
  94112,
  94113,
  94114,
  94115,
  94116,
  94117,
  94118,
  94121,
  94122,
  94123,
  94124,
  94127,
  94129,
  94130,
  94131,
  94132,
  94133,
  94134,
  94141,
  94143,
  94158,
  94159,
  94203,
  94211,
  94248,
  94301,
  94303,
  94305,
  94306,
  94401,
  94402,
  94403,
  94404,
  94501,
  94502,
  94503,
  94504,
  94505,
  94506,
  94507,
  94508,
  94509,
  94510,
  94511,
  94512,
  94513,
  94514,
  94515,
  94516,
  94517,
  94518,
  94519,
  94520,
  94521,
  94522,
  94523,
  94525,
  94526,
  94527,
  94528,
  94530,
  94531,
  94533,
  94534,
  94535,
  94536,
  94538,
  94539,
  94541,
  94542,
  94543,
  94544,
  94545,
  94546,
  94547,
  94548,
  94549,
  94550,
  94551,
  94552,
  94553,
  94555,
  94556,
  94558,
  94559,
  94560,
  94561,
  94562,
  94563,
  94564,
  94565,
  94566,
  94567,
  94568,
  94569,
  94571,
  94572,
  94573,
  94574,
  94576,
  94577,
  94578,
  94579,
  94580,
  94582,
  94583,
  94585,
  94586,
  94587,
  94588,
  94589,
  94590,
  94591,
  94595,
  94596,
  94597,
  94598,
  94599,
  94601,
  94602,
  94603,
  94604,
  94605,
  94606,
  94607,
  94608,
  94609,
  94610,
  94611,
  94612,
  94613,
  94618,
  94619,
  94621,
  94662,
  94701,
  94702,
  94703,
  94704,
  94705,
  94706,
  94707,
  94708,
  94709,
  94710,
  94720,
  94801,
  94803,
  94804,
  94805,
  94806,
  94901,
  94903,
  94904,
  94909,
  94913,
  94914,
  94920,
  94922,
  94923,
  94924,
  94925,
  94928,
  94929,
  94930,
  94931,
  94933,
  94937,
  94938,
  94939,
  94940,
  94941,
  94942,
  94945,
  94946,
  94947,
  94949,
  94950,
  94951,
  94952,
  94954,
  94956,
  94957,
  94960,
  94963,
  94964,
  94965,
  94966,
  94970,
  94971,
  94972,
  94973,
  95001,
  95002,
  95003,
  95004,
  95005,
  95006,
  95007,
  95008,
  95010,
  95012,
  95013,
  95014,
  95017,
  95018,
  95019,
  95020,
  95021,
  95023,
  95030,
  95031,
  95032,
  95033,
  95035,
  95037,
  95038,
  95039,
  95041,
  95043,
  95044,
  95045,
  95046,
  95050,
  95051,
  95054,
  95060,
  95062,
  95063,
  95064,
  95065,
  95066,
  95067,
  95070,
  95073,
  95075,
  95076,
  95078,
  95101,
  95103,
  95110,
  95111,
  95112,
  95113,
  95116,
  95117,
  95118,
  95119,
  95120,
  95121,
  95122,
  95123,
  95124,
  95125,
  95126,
  95127,
  95128,
  95129,
  95130,
  95131,
  95132,
  95133,
  95134,
  95135,
  95136,
  95138,
  95139,
  95140,
  95141,
  95148,
  95150,
  95158,
  95201,
  95202,
  95203,
  95204,
  95205,
  95206,
  95207,
  95209,
  95210,
  95212,
  95215,
  95219,
  95220,
  95221,
  95222,
  95223,
  95224,
  95225,
  95226,
  95227,
  95228,
  95229,
  95230,
  95231,
  95232,
  95233,
  95234,
  95236,
  95237,
  95240,
  95241,
  95242,
  95245,
  95246,
  95247,
  95248,
  95249,
  95250,
  95251,
  95252,
  95253,
  95254,
  95255,
  95257,
  95258,
  95301,
  95303,
  95304,
  95305,
  95306,
  95307,
  95309,
  95310,
  95311,
  95312,
  95313,
  95315,
  95316,
  95317,
  95318,
  95319,
  95320,
  95321,
  95322,
  95323,
  95324,
  95325,
  95326,
  95327,
  95328,
  95329,
  95330,
  95333,
  95334,
  95335,
  95336,
  95337,
  95338,
  95339,
  95340,
  95341,
  95344,
  95345,
  95346,
  95347,
  95348,
  95350,
  95351,
  95352,
  95353,
  95354,
  95355,
  95356,
  95357,
  95358,
  95360,
  95361,
  95363,
  95364,
  95365,
  95366,
  95367,
  95368,
  95369,
  95370,
  95372,
  95374,
  95375,
  95376,
  95377,
  95378,
  95379,
  95380,
  95381,
  95382,
  95383,
  95385,
  95386,
  95387,
  95388,
  95389,
  95391,
  95401,
  95402,
  95403,
  95404,
  95405,
  95407,
  95409,
  95410,
  95412,
  95414,
  95415,
  95416,
  95417,
  95418,
  95419,
  95420,
  95421,
  95422,
  95423,
  95424,
  95425,
  95426,
  95427,
  95428,
  95429,
  95430,
  95432,
  95433,
  95434,
  95435,
  95436,
  95437,
  95439,
  95441,
  95442,
  95443,
  95444,
  95445,
  95446,
  95448,
  95449,
  95450,
  95451,
  95452,
  95453,
  95454,
  95456,
  95457,
  95458,
  95459,
  95460,
  95461,
  95462,
  95463,
  95464,
  95465,
  95466,
  95467,
  95468,
  95469,
  95470,
  95471,
  95472,
  95474,
  95476,
  95480,
  95481,
  95482,
  95485,
  95486,
  95488,
  95490,
  95492,
  95493,
  95494,
  95497,
  95501,
  95502,
  95503,
  95511,
  95514,
  95518,
  95519,
  95521,
  95524,
  95525,
  95526,
  95527,
  95528,
  95536,
  95537,
  95540,
  95542,
  95545,
  95546,
  95547,
  95549,
  95550,
  95551,
  95552,
  95553,
  95554,
  95555,
  95556,
  95558,
  95559,
  95560,
  95561,
  95562,
  95563,
  95564,
  95565,
  95568,
  95569,
  95570,
  95571,
  95573,
  95585,
  95587,
  95589,
  95595,
  95601,
  95602,
  95603,
  95604,
  95605,
  95606,
  95607,
  95608,
  95610,
  95612,
  95613,
  95614,
  95615,
  95616,
  95617,
  95618,
  95619,
  95620,
  95621,
  95623,
  95624,
  95625,
  95626,
  95627,
  95628,
  95629,
  95630,
  95631,
  95632,
  95633,
  95634,
  95635,
  95636,
  95637,
  95639,
  95640,
  95641,
  95642,
  95643,
  95645,
  95648,
  95650,
  95651,
  95652,
  95653,
  95654,
  95656,
  95658,
  95659,
  95660,
  95661,
  95662,
  95663,
  95664,
  95665,
  95666,
  95667,
  95668,
  95669,
  95670,
  95672,
  95673,
  95674,
  95675,
  95676,
  95677,
  95678,
  95679,
  95680,
  95681,
  95682,
  95684,
  95685,
  95686,
  95687,
  95688,
  95689,
  95690,
  95691,
  95692,
  95694,
  95695,
  95697,
  95698,
  95699,
  95701,
  95703,
  95709,
  95712,
  95713,
  95714,
  95715,
  95717,
  95720,
  95721,
  95722,
  95724,
  95726,
  95728,
  95735,
  95736,
  95742,
  95746,
  95747,
  95757,
  95758,
  95762,
  95765,
  95776,
  95811,
  95812,
  95814,
  95815,
  95816,
  95817,
  95818,
  95819,
  95820,
  95821,
  95822,
  95823,
  95824,
  95825,
  95826,
  95827,
  95828,
  95829,
  95830,
  95831,
  95832,
  95833,
  95834,
  95835,
  95837,
  95838,
  95841,
  95842,
  95843,
  95864,
  95901,
  95903,
  95910,
  95912,
  95913,
  95914,
  95915,
  95916,
  95917,
  95918,
  95919,
  95920,
  95922,
  95923,
  95924,
  95925,
  95926,
  95927,
  95928,
  95930,
  95932,
  95934,
  95935,
  95936,
  95937,
  95938,
  95939,
  95940,
  95941,
  95942,
  95943,
  95944,
  95945,
  95946,
  95947,
  95948,
  95949,
  95950,
  95951,
  95953,
  95954,
  95955,
  95956,
  95957,
  95958,
  95959,
  95960,
  95961,
  95962,
  95963,
  95965,
  95966,
  95967,
  95968,
  95969,
  95970,
  95971,
  95972,
  95973,
  95974,
  95975,
  95977,
  95978,
  95979,
  95980,
  95981,
  95982,
  95983,
  95984,
  95986,
  95987,
  95988,
  95991,
  95993,
  96001,
  96002,
  96003,
  96007,
  96008,
  96009,
  96010,
  96011,
  96013,
  96016,
  96019,
  96020,
  96021,
  96022,
  96028,
  96029,
  96033,
  96035,
  96040,
  96041,
  96047,
  96051,
  96055,
  96056,
  96059,
  96061,
  96062,
  96063,
  96065,
  96068,
  96069,
  96071,
  96073,
  96074,
  96075,
  96076,
  96078,
  96080,
  96084,
  96087,
  96088,
  96089,
  96090,
  96092,
  96095,
  96096,
  96125,
  96137,
];
