import React from 'react';
import {useToggle} from '@bellawatt/react-hooks';
import {useInputs} from '@bellawatt/use-inputs';
import {Row, Col} from 'react-bootstrap';
import sum from 'lodash/sum';

import calcElectricTcoByYear from '../../../calcs/calcElectricTcoByYear';
import calcFossilTcoByYear from '../../../calcs/calcFossilTcoByYear';
import calcTcoRoi from '../../../calcs/calcTcoRoi';

import {formatAsDollarsRounded, formatAsYears} from '../../../utils/format';
import IconHeader from '../../../layout/IconHeader';

const IconsHeader = ({incentives, loading}) => {
  const {
    vehicleSets,
    yearsOfOperation,
    gridIntensityInGramsCO2ePerMJ,
    chargers,
    btmConstructionCost,
    btmEvseCost,
    bevRateName,
    bevBlocks,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
  } = useInputs();

  const [showMobile, toggle] = useToggle(false);

  const electricTcoByYear = calcElectricTcoByYear(
    yearsOfOperation,
    vehicleSets,
    chargers,
    bevRateName,
    bevBlocks,
    gridIntensityInGramsCO2ePerMJ,
    btmConstructionCost,
    btmEvseCost,
    incentives
  );

  const fossilTcoByYear = calcFossilTcoByYear(
    yearsOfOperation,
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const roi = calcTcoRoi(electricTcoByYear, fossilTcoByYear);
  const electricTco = sum(electricTcoByYear);
  const fossilTco = sum(fossilTcoByYear);
  const savings = fossilTco - electricTco;

  return (
    <>
      <Row>
        <Col className={showMobile ? '' : 'd-none d-md-block'}>
          <div className="d-flex justify-content-center">
            <IconHeader
              header="Payback Period"
              icon={<img src="/images/icon-savings.png" alt="" style={{height: '62px'}} />}
              tooltip="Payback period is defined as the time it takes for the cost of the Electric Vehicles to become less than the total cost for equivalent Fossil Fuel vehicles. Potential tax advantages are not enumerated."
            >
              {loading ? '-' : <span>{roi === null ? '-' : `${formatAsYears(roi)}`}</span>}
            </IconHeader>
            <IconHeader
              style={{marginLeft: '20px', paddingLeft: '20px', borderLeft: '1px solid #212529'}}
              header="EV Savings"
            >
              {loading ? '-' : formatAsDollarsRounded(savings)}
            </IconHeader>
          </div>
        </Col>

        <Col className={showMobile ? '' : 'd-none d-md-block'}>
          <div className="d-flex justify-content-center">
            <IconHeader
              header="EV Total Costs"
              icon={<img src="/images/icon-car.png" alt="" style={{height: '62px'}} />}
            >
              {loading ? '-' : formatAsDollarsRounded(electricTco)}
            </IconHeader>
            <IconHeader
              style={{marginLeft: '20px', paddingLeft: '20px', borderLeft: '1px solid #212529'}}
              header="Fossil Total Costs"
            >
              {formatAsDollarsRounded(fossilTco)}
            </IconHeader>
          </div>
        </Col>
      </Row>
      <div className="d-block my-1 mb-3 d-md-none text-center">
        <button onClick={toggle} className="btn btn-sm btn-link" type="button">
          {showMobile ? 'Hide Savings' : 'See More Savings'}
        </button>
      </div>
    </>
  );
};

export default IconsHeader;
