import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';

const ValueText = ({value}) => (
  <div
    style={{
      float: 'right',
      fontWeight: '500',
      marginTop: '-5px',
      fontSize: '14px',
      textAlign: 'center',
      border: '1px solid #dbdbdb',
      padding: '4px 8px',
    }}
    type="text"
  >
    {value}
  </div>
);

const Slider = ({label, min, max, value, onChange, step = 1, subText, valFormat}) => {
  return (
    <div style={{width: '100%'}}>
      <Label>
        {label}
        <ValueText value={valFormat ? valFormat(value) : value} />
        <input
          min={min}
          step={step}
          max={max}
          value={value}
          onChange={(e) => onChange && onChange(e.currentTarget.value)}
          type="range"
          style={{marginTop: '10px'}}
          className="custom-range"
        />
      </Label>
      <p className="text-center">
        <span className="float-left">{valFormat ? valFormat(min) : min}</span>
        <span className="float-right">{valFormat ? valFormat(max) : max}</span>
      </p>
      <p className="mb-0 mt-4">{subText}</p>
    </div>
  );
};

Slider.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  step: PropTypes.number,
  subText: PropTypes.node,
};

export default Slider;
