import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {typography} from '../styles';
import {Caret} from '../icons';
import Tooltip from './Tooltip';

const Header = ({children}) => (
  <div
    style={{
      color: '#333',
      fontSize: typography.cta,
      fontWeight: typography.weight.bold,
    }}
  >
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node,
};

const GreyDiv = ({children, header, tooltip, collapsible = false}) => {
  const [open, setOpen] = useState(true);

  return (
    <div
      style={{
        backgroundColor: '#f5f5f5',
        padding: '27px',
        marginBottom: '15px',
        position: 'relative',
      }}
    >
      {collapsible && (
        <button
          onClick={() => setOpen((current) => !current)}
          type="button"
          className="position-absolute btn btn-link"
          style={{top: '20px', right: 0}}
        >
          <Caret direction={open ? 'up' : 'down'} />
        </button>
      )}
      {header && (
        <Header>
          {`${header} `}
          {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </Header>
      )}
      {open && children}
    </div>
  );
};

GreyDiv.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  tooltip: PropTypes.string,
  collapsible: PropTypes.bool,
};

export default GreyDiv;
