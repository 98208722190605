import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Select from '../Select';

const ChargingFacilityType = () => {
  const {chargingFacilityType, setInput} = useInputs();

  return (
    <div>
      <Select
        label="Facility Type"
        optionValues={['DCFC', 'Workplace', 'Multifamily', 'Transit', 'Medium Duty', 'Custom']}
        value={chargingFacilityType}
        onChange={(chargingFacilityType) => setInput({chargingFacilityType})}
      />
      <p className="small mt-2">
        Each facility type is representative of different charger utilizations.
      </p>
      <p className="small mt-2">
        To define your own EV charger utilizations, please select <i>Custom</i> and update the{' '}
        <i>Chargers</i> fields below.
      </p>
      {chargingFacilityType === 'Custom' && (
        <p className="small mt-2 font-weight-bold">
          To change the utilization, click into each charger below and change the parameters to best
          suit your unique setup.
        </p>
      )}
    </div>
  );
};

export default ChargingFacilityType;
