import React from 'react';
import uniq from 'lodash/uniq';
import Select from '../Select';

const SelectVehicleMakeModel = ({value, onChange, vehicles}) => (
  <Select
    label="Make/Model"
    multiLine
    value={value}
    optionValues={uniq(
      vehicles
        .map(({name}) => name)
        .sort((a, b) => {
          if (a === 'Generic') {
            return 1;
          }

          if (b === 'Generic') {
            return -1;
          }

          return a > b ? 1 : -1;
        })
    )}
    onChange={onChange}
  />
);

export default SelectVehicleMakeModel;
