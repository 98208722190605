const fixedCharges = {
  Secondary: 31.54779,
  Primary: 48.83272,
  Transmission: 71.30886,
};

const demandPeakSummerCharges = {
  Secondary: 32.9,
  Primary: 27.95,
  Transmission: 13.94,
};

const demandPartPeakSummerCharges = {
  Secondary: 6.81,
  Primary: 5.76,
  Transmission: 3.49,
};

const demandSummerCharges = {
  Secondary: 26.07,
  Primary: 22.17,
  Transmission: 15.81,
};

const demandPeakWinterCharges = {
  Secondary: 2.22,
  Primary: 1.65,
  Transmission: 1.34,
};

const demandWinterCharges = {
  Secondary: 26.07,
  Primary: 22.17,
  Transmission: 15.81,
};

const energyPeakSummerCharges = {
  Secondary: 0.19598,
  Primary: 0.17793,
  Transmission: 0.16195,
};

const energyPartPeakSummerCharges = {
  Secondary: 0.15878,
  Primary: 0.14914,
  Transmission: 0.1501,
};

const energyOffPeakSummerCharges = {
  Secondary: 0.13246,
  Primary: 0.1241,
  Transmission: 0.12488,
};

const energyPeakWinterCharges = {
  Secondary: 0.17235,
  Primary: 0.16119,
  Transmission: 0.16115,
};

const energyOffPeakWinterCharges = {
  Secondary: 0.13236,
  Primary: 0.12441,
  Transmission: 0.12543,
};

const energySuperOffPeakWinterCharges = {
  Secondary: 0.07872,
  Primary: 0.07207,
  Transmission: 0.0729,
};

const b19Rate = ({voltage}) => ({
  name: 'B-19',
  title: 'B-19 Medium General Demand-Metered TOU Service',
  maxKw: 999,
  rateElements: [
    {
      rateElementType: 'FixedPerDay',
      classification: 'fixed',
      rateComponents: [
        {
          name: 'Customer Charge Mandatory B-19 ($ per meter per day)',
          charge: fixedCharges[voltage],
        },
      ],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      classification: 'demand',
      rateComponents: [
        {
          name: 'Maximum Peak Demand Summer ($ per kW)',
          charge: demandPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Maximum Part-Peak Demand Summer ($ per kW)',
          charge: demandPartPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [14, 15, 21, 22],
        },
        {
          name: 'Maximum Demand Summer ($ per kW)',
          charge: demandSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ],
        },
        {
          name: 'Maximum Peak Demand Winter ($ per kW)',
          charge: demandPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Maximum Demand Winter ($ per kW)',
          charge: demandWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ],
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      classification: 'energy',
      rateComponents: [
        {
          name: 'Total Energy Rates: Peak Summer ($ per kWh)',
          charge: energyPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates: Part-Peak Summer ($ per kWh)',
          charge: energyPartPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [14, 15, 21, 22],
        },
        {
          name: 'Total Energy Rates: Off-Peak Summer ($ per kWh)',
          charge: energyOffPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 23],
        },
        {
          name: 'Total Energy Rates: Peak Winter ($ per kWh)',
          charge: energyPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates: Off-Peak Winter ($ per kWh)',
          charge: energyOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [9, 10, 11, 12, 13],
        },
        {
          name: 'Total Energy Rates: Super Off-Peak Winter ($ per kWh)',
          charge: energySuperOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
        },
      ],
    },
  ],
});

export default b19Rate;
