import React from 'react';
import BaseGraph from './BaseGraph';
import {formatAsDollars} from '../utils/format';
import A from '../data/ASSUMPTIONS';

const BevCostComponents = ({electricityCosts}) => {
  const data = {
    datasets: [
      {
        data: [
          electricityCosts.monthlyOverage,
          electricityCosts.monthlySubscription,
          electricityCosts.monthlyEnergy,
        ],
        backgroundColor: [A.COST_OVERAGE_COLOR, A.COST_SET_COLORS[1], A.COST_SET_COLORS[0]],
      },
    ],
    labels: ['Overage', 'Subscription', 'Energy'],
  };

  return (
    <BaseGraph
      title="BEV Cost Components"
      type="doughnut"
      data={data}
      tooltipCallback={formatAsDollars}
      yAxisOptions={{display: false}}
      xAxisOptions={{display: false}}
    />
  );
};

export default BevCostComponents;
