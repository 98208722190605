import React, {useState} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import BaseGraph from './BaseGraph';
import Select from '../inputs/Select';
import calcBatterySchedule from '../calcs/calcBatterySchedule/calcBatterySchedule';
import {formatVehicleName, formatAsPercentRounded} from '../utils/format';
import A from '../data/ASSUMPTIONS';

const WeeklyBatterySchedule = () => {
  const [vehicleSetIndex, setVehicleSetIndex] = useState(0);
  const {vehicleSets, chargers} = useInputs();

  const set = vehicleSets[vehicleSetIndex];

  const batterySchedule = calcBatterySchedule(chargers, set).weeklyBatterySchedule;

  const chargeAtHourStart = batterySchedule.map((schedule) => schedule.chargeAtHourStart);

  const data = {
    labels: A.WEEKDAYS_LABELS,
    datasets: [
      {
        label: formatVehicleName(set),
        backgroundColor: A.ELECTRICITY_SET_COLORS[vehicleSetIndex],
        borderColor: A.ELECTRICITY_SET_COLORS[vehicleSetIndex],
        data: chargeAtHourStart.map((charge) => charge / Math.max(...chargeAtHourStart)),
      },
    ],
  };

  return (
    <>
      <div style={{maxWidth: '300px', marginBottom: '2rem'}}>
        <Select
          label="Vehicle Set Selected"
          value={formatVehicleName(set)}
          optionValues={vehicleSets.map((_, idx) => idx)}
          optionNames={vehicleSets.map((set) => formatVehicleName(set))}
          onChange={(newVehicleSetIndex) => {
            setVehicleSetIndex(newVehicleSetIndex);
          }}
        />
      </div>

      <BaseGraph
        type="line"
        title="Weekly Battery Schedule"
        data={data}
        stacked
        xAxisOptions={{
          ticks: {maxTicksLimit: 7},
          categoryPercentage: 1.0,
          barPercentage: 1.0,
        }}
        yFormatCallback={formatAsPercentRounded}
        tooltipCallback={formatAsPercentRounded}
      />
    </>
  );
};

export default WeeklyBatterySchedule;
