const b6Rate = () => ({
  name: 'B-6',
  title: 'B-6 Small General TOU',
  maxKw: 75,
  rateElements: [
    {
      rateElementType: 'FixedPerDay',
      classification: 'fixed',
      rateComponents: [
        {name: 'Customer Charge Single-phase ($ per meter per day)', charge: 0.32854},
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      classification: 'energy',
      rateComponents: [
        {
          name: 'Peak Summer ($ per kWh)',
          charge: 0.55639,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Off-Peak Summer ($ per kWh)',
          charge: 0.29876,
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21, 22, 23],
        },
        {
          name: 'Peak Winter ($ per kWh)',
          charge: 0.3097,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Off-Peak Winter ($ per kWh)',
          charge: 0.2661,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
        },
        {
          name: 'Super Off-Peak Winter ($ per kWh)',
          charge: 0.23002,
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [9, 10, 11, 12, 13],
        },
      ],
    },
  ],
});

export default b6Rate;
