/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000';

const BASE_POST_URL = process.env.REACT_APP_EVALUATOR_API_URL || 'http://localhost:4000';

export const get = (url) =>
  axios.get(`${BASE_URL}${url}`, {
    headers: {
      Authorization: 'Bearer 805f1854eb6a4060b6b63921923100ac',
    },
  });

export const post = (url, data) => axios.post(`${BASE_POST_URL}${url}`, data);


