/* eslint-disable max-classes-per-file */
import React from 'react';
import {Container} from 'react-bootstrap';
import {Header, Subheader} from '../layout/Headers';
import PageLayout from '../layout/PageLayout';

export class VehicleIdentificationError extends Error {}

class DataErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasVehicleError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasVehicleError: error instanceof VehicleIdentificationError};
  }

  componentDidCatch(error, errorInfo) {}

  resetInputs() {
    window.localStorage.clear();
    window.location = window.location.href.split('?')[0];
  }

  render() {
    const {children} = this.props;
    const {hasVehicleError} = this.state;

    if (hasVehicleError) {
      return (
        <PageLayout>
          <Container className="text-center">
            <Header>Please reset your inputs!</Header>
            <Subheader>
              You have iether modified a valid url, or we have updated our vehicle data as part of
              our effort to stay up-to-date with a fast changing industry. Please reset your inputs
              using the button below to resolve this issue.
            </Subheader>
            <button
              type="button"
              onClick={this.resetInputs}
              className="btn text-center btn-outline-danger"
            >
              Reset Inputs
            </button>
          </Container>
        </PageLayout>
      );
    }

    return children;
  }
}

export default DataErrorBoundary;
