import React from 'react';
import ReactGA from 'react-ga4';
import {Inputs} from '@bellawatt/use-inputs';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {getDefaultsWithApi} from './defaults';
import FuelSavingsCalculator from './pages/FuelSavingsCalculator/FuelSavingsCalculator';
import TotalCostOfOwnership from './pages/TotalCostOfOwnership/TotalCostOfOwnership';
import BevRateCalculator from './pages/BevRateCalculator';
import LcfsCalculator from './pages/LcfsCalculator';
import GrantsAndIncentives from './pages/GrantsAndIncentives';
import Home from './pages/Home';
import useScrollToTop from './utils/useScrollToTop';
import watchers from './watchers';
import computed from './computed';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './print.css';
import ElectrificationPlanning from './pages/ElectrificationPlanning';
import ErrorPage from './pages/ErrorPage';
import Feedback from './components/Feedback';
import VehicleCatalog from './pages/VehicleCatalog';
import ErrorBoundary from './components/ErrorBoundary';
import DataErrorBoundary from './components/DataErrorBoundary';
import RateComparisonPage from './pages/RateComparisonPage';

const ErrorBoundedRoute = ({children, ...props}) => (
  <Route {...props}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Route>
);

const RoutedApplication = () => {
  useScrollToTop();
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

  return (
    <Inputs
      defaults={getDefaultsWithApi()}
      watch={watchers}
      computed={computed}
      ignore={['apiVehicles']}
    >
      <Feedback />
      <Switch>
        <ErrorBoundedRoute path="/tco">
          <TotalCostOfOwnership />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/fuel-savings">
          <FuelSavingsCalculator />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/bev-rate">
          <BevRateCalculator />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/rate-comparison">
          <RateComparisonPage />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/lcfs">
          <LcfsCalculator />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/plan">
          <ElectrificationPlanning />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/grants">
          <GrantsAndIncentives />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/error">
          <ErrorPage />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/vehicle-catalog">
          <VehicleCatalog />
        </ErrorBoundedRoute>
        <ErrorBoundedRoute path="/">
          <Home />
        </ErrorBoundedRoute>
      </Switch>
    </Inputs>
  );
};

const App = () => {
  return (
    <Router>
      <DataErrorBoundary>
        <RoutedApplication />
      </DataErrorBoundary>
    </Router>
  );
};

export default App;
