import React from 'react';
import PropTypes from 'prop-types';
import {Link as BaseLink} from 'react-router-dom';
import {colors, typography} from '../styles';

const Header = ({children}) => {
  return (
    <div
      style={{
        fontWeight: typography.weight.bold,
        fontSize: typography.headers[2],
        color: '#506272',
      }}
    >
      {children}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.string,
};

export const BorderedText = ({children, borderTop = true, borderBottom = true}) => (
  <div
    style={{
      borderTop: borderTop ? '1px solid #DBDBDB' : 'none',
      borderBottom: borderBottom ? '1px solid #DBDBDB' : 'none',
      paddingTop: '15px',
      paddingBottom: '15px',
      display: 'block',
      color: colors.text.base,
      fontSize: '20px',
    }}
  >
    {children}
  </div>
);

BorderedText.propTypes = {
  children: PropTypes.node,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

export const Bullets = ({children}) => (
  <ul className="my-4">
    {children.map((child, idx) => (
      <li key={idx} className="my-1">
        {child}
      </li>
    ))}
  </ul>
);

Bullets.propTypes = {
  children: PropTypes.node,
};

export const Sublink = ({href, children}) => (
  <BaseLink
    to={href}
    style={{
      fontSize: typography.links.primary,
      textDecoration: 'none',
      display: 'block',
    }}
  >
    {children}
  </BaseLink>
);

Sublink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

const Card = ({children, header}) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        backgroundColor: '#FFF',
        boxShadow: '0 0 4px 0 rgba(0,0,0,0.1)',
        padding: '32px',
        width: '100%',
        maxWidth: '480px',
        fontSize: typography.body.secondary,
        margin: '16px auto',
      }}
    >
      <Header>{header}</Header>
      {children}
    </div>
  );
};

Card.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
