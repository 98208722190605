import React from 'react';

const Graph = () => (
  <svg width="47px" height="48px" viewBox="0 0 47 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="PGE-Fleets_Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Wizard-Intro" transform="translate(-232.000000, -480.000000)">
            <g id="Group-8" transform="translate(103.000000, 125.000000)">
                <g id="Group-7" transform="translate(125.000000, 204.000000)">
                    <g id="Group-2" transform="translate(4.000000, 10.500000)">
                        <g id="icn-chart" transform="translate(0.000000, 141.000000)">
                            <g id="Group-Copy-2" fill="#00527C">
                                <circle id="Oval" cx="23.5" cy="23.5" r="23.5"></circle>
                            </g>
                            <g id="noun_bar-chart_1080214" transform="translate(12.702703, 12.702703)" fill="#FFFFFF" fillRule="nonzero">
                                <path d="M22.1043252,21.5945946 L0,21.5945946 L0,1.67331687 C0,1.26819805 0.340721776,0.939405964 0.760539678,0.939405964 C1.18035758,0.939405964 1.52107936,1.26819805 1.52107936,1.67331687 L1.52107936,20.1267728 L22.1043252,20.1267728 C22.5241431,20.1267728 22.8648649,20.4555649 22.8648649,20.8606837 C22.8648649,21.2658025 22.5241431,21.5945946 22.1043252,21.5945946 Z M20.9604735,0 C19.3420451,1.02160399 17.078679,2.10779213 15.2716367,2.49529709 L17.577593,3.15875255 C14.26164,7.22168335 10.7996634,8.82454477 8.43286394,9.45277251 C5.7618486,10.1631983 3.86962588,9.81679232 3.82703566,9.81092104 C3.41938639,9.72872301 3.01782144,9.98705965 2.932641,10.3804359 C2.84746055,10.7796834 3.1090862,11.1671884 3.52281979,11.2493864 C3.57149433,11.261129 4.1555888,11.3726835 5.12907959,11.3726835 C6.0417272,11.3726835 7.28901227,11.2728716 8.74316413,10.8971092 C10.4893632,10.4450201 12.1929721,9.68175272 13.7992319,8.63079229 C15.6001899,7.45066355 17.2794615,5.906515 18.8066251,4.02770307 L20.1573436,5.98284173 C20.0052357,4.1979704 20.4250536,1.79074262 20.9604735,0 Z M8.42677963,12.7583072 L3.0725803,12.7583072 L3.0725803,18.7528916 L8.42677963,18.7528916 L8.42677963,12.7583072 Z M15.3020583,11.3550696 L9.94785898,11.3550696 L9.94785898,18.7528916 L15.3020583,18.7528916 L15.3020583,11.3550696 Z M22.1043252,8.61317843 L16.7501259,8.61317843 L16.7501259,18.6648222 L22.1043252,18.6648222 L22.1043252,8.61317843 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default Graph;
