import React from 'react';
import PropTypes from 'prop-types';
import {colors, typography} from '../styles';

const CheckButton = ({checked, children, onClick}) => (
  <button
    onClick={onClick}
    type="button"
    role="checkbox"
    aria-checked={checked}
    tabIndex="0"
    aria-label={children}
    className="hover-button-orange"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '100%',
      cursor: 'pointer',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: checked ? colors.brand.orange : colors.grey.lighter,
      backgroundColor: checked ? colors.brand.orange : 'transparent',
      color: colors.grey.darker,
      fontWeight: typography.weight.bold,
    }}
  >
    <small>{children}</small>
  </button>
);

CheckButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

export default CheckButton;
