const BEV = {
  name: 'BEV',
  title: 'Business Electric Vehicles',
  link: 'https://www.pge.com/tariffs/assets/pdf/tariffbook/ELEC_SCHEDS_BEV.pdf',
  tariff_elements: {
    pge_subscription_rate: [
      {
        name: 'Business Low Use EV',
        code: 'BEV1',
        helpText:
          'For EV charging installations with a connected load up to and including 100 kilowatts (kW). Best suited for smaller projects with level 2 chargers.',
        title: 'Under 100kW',
        blockSizeInKw: 10,
        periods: [
          {
            charge: 0.38204,
            hours: [16, 17, 18, 19, 20],
            name: 'peak',
          },
          {
            charge: 0.19003,
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
            name: 'off-peak',
          },
          {
            charge: 0.16337,
            hours: [9, 10, 11, 12, 13],
            name: 'super off-peak',
          },
        ],
        subscriptionChargePerBlock: 12.41,
        subscriptionOverageChargePerKw: 2.48,
        crsTotalChargePerKwh: 0.01085,
        maxApplicableKwh: 100,
      },
      {
        name: 'Business High Use EV',
        code: 'BEV2-S',
        helpText:
          'For EV charging installations with a connected load of 100 kilowatts (kW) and above. A secondary service refers to electricity supplied via lower voltage distribution lines. This rate is best suited for medium-sized projects.',
        title: 'Over 100kW',
        blockSizeInKw: 50,
        periods: [
          {
            charge: 0.39601,
            hours: [16, 17, 18, 19, 20],
            name: 'peak',
          },
          {
            charge: 0.18278,
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
            name: 'off-peak',
          },
          {
            charge: 0.15951,
            hours: [9, 10, 11, 12, 13],
            name: 'super off-peak',
          },
        ],
        subscriptionChargePerBlock: 95.56,
        subscriptionOverageChargePerKw: 3.82,
        crsTotalChargePerKwh: 0.01239,
      },
      {
        name: 'Business High Use EV (Primary & Transmission)',
        code: 'BEV2-P',
        helpText:
          'For EV charging installations with a connected load of 100 kilowatts (kW) and above. A primary service refers to electricity supplied via higher voltage transmission lines. This rate is best suited for larger projects.',
        title: 'Primary/Transmission Service',
        blockSizeInKw: 50,
        periods: [
          {
            charge: 0.38713,
            hours: [16, 17, 18, 19, 20],
            name: 'peak',
          },
          {
            charge: 0.17825,
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
            name: 'off-peak',
          },
          {
            charge: 0.15559,
            hours: [9, 10, 11, 12, 13],
            name: 'super off-peak',
          },
        ],
        subscriptionChargePerBlock: 85.98,
        subscriptionOverageChargePerKw: 3.44,
        crsTotalChargePerKwh: 0.01239,
      },
    ],
  },
};

export default BEV;
