const transitLoadProfile = [
  153.0,
  88.0,
  66.0,
  51.0,
  50.0,
  49.0,
  62.0,
  58.0,
  145.0,
  355.0,
  427.0,
  356.0,
  324.0,
  204.0,
  111.0,
  148.0,
  205.0,
  271.0,
  275.0,
  284.0,
  244.0,
  319.0,
  341.0,
  250.0,
];

export default transitLoadProfile;
