import {useInputs} from '@bellawatt/use-inputs';
import React from 'react';
import {Alert} from 'react-bootstrap';
import alarmMultipleChargersInUseAtOnce from '../calcs/alarmMultipleChargersInUseAtOnce';
import calcBatterySchedule from '../calcs/calcBatterySchedule/calcBatterySchedule';
import {formatVehicleName} from '../utils/format';

const DisplayVehicleSetAlarms = ({children, vehicleSets}) => {
  const {chargers} = useInputs();

  if (!vehicleSets) return null;

  const alarmMultipleChargersInUseSet = vehicleSets.map((set) =>
    alarmMultipleChargersInUseAtOnce(set.chargingWindows)
  );
  const alarmBatteryDepleted = vehicleSets.map(
    (set) => calcBatterySchedule(chargers, set).minStateOfCharge <= 0
  );

  const showAlarms =
    alarmMultipleChargersInUseSet.includes(true) || alarmBatteryDepleted.includes(true);

  return showAlarms ? (
    <>
      {alarmMultipleChargersInUseSet.includes(true) && (
        <Alert variant="danger" className="text-center py-5">
          <strong>Warning!!</strong> Multiple chargers are in use at once for{' '}
          <strong>
            {alarmMultipleChargersInUseSet
              .map((alarm, i) => (alarm ? formatVehicleName(vehicleSets[i]) : null))
              .filter((val) => val !== null)
              .join(', ')}
          </strong>
          .
        </Alert>
      )}
      {alarmBatteryDepleted.includes(true) && (
        <Alert variant="danger" className="text-center py-5">
          <strong>Warning!!</strong> The following vehicles deplete their batteries:{' '}
          <strong>
            {alarmBatteryDepleted
              .map((alarm, i) => (alarm ? formatVehicleName(vehicleSets[i]) : null))
              .filter((val) => val !== null)
              .join(', ')}
          </strong>
          .<br />
          <br />
          Please add more charging times, increase the charging level(s), and/or decrease the daily
          miles.
        </Alert>
      )}
    </>
  ) : (
    children
  );
};

export default DisplayVehicleSetAlarms;
