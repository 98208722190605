import React, {useState} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../TextInput';
import Select from '../Select';
import Slider from '../Slider';

const CUSTOM_CHARGER_PORT_OPTIONS = {1: 'Single', 2: 'Double', 3: 'Ten'};

const CreateCustomCharger = ({cancel, changeParam, defaultCharger}) => {
  const {setInput, chargers, customChargers} = useInputs();
  const [error, setError] = useState(null);
  const [customCharger, setCustomCharger] = useState({
    ...chargers.find(({type}) => type === defaultCharger.type),
    type: '',
  });

  const customParamChange = (change) => {
    setCustomCharger((current) => ({...current, ...change}));
  };

  const createCustomCharger = () => {
    if (!customCharger.type) {
      setError('Custom chargers must have a type.');
      return;
    }

    if (chargers.find(({type}) => type === customCharger.type)) {
      setError('This type already exists! Please use a unique type for each custom charger.');
    }

    setInput({customChargers: [...customChargers, customCharger]});
    changeParam(customCharger);
    cancel();
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <TextInput
            label="Custom Charger Name"
            value={customCharger.type}
            onChange={(type) => customParamChange({type})}
          />
        </Col>
        <Col sm={6}>
          <Select
            label="Ports"
            value={CUSTOM_CHARGER_PORT_OPTIONS[customCharger.ports]}
            onChange={(ports) => customParamChange({ports})}
            optionValues={Object.keys(CUSTOM_CHARGER_PORT_OPTIONS)}
            optionNames={Object.values(CUSTOM_CHARGER_PORT_OPTIONS)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Slider
            label="Charging Speed (kW)"
            value={customCharger.portKw}
            onChange={(portKw) => customParamChange({portKw})}
            min={10}
            max={400}
            step={5}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <button type="button" className="btn btn-secondary" onClick={cancel}>
            Cancel
          </button>
          <button type="button" onClick={createCustomCharger} className="mx-2 btn btn-primary">
            Save
          </button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="text-right text-danger">{error}</Col>
      </Row>
    </>
  );
};

CreateCustomCharger.propTypes = {
  cancel: PropTypes.func,
  changeParam: PropTypes.func,
  defaultCharger: PropTypes.object,
};

export default CreateCustomCharger;
