/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import times from 'lodash/times';

moment.tz.setDefault('America/New_York');

const hourlyDates = {};

const generateHoursOfYear = (year) => {
  const profileTime = moment(year, 'Y');

  return times(profileTime.isLeapYear() ? 8784 : 8760, (hourOfYear) => {
    const val = {
      month: profileTime.month(),
      dayOfWeek: profileTime.day(),
      hourStart: profileTime.hour(),
      date: profileTime.format('YYYY-MM-DD'),
      hourOfYear,
    };

    profileTime.add(1, 'hour');
    return val;
  });
};

export const getHoursOfYear = (year) => {
  if (!hourlyDates[year]) {
    hourlyDates[year] = generateHoursOfYear(year);
  }

  return hourlyDates[year];
};

export const getHoursOfWeek = () => {
  return times(24 * 7, (i) => ({
    hourStart: i % 24,
    dayOfWeek: Math.floor(i / 24) === 6 ? 0 : Math.floor(i / 24) + 1,
  }));
};
