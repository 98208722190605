import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Select from '../Select';
import A from '../../data/ASSUMPTIONS';

const SelectElectricCarbonIntensity = () => {
  const {gridIntensityInGramsCO2ePerMJ, setInput} = useInputs();
  const {names} = A.GRID_INTENSITY_PATHWAYS;
  const {values} = A.GRID_INTENSITY_PATHWAYS;

  return (
    <>
      <Select
        label="Electricity Source"
        optionNames={names}
        optionValues={values}
        value={names[values.indexOf(gridIntensityInGramsCO2ePerMJ)]}
        onChange={(gridIntensityInGramsCO2ePerMJ) => {
          setInput({gridIntensityInGramsCO2ePerMJ});
        }}
      />
      <p>
        <small>
          Carbon neutral generation creates more LCFS credits. Carbon neutrality can be acheived with
          clean on-site generation or Renewable Energy Credits (RECs).
        </small>
      </p>
    </>
  );
};

export default SelectElectricCarbonIntensity;
