import React from 'react';

const CustomVehicle = () => {
  return (
    <img
      src="images/custom-vehicle.svg"
      alt="Custom Vehicle"
      className="img-fluid"
      style={{maxWidth: '200px', width: '100%', padding: '15% 10% 10% 10%'}}
    />
  );
};

export default CustomVehicle;
