import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {formatAsDollarsRounded, formatAsDollars, formatVehicleName} from '../utils/format';
import uniqueLabels from '../utils/uniqueLabels';
import BaseGraph from './BaseGraph';
import {colors} from '../styles';
import A from '../data/ASSUMPTIONS';
import calcFossilFuelCostForVehicleSet from '../calcs/calcFossilFuelCostForVehicleSet';
import calcAnnualFossilFuelCost from '../calcs/calcAnnualFossilFuelCost';
import calcAnnualElectricityCost from '../calcs/calcAnnualElectricityCost';

const AnnualFuelCosts = ({overwriteRateInputs = false}) => {
  const {
    vehicleSets,
    bevRateName,
    bevBlocks,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
    chargers,
  } = useInputs();

  const totalElectricityCosts = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    overwriteRateInputs
  );

  const fuelCostSets = vehicleSets.map((set, i) => {
    const cost = calcFossilFuelCostForVehicleSet(
      set,
      dieselPrice,
      gasolinePrice,
      lpgPrice
    );
    return {
      label: formatVehicleName(set),
      backgroundColor: A.OIL_SET_COLORS[i],
      data: [cost, 0, 0],
    };
  });

  const totalFossilFuelCosts = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const data = {
    labels: ['Fossil Fuel', 'Electricity', 'Fuel Savings'],
    datasets: [
      ...uniqueLabels(fuelCostSets),
      {
        label: 'Electric Vehicles',
        data: [0, totalElectricityCosts, 0],
        backgroundColor: colors.orange.base,
      },
      {
        label: 'hidden',
        backgroundColor: 'transparent',
        data: [0, 0, totalElectricityCosts],
      },
      {
        label: 'Savings',
        backgroundColor: colors.green.darkest,
        data: [0, 0, totalFossilFuelCosts - totalElectricityCosts],
      },
    ],
  };

  return (
    <>
      <BaseGraph
        title="Annual Fuel Costs"
        data={data}
        stacked
        yFormatCallback={formatAsDollarsRounded}
        tooltipCallback={formatAsDollars}
        options={{
          tooltips: {
            filter: (tooltipItem) =>
              !(tooltipItem.index === 2 && tooltipItem.datasetIndex === fuelCostSets.length + 1),
          },
          legend: {labels: {filter: (legendItem) => legendItem.text !== 'hidden'}},
        }}
      />
    </>
  );
};

export default AnnualFuelCosts;
