import {checkIntersection} from 'line-intersect';

const cumulativeSum = (arr) => {
  // TODO: refactor using lodash?
  const output = [];
  arr.forEach((num, index) => {
    if (index === 0) {
      output[index] = num;
    } else {
      output[index] = num + output[index - 1];
    }
  });
  return output;
};

const calcTcoRoi = (electricTcoByYear, fossilTcoByYear) => {
  const cumulativeElectricCosts = cumulativeSum(electricTcoByYear);
  const cumulativeFossilCosts = cumulativeSum(fossilTcoByYear);

  const year = cumulativeElectricCosts.findIndex(
    (electric, idx) => electric < cumulativeFossilCosts[idx]
  );

  if (year === -1) {
    return null;
  }

  if (year === 0) {
    return 1;
  }

  const {
    point: {x: zeroIndexedYear},
  } = checkIntersection(
    year - 1,
    cumulativeElectricCosts[year - 1],
    year,
    cumulativeElectricCosts[year],
    year - 1,
    cumulativeFossilCosts[year - 1],
    year,
    cumulativeFossilCosts[year]
  );

  return zeroIndexedYear + 1;
};

export default calcTcoRoi;
