import calcTotalWeeklyLoadProfile from './calcTotalWeeklyLoadProfile';
import calcBevCosts from './calcBevCosts/calcBevCosts';

const calcAnnualElectricityCost = (
  chargers,
  vehicleSets,
  bevRateName,
  bevBlocks,
  overwriteRateInputs
) => {
  const weeklyLoadProfile = calcTotalWeeklyLoadProfile(chargers, vehicleSets);

  const electricityCosts = calcBevCosts(
    weeklyLoadProfile,
    bevRateName,
    bevBlocks,
    overwriteRateInputs
  );

  return electricityCosts.total;
};

export default calcAnnualElectricityCost;
