import React from 'react';
import times from 'lodash/times';
import BaseGraph from './BaseGraph';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import {colors} from '../styles';

const cumulativeSum = (arr) => { // TODO: refactor using lodash?
  const output = [];
  arr.forEach((num, index) => {
    if (index === 0) {
      output[index] = num;
    } else {
      output[index] = num + output[index - 1];
    }
  });
  return output;
};

const BreakEven = ({electricTcoByYear, fossilTcoByYear, yearsOfOperation}) => {
  const data = {
    labels: times(yearsOfOperation, (year) => `Year ${year + 1}`),
    datasets: [
      {
        label: 'Electric Vehicles',
        data: cumulativeSum(electricTcoByYear),
        borderColor: colors.orange.base,
        fill: false,
        tension: 0,
      },
      {
        label: 'Fossil Fuel Vehicles',
        data: cumulativeSum(fossilTcoByYear),
        borderColor: colors.grey.darker,
        fill: false,
        tension: 0,
      },
    ],
  };

  return (
    <BaseGraph
      title="Total Costs Over Time"
      type="line"
      data={data}
      yFormatCallback={formatAsDollarsRounded}
      tooltipCallback={formatAsDollars}
      pointRadius={5}
    />
  );
};

export default BreakEven;
