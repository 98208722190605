import React from 'react';
import MinimumChargingEquipmentTable from '../../../graphs/MinimumChargingEquipmentTable';
import VehiclesToChargers from './VehiclesToChargers';
import ChargersToVehicles from './ChargersToVehicles';

const Chargers = () => {
  return (
    <>
      <div className="h2 text-center d-none d-print-block">Charging</div>
      <div className="title-for-graphs-and-tables text-center">Vehicle Charging Times</div>
      <p className="takeaway">
        Chargers are most profitable if they can be shared by vehicles. The minimum charging
        equipment for this scenario is shown below.
      </p>
      <VehiclesToChargers />
      <div className="my-5" />
      <ChargersToVehicles />
      <div className="my-5" />
      <MinimumChargingEquipmentTable />
    </>
  );
};

export default Chargers;
