import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {Table} from 'react-bootstrap';
import {formatAsDollars, formatAsDollarsRounded, formatAsYears} from '../../../utils/format';
import {sum, unzip} from 'lodash';
import BreakEven from '../../../graphs/BreakEven';
import TotalCosts from '../../../graphs/TotalCosts';
import calcLcfsCreditsByYear from '../../../calcs/calcLcfsCreditsByYear';
import calcAnnualFossilFuelCost from '../../../calcs/calcAnnualFossilFuelCost';
import calcAnnualElectricityCost from '../../../calcs/calcAnnualElectricityCost';
import calcMaintenanceCostByYear, {
  calcFossilMaintenanceCostByYear,
} from '../../../calcs/calcMaintenanceCostByYear';
import calcAnnualInsuranceCost from '../../../calcs/calcAnnualInsuranceCost';
import calcResaleValue from '../../../calcs/calcResaleValue/calcResaleValue';
import calcElectricTcoByYear from '../../../calcs/calcElectricTcoByYear';
import calcFossilTcoByYear from '../../../calcs/calcFossilTcoByYear';
import calcTcoRoi from '../../../calcs/calcTcoRoi';
import Tooltip from '../../../layout/Tooltip';

const Overview = ({setCurrentTab, incentives}) => {
  const {
    vehicleSets,
    yearsOfOperation,
    gridIntensityInGramsCO2ePerMJ,
    chargers,
    btmConstructionCost,
    btmEvseCost,
    bevRateName,
    bevBlocks,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
  } = useInputs();

  // Vehicle
  const electricVehicleCosts = sum(
    vehicleSets.map(({vehicle, vehicleCount}) => vehicleCount * vehicle.msrp)
  );
  const fossilVehicleCosts = sum(
    vehicleSets.map(({archetype, vehicleCount}) => vehicleCount * archetype.fossilFuelMsrp)
  );
  const allVehiclesHaveKnownPurchaseCosts = vehicleSets
    .map((set) => set.vehicle.msrpIsEstimated === false)
    .every((val) => val === true);

  // Resale
  const electricResaleValues = sum(
    vehicleSets.map(
      ({vehicle, vehicleCount}) =>
        vehicleCount * calcResaleValue(vehicle.msrp, vehicle.duty, yearsOfOperation)
    )
  );
  const fossilResaleValues = sum(
    vehicleSets.map(
      ({archetype, vehicleCount}) =>
        vehicleCount * calcResaleValue(archetype.fossilFuelMsrp, archetype.duty, yearsOfOperation)
    )
  );

  // Fuel
  const annualElectricityCost = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    true
  );
  const annualFossilFuelCost = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  // LCFS
  const lcfsByYear = calcLcfsCreditsByYear(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );
  const lcfsTotal = sum(lcfsByYear.slice(0, yearsOfOperation));

  // Maintenance
  const electricMaintenanceByYear = unzip(
    vehicleSets.map((vehicleSet) => calcMaintenanceCostByYear({vehicleSet, yearsOfOperation}))
  ).map((arr) => sum(arr));

  const fossilMaintenanceByYear = unzip(
    vehicleSets.map((vehicleSet) => calcFossilMaintenanceCostByYear({vehicleSet, yearsOfOperation}))
  ).map((arr) => sum(arr));

  // Insurance
  const annualElectricInsurance = sum(
    vehicleSets.map(({vehicle}) => calcAnnualInsuranceCost(vehicle.msrp))
  );
  const annualFossilInsurance = sum(
    vehicleSets.map(({archetype}) => calcAnnualInsuranceCost(archetype.fossilFuelMsrp))
  );

  // Construction
  const construction = btmConstructionCost + btmEvseCost;

  // Totals
  const electricTcoByYear = calcElectricTcoByYear(
    yearsOfOperation,
    vehicleSets,
    chargers,
    bevRateName,
    bevBlocks,
    gridIntensityInGramsCO2ePerMJ,
    btmConstructionCost,
    btmEvseCost,
    incentives
  );

  const fossilTcoByYear = calcFossilTcoByYear(
    yearsOfOperation,
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const totalElectricTcoComponents = sum([
    electricVehicleCosts,
    -electricResaleValues,
    yearsOfOperation * annualElectricityCost,
    sum(electricMaintenanceByYear),
    yearsOfOperation * annualElectricInsurance,
    -incentives,
    -lcfsTotal,
    construction,
  ]);

  const totalFossilTcoComponents = sum([
    fossilVehicleCosts,
    -fossilResaleValues,
    yearsOfOperation * annualFossilFuelCost,
    sum(fossilMaintenanceByYear),
    yearsOfOperation * annualFossilInsurance,
  ]);

  if (Math.abs(sum(electricTcoByYear) - totalElectricTcoComponents) > 1) {
    console.log(
      `Warning! Electric TCO may not match up. Total should be ${formatAsDollars(
        sum(electricTcoByYear)
      )} but is ${formatAsDollars(totalElectricTcoComponents)}`
    );
  }

  if (Math.abs(sum(fossilTcoByYear) - totalFossilTcoComponents) > 1) {
    console.log(
      `Warning! Fossil TCO may not match up. Total should be ${formatAsDollars(
        sum(fossilTcoByYear)
      )} but is ${formatAsDollars(totalFossilTcoComponents)}`
    );
  }

  const roi = calcTcoRoi(electricTcoByYear, fossilTcoByYear);
  const savings = sum(fossilTcoByYear) - sum(electricTcoByYear);

  return (
    <>
      <div className="h2 text-center d-none d-print-block">Overview</div>
      <p className="takeaway">
        {roi === null ? (
          'A breakeven point does not exist. The total cost of ownership for the electric vehicle(s) exceeds its fossil vehicle counterpart(s).'
        ) : (
          <span>
            After <strong>{formatAsYears(roi)}</strong>, the cumulative cost of electric vehicles
            becomes cheaper than the cost of an equivalent fossil fuel fleet.
          </span>
        )}
      </p>
      <BreakEven
        electricTcoByYear={electricTcoByYear}
        fossilTcoByYear={fossilTcoByYear}
        yearsOfOperation={yearsOfOperation}
      />
      <hr />
      <p className="takeaway">
        You&apos;re looking at{' '}
        {savings > 0 ? (
          <>
            <strong>{formatAsDollars(savings)}</strong> in <strong>Savings</strong>
          </>
        ) : (
          <>
            <strong>{formatAsDollars(-savings)}</strong> in additional costs
          </>
        )}{' '}
        over the life of the vehicles.
      </p>
      <TotalCosts
        electricFuel={annualElectricityCost * yearsOfOperation}
        fossilFuel={annualFossilFuelCost * yearsOfOperation}
        electricVehicles={electricVehicleCosts - electricResaleValues}
        fossilVehicles={fossilVehicleCosts - fossilResaleValues}
        electricOperations={
          sum(electricMaintenanceByYear) + yearsOfOperation * annualElectricInsurance
        }
        fossilOperations={sum(fossilMaintenanceByYear) + yearsOfOperation * annualFossilInsurance}
        electricIncentives={incentives}
        lcfs={lcfsTotal}
        construction={construction}
      />
      <div className="table-responsive mt-5">
        <Table bordered className="chart-js-styles-for-tables text-right">
          <thead>
            <tr>
              <td colSpan="2"> </td>
              <th className="text-center">Electric Vehicle(s)</th>
              <th className="text-center">Fossil Vehicle(s)</th>
              <th className="text-center">EV Savings</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left" colSpan="2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => setCurrentTab('Fuel')}
                >
                  Fuel
                </button>
              </td>
              <td>
                <Tooltip>
                  {formatAsDollars(annualElectricityCost)} per year for {yearsOfOperation} years.
                </Tooltip>{' '}
                {formatAsDollars(annualElectricityCost * yearsOfOperation)}
              </td>
              <td>
                <Tooltip>
                  {formatAsDollars(annualFossilFuelCost)} per year for {yearsOfOperation} years.
                </Tooltip>{' '}
                {formatAsDollars(annualFossilFuelCost * yearsOfOperation)}
              </td>
              <td>
                {formatAsDollars(yearsOfOperation * (annualFossilFuelCost - annualElectricityCost))}
              </td>
            </tr>
            <tr>
              <td className="text-left" rowSpan="2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => setCurrentTab('Vehicles')}
                >
                  Vehicles
                </button>
              </td>
              <td className="text-left">Purchase</td>
              <td>
                {!allVehiclesHaveKnownPurchaseCosts && (
                  <Tooltip>Some EV purchase costs are estimated.</Tooltip>
                )}{' '}
                {formatAsDollars(electricVehicleCosts)}
              </td>
              <td>
                <Tooltip>Fossil vehicle purchase costs are estimated.</Tooltip>{' '}
                {formatAsDollars(fossilVehicleCosts)}
              </td>
              <td>{formatAsDollars(fossilVehicleCosts - electricVehicleCosts)}</td>
            </tr>
            <tr>
              <td className="text-left">Resale</td>
              <td>{formatAsDollars(-electricResaleValues)}</td>
              <td>{formatAsDollars(-fossilResaleValues)}</td>
              <td>{formatAsDollars(-fossilResaleValues + electricResaleValues)}</td>
            </tr>
            <tr>
              <td className="text-left" rowSpan="2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => setCurrentTab('Operations')}
                >
                  Operations
                </button>
              </td>
              <td className="text-left">Maintenance</td>
              <td>
                <Tooltip>
                  First year electric maintenance costs are estimated to be{' '}
                  {formatAsDollars(electricMaintenanceByYear[0])}, and increase over time.
                </Tooltip>{' '}
                {formatAsDollars(sum(electricMaintenanceByYear))}
              </td>
              <td>
                <Tooltip>
                  First year fossil maintenance costs are estimated to be{' '}
                  {formatAsDollars(fossilMaintenanceByYear[0])}, and drastically increase over time.
                </Tooltip>{' '}
                {formatAsDollars(sum(fossilMaintenanceByYear))}
              </td>
              <td>
                {formatAsDollars(sum(fossilMaintenanceByYear) - sum(electricMaintenanceByYear))}
              </td>
            </tr>
            <tr>
              <td className="text-left">Insurance</td>
              <td>
                <Tooltip>
                  {formatAsDollars(annualElectricInsurance)} per year for {yearsOfOperation} years.
                </Tooltip>{' '}
                {formatAsDollars(annualElectricInsurance * yearsOfOperation)}
              </td>
              <td>
                <Tooltip>
                  {formatAsDollars(annualFossilInsurance)} per year for {yearsOfOperation} years.
                </Tooltip>{' '}
                {formatAsDollars(annualFossilInsurance * yearsOfOperation)}
              </td>
              <td>
                {formatAsDollars(
                  yearsOfOperation * (annualFossilInsurance - annualElectricInsurance)
                )}
              </td>
            </tr>
            <tr>
              <td className="text-left" colSpan="2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => setCurrentTab('Grants')}
                >
                  Grants
                </button>{' '}
                <Tooltip>
                  Grants are automatically populated from the Grants tab, and depend on the inputs
                  on the left-hand side of the page.
                </Tooltip>
              </td>

              <td>{formatAsDollars(-incentives)}</td>
              <td>N/A</td>
              <td>{formatAsDollars(incentives)}</td>
            </tr>
            <tr>
              <td className="text-left" colSpan="2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => setCurrentTab('LCFS')}
                >
                  LCFS
                </button>
              </td>
              <td>
                <Tooltip>
                  First year LCFS revenues are estimated to be {formatAsDollars(lcfsByYear[0])}, and
                  slightly decrease over time.
                </Tooltip>{' '}
                {formatAsDollars(-lcfsTotal)}
              </td>
              <td>N/A</td>
              <td>{formatAsDollars(lcfsTotal)}</td>
            </tr>
            <tr>
              <td className="text-left" colSpan="2">
                Construction
              </td>
              <td>
                <Tooltip>The sum of Construction costs as input on the left-hand panel.</Tooltip>{' '}
                {formatAsDollars(construction)}
              </td>
              <td>N/A</td>
              <td>{formatAsDollars(-construction)}</td>
            </tr>
            <tr>
              <td colSpan="2" className="text-left">
                Total
              </td>
              <td>{formatAsDollars(totalElectricTcoComponents)}</td>
              <td>{formatAsDollars(totalFossilTcoComponents)}</td>
              <td>{formatAsDollars(totalFossilTcoComponents - totalElectricTcoComponents)}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="2" className="text-left">
                Total (Rounded)
              </th>
              <th>{formatAsDollarsRounded(totalElectricTcoComponents)}</th>
              <th>{formatAsDollarsRounded(totalFossilTcoComponents)}</th>
              <th>
                {formatAsDollarsRounded(totalFossilTcoComponents - totalElectricTcoComponents)}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
};

export default Overview;
