import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import flatMap from 'lodash/flatMap';
import times from 'lodash/times';
import {formatAsDollarsRounded, formatAsDollars, formatVehicleName} from '../utils/format';
import BaseGraph from './BaseGraph';
import A from '../data/ASSUMPTIONS';
import {
  calcMaintenanceCostByYear,
  calcFossilMaintenanceCostByYear,
} from '../calcs/calcMaintenanceCostByYear';
import uniqueLabels from '../utils/uniqueLabels';

const Maintenance = () => {
  const {vehicleSets, yearsOfOperation} = useInputs();

  const maintenanceCosts = flatMap(vehicleSets, (vehicleSet, i) => [
    {
      label: formatVehicleName(vehicleSet),
      stack: 'Electric',
      order: i,
      yearlyMaintenance: calcMaintenanceCostByYear({vehicleSet, yearsOfOperation}),
    },
    {
      label: `hidden ${formatVehicleName(vehicleSet)} Archetype`,
      stack: 'Fossil',
      order: i,
      yearlyMaintenance: calcFossilMaintenanceCostByYear({vehicleSet, yearsOfOperation}),
    },
  ]);

  const data = {
    labels: times(yearsOfOperation, (year) => `Year ${year + 1}`),
    datasets: uniqueLabels(
      maintenanceCosts.map(({label, stack, order, yearlyMaintenance}) => {
        const stackColors = stack === 'Electric' ? A.ELECTRICITY_SET_COLORS : A.OIL_SET_COLORS;

        return {
          label,
          data: yearlyMaintenance,
          stack,
          backgroundColor: stackColors[order % stackColors.length],
        };
      })
    ),
  };

  return (
    <>
      <BaseGraph
        title="Annual Maintenance Costs"
        data={data}
        stacked
        yFormatCallback={formatAsDollarsRounded}
        tooltipCallback={formatAsDollars}
        options={{
          legend: {labels: {filter: (legendItem) => legendItem.text.indexOf('hidden') !== 0}},
        }}
      />
    </>
  );
};

export default Maintenance;
