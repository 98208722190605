import React from 'react';
import {typography, colors} from '../styles';
import {StarGraph, Plus, Graph} from '../icons';

const Header = ({children}) => (
  <div
    style={{
      fontSize: typography.headers[2],
      fontWeight: typography.weight.bold,
      marginBottom: '40px',
    }}
  >
    {children}
  </div>
);

const BulletPoint = ({icon, children}) => (
  <div
    style={{
      display: 'flex',
      padding: '10px',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        height: '47px',
        width: '47px',
        minWidth: '47px',
        overflow: 'none',
        backgroundColor: colors.blue.base,
        borderRadius: '100%',
        marginRight: '10px',
        display: 'flex',
      }}
    >
      {icon}
    </div>
    <div
      style={{
        fontWeight: typography.weight.bold,
      }}
    >
      {children}
    </div>
  </div>
);

const CreateYourFirstSet = () => {
  return (
    <div className="text-center">
      <Header>Your fuel savings will depend on the vehicles and how you use them</Header>
      <p>Evaluating your entire fleet is a lot to take on. We make it simpler.</p>
      <div style={{margin: 'auto', width: '318px', marginTop: '20px'}}>
        <BulletPoint icon={<StarGraph />}>Add your first vehicle</BulletPoint>
        <BulletPoint icon={<Plus />}>Define your vehicle's behaviors</BulletPoint>
        <BulletPoint icon={<Graph />}>Analyze how much you can save</BulletPoint>
      </div>
    </div>
  );
};

CreateYourFirstSet.header = 'Calculate Your Fuel Savings';

export default CreateYourFirstSet;
