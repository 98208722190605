import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';

const SlideYearsOfOperation = () => {
  const {yearsOfOperation, setInput} = useInputs();

  return (
    <Slider
      label="Years of Operation"
      min={2}
      max={20}
      step={1}
      value={yearsOfOperation}
      onChange={(yearsOfOperation) => setInput({yearsOfOperation})}
    />
  );
};

export default SlideYearsOfOperation;
