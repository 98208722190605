import React, {useState} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from '../layout/Button';
import {typography} from '../styles';
import {DEFAULT_VEHICLE_SET} from '../defaults';
import SelectVehicle from './SelectVehicle';
import EnterVehicleDetails from './EnterVehicleDetails';
import SelectChargingBehaviors from './SelectChargingBehaviors';
import CreateYourFirstSet from './CreateYourFirstSet';
import GreyDiv from '../layout/GreyDiv';
import TrackableVehicleSetButton from './TrackableVehicleSetButton';
import { findArchetype } from '../data/VEHICLES';

const Header = ({children}) => (
  <div
    style={{
      color: '#506272',
      fontSize: typography.cta,
      fontWeight: typography.weight.bold,
      textTransform: 'uppercase',
    }}
  >
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.string,
};

const steps = [CreateYourFirstSet, SelectVehicle, EnterVehicleDetails, SelectChargingBehaviors];

const NewVehicleSetModal = ({isOpen, handleClose, saveAndClose}) => {
  const {vehicles} = useInputs();
  const [currentStep, setCurrentStep] = useState(0);
  const [newVehicleSet, setNewVehicleSet] = useState(DEFAULT_VEHICLE_SET);
  const [vehicle, setVehicle] = useState(
    vehicles.find(({id}) => id === DEFAULT_VEHICLE_SET.vehicle_id)
  );
  const archetype = findArchetype(vehicle);


  const changeParam = (obj) => {
    setNewVehicleSet((current) => ({...current, ...obj}));

    if (obj.vehicle_id) {
      setVehicle(vehicles.find(({id}) => obj.vehicle_id === id));
    }
  };

  const StepComponent = steps[currentStep];
  const hasNextStep = steps.length > currentStep + 1;
  const hasPreviousStep = currentStep > 1;

  if (navigator.userAgent === 'ReactSnap') {
    return null;
  }

  return (
    <Modal show={isOpen} onHide={handleClose} size="lg">
      <Modal.Header closeButton>{StepComponent.header}</Modal.Header>
      <Modal.Body>
        <GreyDiv>
          <StepComponent {...newVehicleSet} {...vehicle} archetype={archetype} changeParam={changeParam} />
        </GreyDiv>
      </Modal.Body>
      <Modal.Footer>
        {hasPreviousStep && (
          <Button type="secondary" onClick={() => setCurrentStep((current) => current - 1)}>
            Back
          </Button>
        )}
        {!hasNextStep && (
          <TrackableVehicleSetButton
            onClick={() => saveAndClose(newVehicleSet)}
            vehicleSet={newVehicleSet}
          >
            Next
          </TrackableVehicleSetButton>
        )}
        {hasNextStep && (
          <Button onClick={() => setCurrentStep((current) => current + 1)}>
            Next: {steps[currentStep + 1].header}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

NewVehicleSetModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  saveAndClose: PropTypes.func,
  discardAndClose: PropTypes.func,
  vehicleSet: PropTypes.object,
};

export default NewVehicleSetModal;
