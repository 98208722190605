import React from 'react';
import PropTypes from 'prop-types';
import {typography} from '../styles';

const Link = ({href, children}) => (
  <a
    style={{
      fontSize: typography.links.primary,
      textDecoration: 'none',
      display: 'block',
    }}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
};

const imageContainerStyle = {
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
};

const Testimonial = ({children, type, href, image, alt}) => (
  <div className="text-left">
    <div style={imageContainerStyle}>
      <img src={image} alt={alt} />
    </div>
    <div
      style={{
        fontSize: typography.headers[5],
        textTransform: 'uppercase',
        fontWeight: typography.weight.bold,
      }}
    >
      {type}
    </div>
    <div
      style={{
        margin: '20px 0',
        fontSize: typography.body.secondary,
      }}
    >
      {children}
    </div>
    <Link href={href}>See Full Case Study →</Link>
  </div>
);

Testimonial.propTypes = {
  children: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
};

export default Testimonial;
