import React from 'react';
import PropTypes from 'prop-types';

const Fuel = ({color = 'currentColor'}) => (
  <svg
    width="62px"
    height="62px"
    viewBox="0 0 62 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="PGE-Fleets_Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Fleets---Savings" transform="translate(-151.000000, -151.000000)">
        <g id="Stats" transform="translate(152.000000, 150.000000)">
          <g id="icn_fuel">
            <g transform="translate(0.000000, 2.000000)">
              <circle id="Oval" stroke={color} strokeWidth="2" cx="30" cy="30" r="30" />
              <g id="noun_Fuel_2714075" transform="translate(15.000000, 14.000000)" fill={color}>
                <path
                  d="M26.46875,27.0828644 L26.46875,29.9310345 L0,29.9310345 L0,27.0828644 L4.12317154,27.0828644 L4.12317154,1.73606617 C4.12317154,0.780678368 4.94353945,0 5.9467531,0 L20.0173426,0 C21.0205563,0 21.8403147,0.780678368 21.8403147,1.73606617 L21.8403147,11.8901088 C23.9143949,11.9905232 25.6989694,12.5088472 25.6989694,15.1596711 L25.6989694,21.1793107 C25.6989694,24.8859368 30.9807735,24.5202659 30.9807735,21.3911677 L30.9807735,13.9895242 L28.7384752,11.8808219 L28.7384752,8.66117665 L24.8755541,4.98241123 C23.9369459,4.08854901 25.3649712,2.72918192 26.3035793,3.62304414 L33,9.99964826 L33,21.3911677 C33,27.0521016 23.6797429,27.4357659 23.6797429,21.1793107 L23.6797429,15.1596711 C23.6797429,14.1056102 22.7801418,13.8449971 21.8403147,13.7898562 L21.8403147,27.0828644 L26.46875,27.0828644 Z M7.07893968,3.20689655 L19.5327292,3.20689655 C19.9141377,3.20689655 20.2258065,3.4944825 20.2258065,3.84584989 L20.2258065,13.2581679 C20.2258065,13.6089658 19.9141377,13.8965517 19.5327292,13.8965517 L7.07893968,13.8965517 C6.69814836,13.8965517 6.38709677,13.6089658 6.38709677,13.2581679 L6.38709677,3.84584989 C6.38709677,3.4944825 6.69814836,3.20689655 7.07893968,3.20689655 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Fuel.propTypes = {
  color: PropTypes.string,
};

export default Fuel;
