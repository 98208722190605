import React from 'react';
import PropTypes from 'prop-types';
import {typography} from '../styles';
import Tooltip from '../layout/Tooltip';

const IconHeader = ({children, header, icon, style, tooltip, headerStyles = {}}) => (
  <div className="my-3 d-flex" style={style}>
    {icon && <div className="mr-3 d-print-none">{icon}</div>}
    <div>
      <div
        style={{
          color: '#6E6E6E',
          fontSize: '12px',
          fontWeight: typography.weight.bolder,
          letterSpacing: '0.5px',
          whiteSpace: 'nowrap',
          ...headerStyles,
        }}
      >
        {header} {tooltip && <Tooltip>{tooltip}</Tooltip>}
      </div>
      <div
        style={{
          fontSize: '40px',
          fontWeight: typography.weight.bold,
          letterSpacing: 0,
          whiteSpace: 'nowrap',
        }}
        className="mobile-fs-24"
      >
        {children}
      </div>
    </div>
  </div>
);

IconHeader.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  icon: PropTypes.node,
  style: PropTypes.object,
};

export default IconHeader;
