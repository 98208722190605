import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Select from '../Select';
import BEV from '../../data/BEV';

const RateComparisonSelectBevRateName = ({maxKw}) => {
  const {
    rateComparisonVehiclesBevRateName,
    rateComparisonChargersBevRateName,
    estimationApproach,
    setInput,
  } = useInputs();
  const subscriptions = BEV.tariff_elements.pge_subscription_rate.filter(
    ({maxApplicableKwh}) => !maxApplicableKwh || maxKw < maxApplicableKwh
  );
  const rateName =
    estimationApproach === 'Vehicles'
      ? rateComparisonVehiclesBevRateName
      : rateComparisonChargersBevRateName;

  const currentSubscription = subscriptions.find((subscription) => subscription.name === rateName);

  return (
    <>
      <Select
        label="BEV Rate Option"
        value={rateName}
        optionValues={subscriptions.map((subscription) => subscription.name)}
        onChange={(bevRateName) => {
          setInput(
            estimationApproach === 'Vehicles'
              ? {rateComparisonVehiclesBevRateName: bevRateName}
              : {rateComparisonChargersBevRateName: bevRateName}
          );
        }}
      />
      {currentSubscription && <p className="small mt-2">{currentSubscription.helpText}</p>}
    </>
  );
};

export default RateComparisonSelectBevRateName;
