import crypto from 'isomorphic-webcrypto';
import {fromBuffer} from 'hex-lite';

const SHOULD_USE_MOCK = process.env.NODE_ENV === 'development';

const feedbackService = async (data) => {
  const payload = Buffer.from(JSON.stringify(data)).toString('base64');
  const hexDigest = await crypto.subtle
    .digest(
      {name: 'SHA-1'},
      Uint8Array.from(payload, (x) => x.charCodeAt(0))
    )
    .then((hash) => fromBuffer(hash));

  const feedback = {
    service: process.env.REACT_APP_PGE_SERVICE_ID,
    payload: [payload],
    checksum: `-Sha1-${hexDigest}`,
  };

  let json;
  if (SHOULD_USE_MOCK) {
    await new Promise((resolve) =>
      setTimeout(() => {
        console.log('Feedback is:', feedback);
        json = 'ok';
        resolve();
      }, 2000)
    );
  } else {
    const url = new URL(process.env.REACT_APP_PGE_BACKEND_HOST);

    const response = await window.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedback),
    });
    json = await response.json();
  }

  return json;
};

export default feedbackService;
