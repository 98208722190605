import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {Row, Col, Alert} from 'react-bootstrap/';
import {useInputs} from '@bellawatt/use-inputs';
import Button from '../layout/Button';
import GreyDiv from '../layout/GreyDiv';
import {Trash} from '../icons';
import SlideVehicleCount from '../inputs/components/SlideVehicleCount';
import SlideMilesPerWorkday from '../inputs/components/SlideMilesPerWorkday';
import SlideHoursPerWorkday from '../inputs/components/SlideHoursPerWorkday';
import ChooseWorkdays from '../inputs/components/ChooseWorkdays';
import SelectChargingBehaviors from './SelectChargingBehaviors';
import calcBatterySchedule from '../calcs/calcBatterySchedule/calcBatterySchedule';
import {findArchetype} from '../data/VEHICLES';
import {formatAsPercentRounded, formatVehicleName} from '../utils/format';
import TrackableVehicleSetButton from './TrackableVehicleSetButton';
import SelectVehicle from './SelectVehicle';
import SelectVehicleCustom from './SelectVehicleCustom';
import VehicleCatalogModal from './VehicleCatalogModal';
import {DEFAULT_VEHICLE_SET} from '../defaults';

const NewVehicleSetModal = ({isOpen, handleClose, vehicleSet, saveAndClose, discardAndClose}) => {
  const [newVehicleSet, setNewVehicleSet] = useState({...DEFAULT_VEHICLE_SET, ...vehicleSet});
  const [isCustomVehicle, setIsCustomVehicle] = useState(false);
  const [viewingVehicleCatalog, setIsViewingVehicleCatalog] = useState(false);
  const {vehicles, chargers} = useInputs();
  const {
    vehicleCount,
    milesPerWorkday,
    hoursPerWorkday,
    workdays,
    chargingWindows,
    vehicle_id,
  } = newVehicleSet;
  const vehicle = vehicles.find(({id}) => id === vehicle_id);
  const archetype = findArchetype(vehicle);
  const vehicleIsOnRoad = vehicle.category === 'On-Road';

  const changeParam = (obj) => {
    setNewVehicleSet((current) => ({...current, ...obj}));
  };

  const {minStateOfCharge} = calcBatterySchedule(chargers, {...newVehicleSet, vehicle});

  return (
    <>
      <VehicleCatalogModal
        isOpen={viewingVehicleCatalog}
        handleClose={() => setIsViewingVehicleCatalog(false)}
        selectVehicle={({id}) => changeParam({vehicle_id: id})}
      />
      <Modal animation={false} show={isOpen} onHide={handleClose} size="lg">
        <Modal.Header closeButton style={{borderBottom: 0}}>
          <p className="h5">{formatVehicleName({...newVehicleSet, vehicle})}</p>
        </Modal.Header>
        <Modal.Body style={{padding: '0 2rem'}}>
          <GreyDiv collapsible header="Select A Vehicle">
            {isCustomVehicle ? (
              <SelectVehicleCustom
                defaultVehicle={vehicle}
                changeParam={changeParam}
                cancel={() => setIsCustomVehicle(false)}
              />
            ) : (
              <>
                <SelectVehicle changeParam={changeParam} archetype={archetype} {...vehicle} />

                <button
                  onClick={() => setIsViewingVehicleCatalog(true)}
                  className="btn btn-link"
                  style={{fontSize: '80%'}}
                  type="button"
                >
                  See available vehicle catalog &gt;
                </button>
                <div className="text-right">
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => setIsCustomVehicle(true)}
                  >
                    + Add Custom Vehicle
                  </button>
                </div>
              </>
            )}
          </GreyDiv>

          <GreyDiv collapsible header="Enter Vehicle Details">
            <Row>
              <Col xs="12" sm>
                <SlideVehicleCount
                  value={vehicleCount}
                  onChange={(vehicleCount) => changeParam({vehicleCount})}
                />
              </Col>
              <Col xs="12" sm>
                {vehicleIsOnRoad && (
                  <SlideMilesPerWorkday
                    value={milesPerWorkday}
                    onChange={(milesPerWorkday) => changeParam({milesPerWorkday})}
                  />
                )}
                {!vehicleIsOnRoad && (
                  <SlideHoursPerWorkday
                    value={hoursPerWorkday}
                    onChange={(hoursPerWorkday) => changeParam({hoursPerWorkday})}
                  />
                )}
              </Col>

              <Col sm={12}>
                <ChooseWorkdays value={workdays} onChange={(workdays) => changeParam({workdays})} />
              </Col>
            </Row>
          </GreyDiv>
          {milesPerWorkday > vehicle.rangeInMiles && (
            <Alert variant="warning">
              <strong>Warning!!</strong> The miles per day are greater than the range for this
              vehicle. This is sustainable with multiple charging windows.
            </Alert>
          )}
          <GreyDiv
            collapsible
            header="Charging Behavior Per Vehicle"
            tooltip="The Business EV Rate is a time-of-use rate for business customers with on-site EV charging. Charging is most expensive between 4pm and 9pm, and least expensive from 9a to 2pm."
          >
            <p className="small">
              When will the {vehicleCount} vehicle{vehicleCount === '1' ? '' : 's'} be parked and
              available to charge? This application computes the minimum chargers needed to recharge
              the fleet, depending on the charging levels chosen below.
            </p>
            <SelectChargingBehaviors chargingWindows={chargingWindows} changeParam={changeParam} />
          </GreyDiv>

          {minStateOfCharge > 0.2 && (
            <Alert variant="primary">
              With this configuration, the minimum state of charge across the week will be{' '}
              <strong>{formatAsPercentRounded(minStateOfCharge)}</strong>.
            </Alert>
          )}
          {minStateOfCharge > 0 && minStateOfCharge < 0.2 && (
            <Alert variant="warning">
              With this configuration, the minimum state of charge across the week will be{' '}
              <strong>{formatAsPercentRounded(minStateOfCharge)}</strong>. Are you sure the charging
              times, charging level(s), and daily miles are a conservative estimate?
            </Alert>
          )}
          {minStateOfCharge <= 0 && (
            <Alert variant="danger">
              <strong>Warning!!</strong> With this configuration, the batteries will be depleted.
              Please add more charging times, increase the charging level(s), and/or decrease the
              daily miles.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row style={{width: '100%'}}>
            <Col className="text-left mb-3 mb-sm-0" sm="6" xs="12">
              <Button
                type="link"
                style={{paddingLeft: 0}}
                onClick={() => discardAndClose(newVehicleSet)}
              >
                <Trash /> Delete Vehicle Set
              </Button>
            </Col>
            <Col className="text-right">
              <Button style={{width: '100%'}} type="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <TrackableVehicleSetButton
                onClick={() => saveAndClose(newVehicleSet)}
                vehicleSet={newVehicleSet}
              >
                Save
              </TrackableVehicleSetButton>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NewVehicleSetModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  saveAndClose: PropTypes.func,
  discardAndClose: PropTypes.func,
  vehicleSet: PropTypes.object,
};

export default NewVehicleSetModal;
