import React, {useState, useEffect} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import {Row, Col, Tabs, Tab} from 'react-bootstrap';
import PageLayout from '../../layout/PageLayout';
import Inputs from './components/Inputs';
import IconsHeader from './components/IconsHeader';
import Financial from './components/Financial';
import Chargers from './components/Chargers';
import Electricity from './components/Electricity';
import Vehicles from './components/Vehicles';
import Emissions from './components/Emissions';

import MobileTabs from './components/MobileTabs';
import FirstVehicleModal from '../../components/FirstVehicleModal';
import DisplayVehicleSetAlarms from '../../components/DisplayVehicleSetAlarms';
import {DEFAULT_VEHICLE_SET} from '../../defaults';
import Loading from '../../components/Loading';

const FuelSavingsCalculator = () => {
  const {vehicleSets, setInput, loading} = useInputs();
  const [currentTab, setCurrentTab] = useState('Financial');
  const hasVehicles = vehicleSets && vehicleSets.length > 0;

  // prime the charts so that printing can occur
  useEffect(() => {
    document.body.classList.add('print-prime');

    setTimeout(() => {
      document.body.classList.remove('print-prime');
    }, 250);
  }, [loading]);

  if (loading) {
    return (
      <PageLayout page="Fuel Savings Calculator">
        <Loading />
      </PageLayout>
    )
  }

  return (
    <PageLayout page="Fuel Savings Calculator">
      <Meta>
        <Meta.Title>PG&amp;E Fleets: Fuel Savings Calculator</Meta.Title>
        <Meta.Description>
          Calculate your fuel savings from converting your fleet to electric.
        </Meta.Description>
        <Meta.Image src="/images/home-background.jpg" />
      </Meta>
      <FirstVehicleModal
        isOpen={vehicleSets.length === 0}
        handleClose={() => setInput({vehicleSets: [DEFAULT_VEHICLE_SET]})}
        saveAndClose={(newVehicleSet) => setInput({vehicleSets: [newVehicleSet]})}
      />
      <IconsHeader />
      <Row className="my-5">
        <Col xs={12} md={4} className="inputs">
          <Inputs />
        </Col>
        <Col xs={12} md={8} className="content">
          <DisplayVehicleSetAlarms vehicleSets={vehicleSets}>
            <MobileTabs
              currentTab={currentTab}
              tabs={['Financial', 'Electricity', 'Vehicles', 'Chargers', 'Emissions']}
              onChange={setCurrentTab}
            />
            <Tabs
              transition={false}
              activeKey={currentTab}
              id="fuel-savings-tabs"
              onSelect={(newTab) => setCurrentTab(newTab)}
              className="nav-fill navlink-tab-hover"
            >
              <Tab eventKey="Financial" title="Financial">
                <Financial />
              </Tab>
              <Tab eventKey="Electricity" title="Electricity">
                {hasVehicles && <Electricity overwriteRateInputs />}
              </Tab>
              <Tab eventKey="Vehicles" title="Vehicles">
                <Vehicles />
              </Tab>
              <Tab eventKey="Chargers" title="Chargers">
                {hasVehicles && <Chargers />}
              </Tab>
              <Tab eventKey="Emissions" title="Emissions">
                <Emissions />
              </Tab>
            </Tabs>
          </DisplayVehicleSetAlarms>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default FuelSavingsCalculator;
