import React from 'react';
import PropTypes from 'prop-types';
import {typography} from '../styles';

const Button = ({
  onClick,
  href,
  className,
  style = {},
  children,
  size = 'large',
  type = 'primary',
}) => {
  const btn = (
    <button
      type="button"
      style={{
        fontSize: typography.cta,
        fontWeight: typography.weight.bolder,
        padding: size === 'large' ? '10px 20px' : '1px 7px 2px',
        marginLeft: size === 'large' ? '10px' : 0,
        whiteSpace: 'nowrap',
        borderStyle: type === 'link' ? 'none' : 'solid',
        ...style,
      }}
      onClick={onClick}
      className={[className, `btn btn-${type}`].join(' ')}
    >
      {children}
    </button>
  );

  return href ? <a href={href}>{btn}</a> : btn;
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'faint', 'link']),
  size: PropTypes.oneOf(['large', 'small']),
  style: PropTypes.object,
  href: PropTypes.string,
};

export default Button;
