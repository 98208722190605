import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageLayout from '../layout/PageLayout';
import {Header, Subheader} from '../layout/Headers';
import VehicleCatalogContents from '../components/VehicleCatalog';
import Loading from '../components/Loading';

const VehicleCatalog = () => {
  const {loading} = useInputs();

  return (
    <PageLayout page="Vehicles">
      <Container>
        <Meta>
          <Meta.Title>PG&amp;E Fleets: Vehicles</Meta.Title>
          <Meta.Description>The vehicles available to PG&amp;E Fleets cusomters.</Meta.Description>
          <Meta.Image src="/images/home-background.jpg" />
        </Meta>
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <Header>Vehicle Catalog</Header>
            <Subheader>
              An increasing number of vehicles are available to commercial customers looking to
              electrify their fleet. See the <a href="/tco">Total Costs Calculator</a> for more,
              including how these vehicles could be combined as part of your own fleet
              electrification project.
            </Subheader>
          </Col>
        </Row>
      </Container>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <VehicleCatalogContents
            rowClassName="row row-cols-1 row-cols-md-2 row-cols-lg-4"
            showCount
          />
        </Container>
      )}
    </PageLayout>
  );
};

export default VehicleCatalog;
