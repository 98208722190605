import React from 'react';
import BaseGraph from './BaseGraph';
import {formatAsDollarsRounded, formatAsDollars} from '../utils/format';
import {colors} from '../styles';
import A from '../data/ASSUMPTIONS';
import {useInputs} from '@bellawatt/use-inputs';
import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import calcTotalWeeklyLoadProfile from '../calcs/calcTotalWeeklyLoadProfile';
import calcBevCosts from '../calcs/calcBevCosts/calcBevCosts';
import calcTotalChargerSetLoadProfile from '../calcs/calcTotalChargerSetLoadProfile';
import calcTotalYearlyVehicleSetLoadProfile from '../calcs/calcTotalYearlyVehicleSetLoadProfile';
import getApplicableRates from '../rates';

const RateComparisonChart = () => {
  const {
    bevVoltage,
    bevBlocks,
    vehicleSets,
    chargerSets,
    chargingFacilityType,
    chargers,
    estimationApproach,
    onlyShowApplicableRates,
    rateComparisonVehiclesBevRateName,
    rateComparisonChargersBevRateName,
  } = useInputs();

  const bevRate =
    estimationApproach === 'Vehicles'
      ? rateComparisonVehiclesBevRateName
      : rateComparisonChargersBevRateName;

  const weeklyBevLoadProfile =
    estimationApproach === 'Vehicles'
      ? calcTotalWeeklyLoadProfile(chargers, vehicleSets)
      : calcTotalChargerSetLoadProfile({
          chargerSets,
          timeRange: 'weekly',
          facilityType: chargingFacilityType,
        });
  const bevElectricityCosts = calcBevCosts(weeklyBevLoadProfile, bevRate, bevBlocks, false);

  const yearlyLoadProfile =
    estimationApproach === 'Vehicles'
      ? calcTotalYearlyVehicleSetLoadProfile({vehicleSets, chargers})
      : calcTotalChargerSetLoadProfile({
          chargerSets,
          timeRange: 'yearly',
          facilityType: chargingFacilityType,
        });

  const loadProfile = new LoadProfile(yearlyLoadProfile, {year: A.YEAR});
  const nonBevRates = getApplicableRates(loadProfile, {
    voltage: bevVoltage,
    ignoreMaxKw: !onlyShowApplicableRates,
  });
  const rateCalculators = nonBevRates.map((rate) => new RateCalculator({...rate, loadProfile}));

  const bevLabelSuffix = bevRate.includes('High Use') ? '(High Use)' : '(Low Use)';

  const data = {
    labels: [`BEV ${bevLabelSuffix}`, ...nonBevRates.map(({name}) => name)],
    datasets: [
      {
        label: 'Subscription / Fixed',
        data: [
          bevElectricityCosts.monthlySubscription,
          ...rateCalculators.map(
            (calculator) => calculator.annualCost({classifications: 'fixed'}) / 12
          ),
        ],
        borderWidth: 1,
        backgroundColor: A.COST_SET_COLORS[0],
        borderColor: A.COST_SET_COLORS[0],
      },
      {
        label: 'Demand',
        data: [
          0,
          ...rateCalculators.map(
            (calculator) => calculator.annualCost({classifications: 'demand'}) / 12
          ),
        ],
        borderWidth: 1,
        backgroundColor: colors.brand.darkBlue,
        borderColor: colors.brand.darkBlue,
      },
      {
        label: 'Energy',
        data: [
          bevElectricityCosts.monthlyEnergy,
          ...rateCalculators.map(
            (calculator) => calculator.annualCost({classifications: 'energy'}) / 12
          ),
        ],
        borderWidth: 1,
        backgroundColor: colors.brand.blue,
        borderColor: colors.brand.blue,
      },
      {
        label: 'Overage',
        data: [bevElectricityCosts.monthlyOverage, 0, 0, 0, 0],
        borderWidth: 1,
        backgroundColor: A.COST_OVERAGE_COLOR,
        borderColor: A.COST_OVERAGE_COLOR,
      },
    ],
  };

  return (
    <BaseGraph
      title="Monthly Rate Comparison - BEV and Commercial Rates"
      data={data}
      stacked
      yFormatCallback={formatAsDollarsRounded}
      tooltipCallback={(amount, category) => `${category}: ${formatAsDollars(amount)}`}
    />
  );
};

export default RateComparisonChart;
