import React from 'react';
import moment from 'moment';
import {Table} from 'react-bootstrap';
import {useInputs} from '@bellawatt/use-inputs';
import {isEqual} from 'lodash';
import VehicleImage from './VehicleImage';
import {formatAsDollarsRounded, formatVehicleName} from '../utils/format';

const VehiclesWithTheirChargersTable = ({includeMSRP}) => {
  const {vehicleSets} = useInputs();

  return (
    <>
      <p className="title-for-graphs-and-tables">Vehicle Set Characteristics</p>
      <Table bordered className="chart-js-styles-for-tables">
        <thead>
          <tr className="text-center">
            <td> </td>
            {vehicleSets.map((set, idx) => (
              <th key={idx}>{formatVehicleName(set)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Representative Image</th>
            {vehicleSets.map((set, idx) => (
              <td key={idx} className="text-center" style={{maxWidth: '200px'}}>
                <VehicleImage vehicle={set.vehicle} />
              </td>
            ))}
          </tr>
          {includeMSRP && (
            <tr>
              <th>Vehicle Cost</th>
              {vehicleSets.map(({vehicle: {id, msrp, msrpIsEstimated}}) => (
                <td key={id}>
                  {formatAsDollarsRounded(msrp)}
                  {!(msrpIsEstimated === false) && (
                    <small>
                      <br />
                      (estimated)
                    </small>
                  )}
                </td>
              ))}
            </tr>
          )}
          <tr>
            <th>Vehicle Range</th>
            {vehicleSets.map((set, idx) => {
              const {vehicle} = set;

              return (
                <td key={idx}>
                  {vehicle.category === 'On-Road'
                    ? `${vehicle.rangeInMiles} miles`
                    : `${vehicle.rangeInHours} hours`}
                </td>
              );
            })}
          </tr>
          <tr>
            <th>Vehicle Battery Capacity</th>
            {vehicleSets.map((set, idx) => (
              <td key={idx}>{set.vehicle.batteryCapacityInKwh} kWh</td>
            ))}
          </tr>
          <tr>
            <th>Workdays</th>
            {vehicleSets.map((set, idx) => (
              <td key={idx}>
                {isEqual(set.workdays, [1, 2, 3, 4, 5])
                  ? 'Weekdays'
                  : set.workdays.map((day) => moment(day, 'd').format('ddd')).join(', ')}
              </td>
            ))}
          </tr>
          <tr>
            <th>Typical Workday</th>
            {vehicleSets.map((set, idx) => (
              <td key={idx}>{set.milesPerWorkday} miles</td>
            ))}
          </tr>
          <tr>
            <th>Charging Windows</th>
            {vehicleSets.map((set, idx) => (
              <td key={idx}>
                {set.chargingWindows.map((chargingWindow, idx) => (
                  <dl key={idx}>
                    <dt>
                      {`${moment(chargingWindow.start, 'H').format('ha')} to ${moment(
                        chargingWindow.finish,
                        'H'
                      ).format('ha')}`}
                    </dt>
                    <dd style={{marginBottom: 0}}>{chargingWindow.type}</dd>
                    <dd>Approach: {chargingWindow.chargingApproach}</dd>
                  </dl>
                ))}
              </td>
            ))}
          </tr>
          <tr>
            <th>Equivalent Fossil Vehicle</th>
            {vehicleSets.map((set, idx) => {
              const {vehicle} = set;
              return (
                <td key={idx}>
                  <dl>
                    <dt>Efficiency (MPG)</dt>
                    <dd>
                      {vehicle.category === 'On-Road'
                        ? `${vehicle.fossilMilesPerGallon} miles per gallon ${vehicle.fossilFuelType}`
                        : `${vehicle.fossilGallonsPerHour} gallons ${vehicle.fossilFuelType} per hour`}
                    </dd>
                  </dl>
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default VehiclesWithTheirChargersTable;
