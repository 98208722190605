import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';

const SlideLpgPrice = () => {
  const {lpgPrice, setInput} = useInputs();

  return (
    <Slider
      label="LPG Price ($/Gal)"
      min={1}
      max={10}
      step={0.1}
      value={lpgPrice}
      onChange={(lpgPrice) => setInput({lpgPrice})}
    />
  );
};

export default SlideLpgPrice;
