import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';

const TrackableLink = ({
  children,
  event,
  category = 'Clicked Link',
  target,
  href,
  ...linkProps
}) => {
  const sendEvent = (e) => {
    e.preventDefault();

    ReactGA.event({
      category,
      action: event,
    });

    if (target === '_blank') {
      window.open(href, target);
    } else {
      window.location.href = href;
    }
  };

  return (
    <a {...linkProps} href={href} onClick={sendEvent}>
      {children}
    </a>
  );
};

TrackableLink.propTypes = {
  children: PropTypes.node,
  event: PropTypes.string,
  category: PropTypes.string,
  target: PropTypes.string,
  href: PropTypes.string,
};

export default TrackableLink;
