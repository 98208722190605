import React, {useState, useEffect} from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import {Row, Col, Tabs, Tab} from 'react-bootstrap';
import PageLayout from '../../layout/PageLayout';
import Inputs from './components/Inputs';
import IconsHeader from './components/IconsHeader';
import Overview from './components/Overview';
import LCFS from './components/LCFS';
import Fuel from './components/Fuel';
import Vehicles from './components/Vehicles';
import Incentives from './components/Incentives';
import Operations from './components/Operations';
import useEvaluatedApiIncentives from '../../utils/useEvaluatedApiIncentives';
import MobileTabs from './components/MobileTabs';
import FirstVehicleModal from '../../components/FirstVehicleModal';
import DisplayVehicleSetAlarms from '../../components/DisplayVehicleSetAlarms';
import {DEFAULT_VEHICLE_SET} from '../../defaults';
import Loading from '../../components/Loading';

const ApiLoadable = ({loading, error, children}) => {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <p className="takeaway mt-5">
        An error occurred while processing incentives. Please try again later.
      </p>
    )
  }

  return children;
}

const TotalCostOfOwnership = () => {
  const {vehicleSets, setInput, loading} = useInputs();
  const {loading: incentiveApiLoading, error: incentiveApiError, evaluatedIncentives} = useEvaluatedApiIncentives();
  
  const [currentTab, setCurrentTab] = useState('Overview');
  const hasVehicles = vehicleSets && vehicleSets.length > 0;

  const incentives = evaluatedIncentives.reduce((sum, {estimated_amount}) => sum + estimated_amount, 0);

  // prime the charts so that printing can occur
  useEffect(() => {
    document.body.classList.add('print-prime');

    setTimeout(() => {
      document.body.classList.remove('print-prime');
    }, 250);
  }, [loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTab]);

  if (loading) {
    return (
      <PageLayout page="Total Cost of Ownership">
        <Loading />
      </PageLayout>
    );
  }

  return (
    <PageLayout page="Total Cost of Ownership">
      <Meta>
        <Meta.Title>PG&amp;E Fleets: Total Cost of Ownership</Meta.Title>
        <Meta.Description>
          Calculate your fuel savings from converting your fleet to electric.
        </Meta.Description>
        <Meta.Image src="/images/home-background.jpg" />
      </Meta>
      <FirstVehicleModal
        isOpen={vehicleSets.length === 0}
        handleClose={() => setInput({vehicleSets: [DEFAULT_VEHICLE_SET]})}
        saveAndClose={(newVehicleSet) => setInput({vehicleSets: [newVehicleSet]})}
      />
      <IconsHeader incentives={incentives} loading={incentiveApiLoading}/>
      <Row className="my-5">
        <Col xs={12} md={4} className="inputs">
          <Inputs />
        </Col>
        <Col xs={12} md={8} className="content">
          <DisplayVehicleSetAlarms vehicleSets={vehicleSets}>
            <MobileTabs
              currentTab={currentTab}
              tabs={['Overview', 'Fuel', 'Vehicles', 'Operations', 'Grants', 'LCFS']}
              onChange={setCurrentTab}
            />
            <Tabs
              transition={false}
              activeKey={currentTab}
              id="tco-tabs"
              onSelect={(newTab) => setCurrentTab(newTab)}
              className="nav-fill navlink-tab-hover"
            >
              <Tab eventKey="Overview" title="Overview">
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  <Overview setCurrentTab={setCurrentTab} incentives={incentives} />
                </ApiLoadable>
              </Tab>
              <Tab eventKey="Fuel" title="Fuel">
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  {hasVehicles && <Fuel overwriteRateInputs />}
                </ApiLoadable>
              </Tab>
              <Tab eventKey="Vehicles" title="Vehicles">
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  <Vehicles />
                </ApiLoadable>
              </Tab>
              <Tab eventKey="Operations" title="Operations">
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  {hasVehicles && <Operations />}
                </ApiLoadable>
              </Tab>
              <Tab eventKey="Grants" title="Grants"> 
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  <Incentives evaluatedIncentives={evaluatedIncentives} />
                </ApiLoadable>
              </Tab>
              <Tab eventKey="LCFS" title="LCFS">
                <ApiLoadable loading={incentiveApiLoading} error={incentiveApiError}>
                  <LCFS />
                </ApiLoadable>
              </Tab>
            </Tabs>
          </DisplayVehicleSetAlarms>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default TotalCostOfOwnership;
