import React from 'react';
import CustomVehicle from '../components/CustomVehicle';

const VehicleImage = ({vehicle}) => {
  const hasImage = vehicle.image_filename || vehicle.imageUrl;

  return hasImage ? (
    <img
      style={{width: '180px'}}
      className="img-fluid"
      src={vehicle.imageUrl ? vehicle.imageUrl : `/images/vehicles/${vehicle.image_filename}.png`}
      alt={`${vehicle.type} ${vehicle.subtype}`}
    />
  ) : (
    <CustomVehicle />
  );
};

export default VehicleImage;
