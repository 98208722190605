import ASSUMPTIONS from './data/ASSUMPTIONS';
import {findArchetype} from './data/VEHICLES';
import {get} from './utils/api';
import {typeConversions, passengerSubtypeConversions} from './utils/conversions';
import TIME_PERIODS from './utils/timePeriods';

export const DEFAULT_ADDITIONAL_CHARGING_WINDOW = {
  start: 12,
  finish: 15,
  chargingApproach: 'Max',
  type: 'DCFC - 50kW',
};

export const DEFAULT_CHARGING_WINDOW = {
  start: 21,
  finish: 5,
  chargingApproach: 'Even',
  type: 'Level 2 - 7.7kW [Dual Port]',
};

export const DEFAULT_VEHICLE_SET = {
  idx: -1,
  vehicle_id: 'on_road_cargo_van_medium_duty',
  vehicleCount: 3,
  milesPerWorkday: 100,
  hoursPerWorkday: 8,
  workdays: [1, 2, 3, 4, 5],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
};

export const DEFAULT_CHARGER_SET = {
  idx: -1,
  numChargersInstalled: 10,
  chargerLevelInstalled: 'Level 2 - 7.7kW',
  workdays: [1, 2, 3, 4, 5],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargersPerTimePeriod: TIME_PERIODS.reduce(
    (accum, currPeriod) => ({
      ...accum,
      [currPeriod.key]: 5,
    }),
    {}
  ),
};

const defaults = {
  kwhDisplacedForLcfs: 100000,
  gridIntensityInGramsCO2ePerMJ: ASSUMPTIONS.GRID_INTENSITY_PATHWAYS.values[0],
  estimationApproach: 'Vehicles',
  vehicleSets: [],
  chargerSets: [DEFAULT_CHARGER_SET],
  chargingFacilityType: 'Custom',
  onlyShowApplicableRates: true,
  bevRateName: 'Business High Use EV',
  // only used on rate comparison page, avoid conflicts with `bevRateName` due to different estimation approaches used (chargers vs. vehicles)
  rateComparisonVehiclesBevRateName: 'Business High Use EV',
  rateComparisonChargersBevRateName: 'Business Low Use EV',
  bevVoltage: 'Secondary',
  bevBlocks: 5,
  dieselPrice: 3.1,
  gasolinePrice: 3.3,
  lpgPrice: 3.5,
  yearsOfOperation: 10,
  btmConstructionCost: 10000,
  btmEvseCost: 10000,
  customVehicles: [],
  customChargers: [],
  isTaxExempt: 'Yes',
  isDAC: 'No',
  isPublic: 'No',
  replacesIceVehicle: 'Yes',
  organizationType: 'private_school_districts',
  zipCode: '94108',
};

export const getDefaultsWithApi = async () => {
  const apiVehicles = (await get('/vehicles?postcode=94108')).data.vehicles
    .filter((vehicle) => vehicle.fuel !== 'PHEV')
    .map((vehicle) => ({
      fromApi: true,
      id: vehicle.handle,
      category: vehicle.form_factor === 'tru' ? 'TRU' : vehicle.is_offroad ? 'Off-Road' : 'On-Road',
      name: `${vehicle.make} ${vehicle.model} ${vehicle.trim}`.trim(),
      type: typeConversions[vehicle.form_factor] || vehicle.form_factor,
      subtype:
        vehicle.type === 'Passenger'
          ? passengerSubtypeConversions[vehicle.form_factor]
          : vehicle.subtype,
      formFactor: vehicle.form_factor,
      make: vehicle.make,
      year: vehicle.model_year,
      model: vehicle.model,
      duty: vehicle.duty,
      trim: vehicle.trim,
      imageUrl: vehicle.images.find(({url_thumbnail}) => url_thumbnail)?.url_full || null,
      rangeInMiles: vehicle.total_range,
      rangeInHours: vehicle.total_range,
      range: vehicle.total_range,
      fuel: vehicle.fuel,
      liftCapacity: vehicle.lift_capacity || 0,
      batteryCapacityInKwh: vehicle.battery_capacity,
      weightClass: vehicle.weight_class,
      msrp: vehicle.msrp,
      msrpIsEstimated: vehicle.is_estimated_price,
      electricEfficiency: vehicle.electric_efficiency,
      electricEfficiencyUnit: vehicle.electric_efficiency_unit,
      maintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost,
      maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0,
      eligibleForCaRebate:
        typeof vehicle.eligible_for_ca_rebate !== 'string'
          ? vehicle.eligible_for_ca_rebate
          : vehicle.eligible_for_ca_rebate.toLowerCase() === 'yes',
    }))
    .filter((vehicle) => findArchetype(vehicle));

  return {
    ...defaults,
    apiVehicles,
  };
};

export default defaults;
