import calcTotalWeeklyLoadProfile from './calcs/calcTotalWeeklyLoadProfile';
import calcTotalKwh from './calcs/calcTotalKwh';
import calcIdealBevRateName from './calcs/calcIdealBevRateName';
import groupByChargers from './calcs/groupByChargers';
import sumBy from 'lodash/sumBy';
import calcTotalChargerSetLoadProfile from './calcs/calcTotalChargerSetLoadProfile';

export default {
  vehicleSets: ({vehicleSets, chargers}) => {
    const weeklyLoadProfile = calcTotalWeeklyLoadProfile(chargers, vehicleSets);
    const roundedKwh = Math.round(calcTotalKwh(chargers, vehicleSets) / 10000) * 10000;
    const idealRateName = calcIdealBevRateName(weeklyLoadProfile);

    const chargingSets = groupByChargers(vehicleSets);
    const minBtmEvseCost = sumBy(
      chargingSets,
      ({estimatedCost, minimumRequired}) => estimatedCost * minimumRequired
    );

    return {
      kwhDisplacedForLcfs: roundedKwh,
      bevRateName: idealRateName,
      btmEvseCost: minBtmEvseCost,
      rateComparisonVehiclesBevRateName: idealRateName,
    };
  },
  chargerSets: ({chargerSets, chargingFacilityType}) => {
    const weeklyLoadProfile = calcTotalChargerSetLoadProfile({
      chargerSets,
      timeRange: 'weekly',
      facilityType: chargingFacilityType,
    });

    const idealRateName = calcIdealBevRateName(weeklyLoadProfile);
    return {
      rateComparisonChargersBevRateName: idealRateName,
    };
  },
};
