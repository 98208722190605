import {useInputs} from '@bellawatt/use-inputs';
import React, {useState} from 'react';
import uniq from 'lodash/uniq';
import SelectVehicleCategory from '../inputs/components/SelectVehicleCategory';
import Select from '../inputs/Select';
import VehicleCard from './VehicleCard';
import Slider from '../inputs/Slider';
import startCase from 'lodash/startCase';

const VehicleCatalog = ({onClick, rowClassName, showCount}) => {
  const {apiVehicles} = useInputs();
  const [category, baseSetCategory] = useState(SelectVehicleCategory.options[0]);
  const [make, setMake] = useState('All');
  const [range, setRange] = useState(0);
  const [formFactor, baseSetFormFactor] = useState('All');

  const isOnRoad = category === 'On-Road';

  const filteredFormFactors = [
    'All',
    ...uniq(
      apiVehicles.filter((vehicle) => vehicle.category === category)
      .map(({formFactor}) => startCase(formFactor))
      .sort()
    )
  ];

  const filteredMakes = [
    'All',
    ...uniq(
      apiVehicles
        .filter((vehicle) => vehicle.category === category)
        .map(({make}) => make)
        .sort()
    ),
  ];

  const setFormFactor = (newFormFactor) => {
    baseSetFormFactor(newFormFactor);
    setMake('All');
  }

  const setCategory = (newCategory) => {
    baseSetCategory(newCategory);
    setMake('All');
    setFormFactor('All');
    setRange(0);
  };

  const filteredVehicles = apiVehicles.filter(
    (vehicle) =>
      vehicle.category === category &&
      (make === 'All' || vehicle.make === make) &&
      (formFactor === 'All' || startCase(vehicle.formFactor) === formFactor) &&
      vehicle.range > range
  );

  return (
    <>
      <div className={`mb-4 ${rowClassName}`}>
        <div className="col">
          <SelectVehicleCategory value={category} onChange={setCategory} />
        </div>

        <div className="col">
          <Select
            label="Vehicle Type"
            value={formFactor}
            optionValues={filteredFormFactors}
            onChange={setFormFactor}
          />
        </div>

        <div className="col">
          <Select
            label="Vehicle Make"
            value={make}
            onChange={setMake}
            optionValues={filteredMakes}
          />
        </div>

        <div className="col">
          <Slider
            value={range}
            label={`Vehicle Range (${isOnRoad ? 'miles' : 'hours'})`}
            min={isOnRoad ? 50 : 1}
            max={isOnRoad ? 400 : 50}
            step={isOnRoad ? 5 : 0.5}
            onChange={setRange}
          />
        </div>
      </div>

      {showCount && (
        <p className="h3 my-5 text-center">
          Showing <strong>{filteredVehicles.length}</strong> of{' '}
          <strong>{apiVehicles.length}</strong> vehicles.
        </p>
      )}

      <div className={rowClassName}>
        {filteredVehicles.map((vehicle) =>
          onClick ? (
            <button
              className="col p-1 btn"
              onClick={() => onClick(vehicle)}
              key={vehicle.id}
              type="button"
            >
              <VehicleCard {...vehicle} />
            </button>
          ) : (
            <div className="col" key={vehicle.id}>
              <VehicleCard {...vehicle} />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default VehicleCatalog;
