import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import sumBy from 'lodash/sumBy';
import Slider from '../Slider';
import {formatAsDollars} from '../../utils/format';
import groupByChargers from '../../calcs/groupByChargers';

const SlideBtmEvseCost = () => {
  const {btmEvseCost, vehicleSets, setInput} = useInputs();

  const chargingSets = groupByChargers(vehicleSets);

  return (
    <>
      <Slider
        label="EVSE (Charger) Cost"
        min={0}
        max={1000000}
        step={10000}
        valFormat={formatAsDollars}
        value={btmEvseCost}
        onChange={(btmEvseCost) => setInput({btmEvseCost: parseInt(btmEvseCost)})}
      />
      <p>
        <small>
          The estimated cost of your minimum chargers is{' '}
          <strong>
            {formatAsDollars(
              sumBy(
                chargingSets,
                ({estimatedCost, minimumRequired}) => estimatedCost * minimumRequired
              )
            )}
          </strong>
          . This is derived from
          {chargingSets.length === 1 ? (
            ` ${chargingSets[0].minimumRequired}x ${chargingSets[0].type} ${
              chargingSets[0].minimumRequired === 1
                ? '.'
                : `that each cost ${formatAsDollars(chargingSets[0].estimatedCost)}.`
            }`
          ) : (
            <>
              :
              <ul>
                {chargingSets.map(({type, minimumRequired, estimatedCost}) => (
                  <li key={type}>
                    {minimumRequired}x {type} that {minimumRequired === 1 ? 'costs' : 'each cost'}{' '}
                    {formatAsDollars(estimatedCost)}
                  </li>
                ))}
              </ul>
            </>
          )}
        </small>
      </p>
    </>
  );
};

export default SlideBtmEvseCost;
