import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Meta from '@bellawatt/meta-tags';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageLayout from '../layout/PageLayout';
import LcfsRevenues from '../graphs/LcfsRevenues';
import calcLcfsDollarsPerKwh from '../calcs/lcfs/calcLcfsDollarsPerKwh';
import InputCard from '../components/InputCard';
import SlideKwhDisplacedForLcfs from '../inputs/components/SlideKwhDisplacedForLcfs';
import SelectElectricCarbonIntensity from '../inputs/components/SelectElectricCarbonIntensity';
import A from '../data/ASSUMPTIONS';

const LcfsCalculator = () => {
  const {kwhDisplacedForLcfs, gridIntensityInGramsCO2ePerMJ} = useInputs();

  const lcfsCredits = A.LCFS_YEARS.map(
    (year) =>
      kwhDisplacedForLcfs * calcLcfsDollarsPerKwh(gridIntensityInGramsCO2ePerMJ, 'diesel', year)
  );

  return (
    <PageLayout page="LCFS Calculator">
      <Meta>
        <Meta.Title>PG&amp;E Fleets: LCFS Calculator</Meta.Title>
        <Meta.Description>
          The Low Carbon Fuel Standard (LCFS) is administered by the California Air Resources Board
          (CARB).
        </Meta.Description>
        <Meta.Image src="/images/home-background.jpg" />
      </Meta>
      <Row className="mt-5">
        <Col lg={{span: 10, offset: 1}} style={{padding: '0 3rem 3rem 3rem'}}>
          <h2 className="text-center">LCFS Calculator</h2>

          <blockquote className="pl-5">
            <p className="mb-0 font-italic">
              The Low Carbon Fuel Standard is designed to decrease the carbon intensity of
              California&apos;s transportation fuel pool and provide an increasing range of
              low-carbon and renewable alternatives, which reduce petroleum dependency and achieve
              air quality benefits.
            </p>
            <footer className="blockquote-footer">
              <a
                href="https://ww2.arb.ca.gov/our-work/programs/low-carbon-fuel-standard"
                target="_blank"
                rel="noopener noreferrer"
              >
                Low Carbon Fuel Standard Homepage
              </a>
            </footer>
          </blockquote>
          <p>
            The Low Carbon Fuel Standard (LCFS) is administered by the{' '}
            <a href="https://ww2.arb.ca.gov/homepage" target="_blank" rel="noopener noreferrer">
              California Air Resources Board (CARB)
            </a>
            . Participation requires registration, which entities can do so{' '}
            <a
              href="https://ww2.arb.ca.gov/our-work/programs/low-carbon-fuel-standard/lcfs-registration-and-reporting"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . The LCFS program is set to continue through at least 2030.
          </p>

          <p>
            The calculation below is based on the methodology provided by CARB, which assumes a
            decreasing carbon intensity of fossil fuels. This tool makes no assumptions about a
            similar decreasing carbon intensity of the California Grid, therefore it is recommended
            to use the results of 2021 for a typical year.
          </p>
          <p>
            The calculation below also assumes a flat LCFS credit price of $
            {A.LCFS_CREDIT_PRICE_IN_DOLLARS}. This value is not guaranteed going forward and can
            change at any time. See the latest LCFS credit prices{' '}
            <a
              href="https://www.arb.ca.gov/fuels/lcfs/credit/lrtmonthlycreditreports.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . A single LCFS credit represents 1 metric ton of carbon, and credit prices are often
            referred to as $ ___ /MT (dollars per metric ton).
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12" sm="4">
          <InputCard header="Low Carbon Fuel Standard">
            <SlideKwhDisplacedForLcfs />
            <SelectElectricCarbonIntensity />
          </InputCard>
        </Col>
        <Col xs="12" sm="8">
          <InputCard>
            <LcfsRevenues lcfsRevenues={lcfsCredits} />
          </InputCard>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default LcfsCalculator;
