import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'react-bootstrap';
import SelectChargingWindows from '../inputs/components/SelectChargingWindows';
import alarmMultipleChargersInUseAtOnce from '../calcs/alarmMultipleChargersInUseAtOnce';
import {useInputs} from '@bellawatt/use-inputs';

const SelectChargingBehaviors = ({changeParam, chargingWindows}) => {
  const {chargers} = useInputs();

  return (
    <>
      <SelectChargingWindows
        value={chargingWindows}
        onChange={(chargingWindows) => changeParam({chargingWindows})}
        chargers={chargers}
      />
      {alarmMultipleChargersInUseAtOnce(chargingWindows) && (
        <Alert variant="danger" className="text-center">
          <strong>Warning!!</strong> Multiple chargers are in use at once for this vehicle set.
        </Alert>
      )}
    </>
  );
};

SelectChargingBehaviors.header = 'Select Charging Behaviors';

SelectChargingBehaviors.propTypes = {
  chargingWindows: PropTypes.array,
  changeParam: PropTypes.func,
};

export default SelectChargingBehaviors;
