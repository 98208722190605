import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import LcfsRevenues from '../../../graphs/LcfsRevenues';
import {formatAsDollarsRounded} from '../../../utils/format';
import calcLcfsCreditsByYear from '../../../calcs/calcLcfsCreditsByYear';
import calcAnnualLcfsCredits from '../../../calcs/calcAnnualLcfsCredit';

const LCFS = () => {
  const {
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ,
    chargers,
  } = useInputs();

  const lcfsCredits = calcAnnualLcfsCredits(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );
  const lcfsCreditsByYear = calcLcfsCreditsByYear(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );

  return (
    <>
      <p className="takeaway">
        Switching to electricity will also allow you to generate Low Carbon Fuel Standard (LCFS)
        credits, which could generate <strong>{formatAsDollarsRounded(lcfsCredits)}</strong> per
        year.
      </p>
      <LcfsRevenues lcfsRevenues={lcfsCreditsByYear} />
      <p className="takeaway">
        For more on LCFS Credits, visit the <a href="/lcfs">LCFS calculator</a>.
      </p>
    </>
  );
};

export default LCFS;
