import React from 'react';
import {useToggle} from '@bellawatt/react-hooks';
import {useInputs} from '@bellawatt/use-inputs';
import {Row, Col} from 'react-bootstrap';

import calcAnnualFossilFuelCost from '../../../calcs/calcAnnualFossilFuelCost';
import calcTotalMilesPerYear from '../../../calcs/calcTotalMilesPerYear';
import calcAnnualElectricityCost from '../../../calcs/calcAnnualElectricityCost';
import calcCO2SavedInTons from '../../../calcs/calcCO2SavedInTons';

import {formatAsDollarsRounded, formatAsThousands, formatAsCents} from '../../../utils/format';
import IconHeader from '../../../layout/IconHeader';
import {Fuel, Lcfs, Co2Cloud} from '../../../icons';
import calcAnnualLcfsCredits from '../../../calcs/calcAnnualLcfsCredit';

const IconsHeader = () => {
  const {
    vehicleSets,
    bevRateName,
    bevBlocks,
    dieselPrice,
    gasolinePrice,
    lpgPrice,
    gridIntensityInGramsCO2ePerMJ,
    chargers,
  } = useInputs();

  const hasVehicleSets = vehicleSets && vehicleSets.length > 0;

  const electricityCosts = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    true
  );
  const fossilFuelCosts = calcAnnualFossilFuelCost(
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    lpgPrice
  );

  const savings = fossilFuelCosts - electricityCosts;
  const miles = calcTotalMilesPerYear(vehicleSets);
  const emissionsOffset = calcCO2SavedInTons(chargers, vehicleSets);
  const lcfsCredits = calcAnnualLcfsCredits(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );

  const [showMobile, toggle] = useToggle(false);
  const allOnRoad = vehicleSets.every(({category}) => !category || category === 'On-Road');

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex justify-content-center">
            <IconHeader header="Annual Fuel Savings" icon={<Fuel color="#00527C" />}>
              {hasVehicleSets && formatAsDollarsRounded(savings)}
            </IconHeader>
            {allOnRoad && (
              <IconHeader
                style={{marginLeft: '20px', paddingLeft: '20px', borderLeft: '1px solid #212529'}}
                header="Savings Per Mile"
              >
                {hasVehicleSets && formatAsCents(savings / miles)}
              </IconHeader>
            )}
          </div>
        </Col>

        <Col className={showMobile ? '' : 'd-none d-md-block'}>
          <div className="d-flex justify-content-center">
            <IconHeader header="Annual LCFS Credits" icon={<Lcfs color="#00527C" />}>
              {hasVehicleSets && formatAsDollarsRounded(lcfsCredits)}
            </IconHeader>
            {allOnRoad && (
              <IconHeader
                style={{marginLeft: '20px', paddingLeft: '20px', borderLeft: '1px solid #212529'}}
                header="Revenue Per Mile"
              >
                {hasVehicleSets && formatAsCents(lcfsCredits / miles)}
              </IconHeader>
            )}
          </div>
        </Col>

        <Col className={showMobile ? '' : 'd-none d-md-block'}>
          <div className="d-flex justify-content-center">
            <IconHeader header="Annual GHG Emissions Saved" icon={<Co2Cloud color="#00527C" />}>
              {hasVehicleSets && `${formatAsThousands(emissionsOffset)} Tons`}
            </IconHeader>
          </div>
        </Col>
      </Row>
      <div className="d-block my-1 mb-3 d-md-none text-center">
        <button onClick={toggle} className="btn btn-sm btn-link" type="button">
          {showMobile ? 'Hide Savings' : 'See More Savings'}
        </button>
      </div>
    </>
  );
};

export default IconsHeader;
