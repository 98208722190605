import {LoadProfile, RateCalculator} from '@bellawatt/electric-rate-engine';
import {useInputs} from '@bellawatt/use-inputs';
import React, {useState} from 'react';
import {Table} from 'react-bootstrap';
import Select from '../inputs/Select';
import {formatAsDollars, formatAsThousands} from '../utils/format';
import mean from 'lodash/mean';
import A from '../data/ASSUMPTIONS';
import calcTotalYearlyVehicleSetLoadProfile from '../calcs/calcTotalYearlyVehicleSetLoadProfile';
import calcTotalChargerSetLoadProfile from '../calcs/calcTotalChargerSetLoadProfile';
import getApplicableRates from '../rates';
import calcTotalWeeklyLoadProfile from '../calcs/calcTotalWeeklyLoadProfile';
import calcBevCosts from '../calcs/calcBevCosts/calcBevCosts';

const RateBreakdownTable = () => {
  const {
    bevVoltage,
    bevBlocks,
    vehicleSets,
    chargerSets,
    chargingFacilityType,
    chargers,
    estimationApproach,
    onlyShowApplicableRates,
    rateComparisonVehiclesBevRateName,
    rateComparisonChargersBevRateName,
  } = useInputs();

  const bevRate =
    estimationApproach === 'Vehicles'
      ? rateComparisonVehiclesBevRateName
      : rateComparisonChargersBevRateName;

  const loadProfileValues =
    estimationApproach === 'Vehicles'
      ? calcTotalYearlyVehicleSetLoadProfile({vehicleSets, chargers})
      : calcTotalChargerSetLoadProfile({
          chargerSets,
          timeRange: 'yearly',
          facilityType: chargingFacilityType,
        });
  const loadProfile = new LoadProfile(loadProfileValues, {year: A.YEAR});

  const nonBevRates = getApplicableRates(loadProfile, {
    voltage: bevVoltage,
    ignoreMaxKw: !onlyShowApplicableRates,
  });

  const weeklyBevLoadProfile =
    estimationApproach === 'Vehicles'
      ? calcTotalWeeklyLoadProfile(chargers, vehicleSets)
      : calcTotalChargerSetLoadProfile({
          chargerSets,
          timeRange: 'weekly',
          facilityType: chargingFacilityType,
        });

  const {
    components: bevComponents,
    monthlyEnergy: bevMonthlyEnergy,
    monthlyOverage: bevMonthlyOverage,
    monthlySubscription: bevMonthlySubscription,
  } = calcBevCosts(weeklyBevLoadProfile, bevRate, bevBlocks, false);

  const [selectedRateName, setSelectedRateName] = useState('BEV');
  const selectedRate = nonBevRates.find(({name}) => name === selectedRateName);
  const isBevSelected = selectedRateName === 'BEV';

  const rateCalculator = selectedRate && new RateCalculator({...selectedRate, loadProfile});
  const rateComponents = isBevSelected
    ? bevComponents
    : rateCalculator
        .rateElements()
        .map((element) => element.rateComponents())
        .flat();

  return (
    <div>
      <p className="title-for-graphs-and-tables mt-5">Rate Elements</p>
      <div style={{maxWidth: '300px', marginBottom: '2rem'}}>
        <Select
          label="Rate Selected"
          value={selectedRateName}
          optionValues={['BEV', ...nonBevRates.map(({name}) => name)]}
          onChange={(name) => setSelectedRateName(name)}
        />
      </div>
      <Table bordered className="chart-js-styles-for-tables">
        <thead>
          <tr>
            <th width="25%">Rate Element</th>
            <th>Charge</th>
            <th>Units</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {rateComponents.map((component, idx) => (
            <tr key={idx}>
              {isBevSelected ? (
                <>
                  <th>{component.name}</th>
                  <td>{`$${component.charge.toFixed(2)}`}</td>
                  <td>{formatAsThousands(component.units)}</td>
                  <td>{formatAsDollars(component.cost)}</td>
                </>
              ) : (
                <>
                  <th>{component.name}</th>
                  <td>{`$${mean(component.charge).toFixed(2)}`}</td>
                  <td>{formatAsThousands(component.typicalBillingDeterminant())}</td>
                  <td>{formatAsDollars(component.typicalMonthlyCost())}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>Total</th>
            <th>
              {isBevSelected
                ? formatAsDollars(bevMonthlyEnergy + bevMonthlyOverage + bevMonthlySubscription)
                : formatAsDollars(rateCalculator.annualCost() / 12)}
            </th>
          </tr>
        </tfoot>
      </Table>
      <p className="small">Charges are rounded for clarity - see tariff tables for exact values.</p>
    </div>
  );
};

export default RateBreakdownTable;
