const calcResaleValue = (vehicleBasePurchaseCost, duty, yearsOfOperation) => {
  if (duty === 'light_duty')
    return vehicleBasePurchaseCost * 0.9429 * Math.exp(-0.084 * yearsOfOperation);
  if (duty === 'heavy_duty')
    return (
      vehicleBasePurchaseCost *
      (-0.0003 * Math.pow(yearsOfOperation, 3) +
        0.0136 * Math.pow(yearsOfOperation, 2) -
        0.2056 * yearsOfOperation +
        1.2336)
    );

  return vehicleBasePurchaseCost * 0.8826 * Math.exp(-0.1 * yearsOfOperation);
};

export default calcResaleValue;
