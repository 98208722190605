import React, {useState, useCallback} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {colors, typography} from '../styles';
import TalkToAPGESpecialist from '../components/TalkToAPGESpecialist';
import RecommendationDropdown from '../components/RecommendationDropdown';

const StickyFooter = () => {
  const [stuck, setStuck] = useState(true);
  const containerRef = useCallback((ref) => {
    if (!ref) {
      return;
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio === 1) {
          setStuck(false);
        } else {
          setStuck(true);
        }
      },
      {
        rootMargin: '0px',
        threshold: 1,
      }
    );

    observer.observe(ref);
  }, []);

  return (
    <Row className="footer">
      <Col style={{padding: '0', minHeight: '96px', marginTop: '20px'}} ref={containerRef}>
        <div
          style={{
            position: stuck ? 'fixed' : 'static',
            bottom: 0,
            zIndex: 99,
            padding: '25px',
            backgroundColor: colors.blue.darker,
            color: colors.white,
            fontWeight: typography.weight.bold,
            textAlign: 'center',
            width: '100%',
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div className="mx-1">
              <TalkToAPGESpecialist />
            </div>
            <div
              className="d-none d-md-block mx-1"
              style={{color: colors.white}}
            >
              How likely are you to recommend this tool?
            </div>
            <div className="d-none d-md-block mx-1">
              <RecommendationDropdown />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default StickyFooter;
