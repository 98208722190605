import React from 'react';

const Loading = () => (
  <div className="container text-center">
    <img
      src="/images/ellipsis-3s-90px/Ellipsis-3s-90px.svg"
      alt="loading"
      style={{maxWidth: 100}}
    />
  </div>
);

export default Loading;
