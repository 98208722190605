import calcGallonsOfFossilFuelForVehicleSet from './calcGallonsOfFossilFuelForVehicleSet';
import sum from 'lodash/sum';

const calcGallonsOfFossilFuel = (vehicleSets, fossilFuelType) =>
  sum(
    vehicleSets
      .filter((set) => set.vehicle.fossilFuelType === fossilFuelType)
      .map((set) => calcGallonsOfFossilFuelForVehicleSet(set))
  );

export default calcGallonsOfFossilFuel;
