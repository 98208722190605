const demandCharges = {
  Secondary: 17.47,
  Primary: 17.19,
  Transmission: 13.66,
};

const energyPeakSummerCharges = {
  Secondary: 0.31411,
  Primary: 0.29823,
  Transmission: 0.23025,
};

const energyPartPeakSummerCharges = {
  Secondary: 0.25242,
  Primary: 0.23993,
  Transmission: 0.17351,
};

const energyOffPeakSummerCharges = {
  Secondary: 0.21985,
  Primary: 0.20909,
  Transmission: 0.14344,
};

const energyPeakWinterCharges = {
  Secondary: 0.23784,
  Primary: 0.22538,
  Transmission: 0.1772,
};

const energyOffPeakWinterCharges = {
  Secondary: 0.20236,
  Primary: 0.19174,
  Transmission: 0.14436,
};

const energySuperOffPeakWinterCharges = {
  Secondary: 0.16602,
  Primary: 0.1554,
  Transmission: 0.10802,
};

const b10Rate = ({voltage}) => ({
  name: 'B-10',
  title: 'B-10 Medium General Demand-Metered Service',
  maxKw: 499,
  rateElements: [
    {
      rateElementType: 'FixedPerDay',
      classification: 'fixed',
      rateComponents: [{name: 'Customer Charge ($ per meter per day)', charge: 6.42016}],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      classification: 'demand',
      rateComponents: [
        {
          name: 'Demand Rates: Summer ($ per kW)',
          charge: demandCharges[voltage],
          months: [5, 6, 7, 8],
        },
        {
          name: 'Demand Rates: Winter ($ per kW)',
          charge: demandCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      classification: 'energy',
      rateComponents: [
        {
          name: 'Total Energy Rates ($ per kWh): Peak Summer',
          charge: energyPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates ($ per kWh): Part-Peak Summer',
          charge: energyPartPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [14, 15, 21, 22],
        },
        {
          name: 'Total Energy Rates ($ per kWh): Off-Peak Summer',
          charge: energyOffPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 23],
        },
        {
          name: 'Total Energy Rates ($ per kWh): Peak Winter',
          charge: energyPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates ($ per kWh): Off-Peak Winter',
          charge: energyOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [9, 10, 11, 12, 13],
        },
        {
          name: 'Total Energy Rates ($ per kWh): Super Off-Peak Winter',
          charge: energySuperOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
        },
      ],
    },
  ],
});

export default b10Rate;
