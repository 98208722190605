const mediumDutyLoadProfile = [
  2948.4,
  2721.6,
  1587.6,
  793.8,
  226.8,
  113.4,
  113.4,
  113.4,
  226.8,
  226.8,
  453.6,
  1360.8,
  1927.8,
  1927.8,
  1134.0,
  907.2,
  0.0,
  0.0,
  0.0,
  0.0,
  226.8,
  680.4,
  2041.2,
  2948.4,
];

export default mediumDutyLoadProfile;
