import {times} from 'lodash';
import A from '../data/ASSUMPTIONS';
import loadProfiles from '../data/loadProfiles';
import {getHoursOfWeek, getHoursOfYear} from '../utils/time';
import TIME_PERIODS from '../utils/timePeriods';

const isWorkday = ({chargerSet: {workdays, workmonths}, date, ignoreMonths}) =>
  workdays.includes(date.dayOfWeek) && (ignoreMonths || workmonths.includes(date.month));

const calcPresetFacilityDailyLoadProfile = (chargerSet, {facilityType}) => {
  const dailyLoadProfile = loadProfiles[facilityType];

  const chargerKw = chargerSet.charger.portKw;
  const numChargers = chargerSet.numChargersInstalled;

  // for each hour, get relative weight of that hour's kW to the max value of the 24 hours, multiply by charger count * charger kW
  const maxDailyValue = Math.max(...dailyLoadProfile);
  const scaledDailyLoadProfile = dailyLoadProfile.map(
    (value) => (value / (maxDailyValue || 1)) * chargerKw * numChargers
  );
  return scaledDailyLoadProfile;
};

const calcCustomFacilityDailyLoadProfile = (chargerSet) => {
  const {chargersPerTimePeriod, charger} = chargerSet;

  // for each hour, multiply number of chargers used in that hour's time period * charger kW
  return times(24, (hour) => {
    const timePeriod = TIME_PERIODS.find((period) => period.hours[hour] === 1);
    if (timePeriod) {
      const count = chargersPerTimePeriod[timePeriod.key] || 0;
      return charger.portKw * count;
    }
    return 0;
  });
};

const calcChargerSetLoadProfile = (chargerSet, {timeRange, facilityType}) => {
  const dailyLoadProfile =
    facilityType === 'Custom'
      ? calcCustomFacilityDailyLoadProfile(chargerSet)
      : calcPresetFacilityDailyLoadProfile(chargerSet, {facilityType});

  const hours = timeRange === 'yearly' ? getHoursOfYear(A.YEAR) : getHoursOfWeek();

  // apply daily load profile to a full week or year, replacing values with 0 if not a workday
  return hours.map((date) =>
    isWorkday({chargerSet, date, ignoreMonths: timeRange === 'weekly'})
      ? dailyLoadProfile[date.hourStart]
      : 0
  );
};

export default calcChargerSetLoadProfile;
