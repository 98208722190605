import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Select from '../Select';
import BEV from '../../data/BEV';

const SelectBevRateName = ({maxKw}) => {
  const {bevRateName, setInput} = useInputs();
  const subscriptions = BEV.tariff_elements.pge_subscription_rate.filter(
    ({maxApplicableKwh}) => !maxApplicableKwh || maxKw < maxApplicableKwh
  );
  const currentSubscription = subscriptions.find(
    (subscription) => subscription.name === bevRateName
  );

  return (
    <>
      <Select
        label="BEV Rate Option"
        value={bevRateName}
        optionValues={subscriptions.map((subscription) => subscription.name)}
        onChange={(bevRateName) => {
          setInput({bevRateName});
        }}
      />
      {currentSubscription && <p className="small mt-2">{currentSubscription.helpText}</p>}
    </>
  );
};

export default SelectBevRateName;
