import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import without from 'lodash/without';
import Label from '../Label';
import CheckButton from '../CheckButton';
import A from '../../data/ASSUMPTIONS';
import {times} from 'lodash';

const ChooseWorkdays = ({value, onChange}) => {
  const toggleMonth = (monthIndex) => {
    if (value.includes(monthIndex)) {
      onChange(without(value, monthIndex));
    } else {
      onChange([...value, monthIndex]);
    }
  };

  const COL_PADDING = {paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px'};

  return (
    <>
      <Label>Months in Operation</Label>
      <Row className="my-2 px-2">
        {times(12, (i) => (
          <Col key={i} className="workday-btn" style={COL_PADDING}>
            <CheckButton checked={value.includes(i)} onClick={() => toggleMonth(i)}>
              {A.MONTHS[i]}
            </CheckButton>
          </Col>
        ))}
      </Row>
    </>
  );
};

ChooseWorkdays.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default ChooseWorkdays;
