const CHARGERS = [
  {
    type: 'Level 2 - 7.7kW',
    ports: 1,
    portKw: 7.7,
    estimatedCost: 1065,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    type: 'Level 2 - 7.7kW [Dual Port]',
    ports: 2,
    portKw: 7.7,
    estimatedCost: 7800,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    type: 'Level 2 - 11.5kW',
    ports: 1,
    portKw: 11.5,
    estimatedCost: 1142,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 11.5KW-01.png',
  },
  {
    type: 'Level 2 - 19.2kW',
    ports: 1,
    portKw: 19.2,
    estimatedCost: 3460,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },
  {
    type: 'Level 2 - 19.2kW [4 Port]',
    ports: 4,
    portKw: 19.2,
    estimatedCost: 8595,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 19.2KW-01.png',
  },
  {
    type: 'DCFC - 50kW',
    ports: 1,
    portKw: 50,
    estimatedCost: 30000,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    type: 'DCFC - 100kW',
    ports: 1,
    portKw: 100,
    estimatedCost: 40800,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 100 KW dual port charger-01.png',
  },
  {
    type: 'DCFC - 175kW',
    ports: 1,
    portKw: 175,
    estimatedCost: 108000,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC 175kW Singal Port--01.png',
  },
  {
    type: 'DCFC - 175kW [Dual Port]',
    ports: 2,
    portKw: 175,
    estimatedCost: 114000,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC 175kW Singal Port--01.png',
  },
  {
    type: 'DCFC - 500kW [6 Port]',
    ports: 6,
    portKw: 500,
    estimatedCost: 278000,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 6 port 500kW-01.png',
  },
];

export default CHARGERS;
