import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';

const options = ['On-Road', 'Off-Road', 'TRU'];

const SelectVehicleCategory = ({value, onChange}) => (
  <Select
    label="Vehicle Category"
    value={value}
    onChange={onChange}
    optionValues={options}
    // We could filter through all vehicles for categories, but we want this exact order so we'd be hardcoding them anway
  />
);

SelectVehicleCategory.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SelectVehicleCategory.options = options;

export default SelectVehicleCategory;
