import {useInputs} from '@bellawatt/use-inputs';
import React from 'react';
import Select from '../Select';

const options = {
  car_sharing_companies: 'Car Sharing Company',
  federal_government_entities: 'Federal Government Entity',
  local_government_entities: 'Local Government Entity',
  logistics_company: 'Logistics Company',
  medium_or_large_businesses: 'Medium or Large Business',
  native_tribes: 'Native Tribe',
  nonprofits: 'Nonprofit',
  private_school_districts: 'Private School District',
  public_school_districts: 'Public School District',
  rental_companies: 'Car Rental Company',
  small_businesses: 'Small Business',
  state_government_entities: 'State Government Entity',
  transit_agencies: 'Transit Agency',
  universities: 'University',
  utility: 'Utility',
  other: 'Other',
};

const values = Object.keys(options);

const SelectOrganizationType = () => {
  const {setInput, organizationType} = useInputs();

  return (
    <Select
      label="Industry"
      optionValues={values}
      optionNames={values.map((key) => options[key])}
      value={options[organizationType]}
      onChange={(organizationType) => setInput({organizationType})}
    />
  );
};

export default SelectOrganizationType;
