import dcfcLoadProfile from './dcfc';
import workplaceLoadProfile from './workplace';
import multifamilyLoadProfile from './multifamily';
import transitLoadProfile from './transit';
import mediumDutyLoadProfile from './mediumDuty';

const loadProfiles = {
  DCFC: dcfcLoadProfile,
  Workplace: workplaceLoadProfile,
  Multifamily: multifamilyLoadProfile,
  Transit: transitLoadProfile,
  'Medium Duty': mediumDutyLoadProfile,
};

export default loadProfiles;
