import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from '../Select';
import A from '../../data/ASSUMPTIONS';
import {Close} from '../../icons';
import {DEFAULT_ADDITIONAL_CHARGING_WINDOW} from '../../defaults';
import CreateCustomCharger from './CreateCustomCharger';

const ChargingWindow = ({
  start,
  finish,
  type,
  discardBehavior,
  chargingApproach,
  index,
  updateBehavior,
  chargers,
}) => (
  <Row>
    <Col xs="5" sm="2">
      <Select
        label="Start"
        tooltip="Peak charges end at 9pm"
        onChange={(start) => updateBehavior(index, {start, finish, chargingApproach, type})}
        value={A.HOUR_OF_DAY_LABELS[start]}
        optionValues={A.HOUR_OF_DAY}
        optionNames={A.HOUR_OF_DAY_LABELS}
      />
    </Col>
    <Col xs="6" sm="2">
      <Select
        label="Finish"
        tooltip="Peak charges start at 4pm"
        onChange={(finish) => updateBehavior(index, {start, finish, chargingApproach, type})}
        value={A.HOUR_OF_DAY_LABELS[finish]}
        optionValues={A.HOUR_OF_DAY}
        optionNames={A.HOUR_OF_DAY_LABELS}
      />
    </Col>
    <Col xs="12" sm="5">
      <Select
        label="Charger"
        tooltip="The higher the voltage, the faster the charge"
        onChange={(type) => updateBehavior(index, {start, finish, chargingApproach, type})}
        value={type}
        optionValues={chargers.map((charger) => charger.type)}
      />
    </Col>
    <Col xs="10" sm="2">
      <Select
        label="Approach"
        tooltip="Select 'Even' to spread the charging evenly and 'Max' to quickly charge and return back to service"
        onChange={(chargingApproach) =>
          updateBehavior(index, {start, finish, chargingApproach, type})
        }
        value={chargingApproach}
        optionValues={['Even', 'Max']}
      />
    </Col>
    <Col xs="1" sm="1" className="d-flex justify-content-center align-items-center">
      <div
        onClick={discardBehavior}
        className="btn btn-sm btn-faint"
        style={{marginTop: '30px', fontSize: '30px'}}
      >
        <Close />
      </div>
    </Col>
  </Row>
);

ChargingWindow.propTypes = {
  start: PropTypes.number,
  finish: PropTypes.number,
  chargingApproach: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  updateBehavior: PropTypes.func,
  discardBehavior: PropTypes.func,
  chargers: PropTypes.array,
};

const SelectChargingWindows = ({value, onChange, chargers}) => {
  const [isCreatingCustom, setIsCreatingCustom] = useState(false);

  const addChargingWindow = () => {
    onChange([...value, DEFAULT_ADDITIONAL_CHARGING_WINDOW]);
  };

  const updateBehavior = (index, behavior) => {
    const newBehaviors = value.slice();
    newBehaviors[index] = behavior;

    onChange(newBehaviors);
  };

  const discardBehavior = (index) => {
    onChange(value.filter((_, idx) => idx !== index));
  };

  return isCreatingCustom ? (
    <CreateCustomCharger
      defaultCharger={value[value.length - 1]}
      cancel={() => setIsCreatingCustom(false)}
      changeParam={({type}) => updateBehavior(value.length - 1, {...value[value.length - 1], type})}
    />
  ) : (
    <>
      {value.map((behavior, idx) => (
        <ChargingWindow
          key={idx}
          index={idx}
          chargers={chargers}
          discardBehavior={() => discardBehavior(idx)}
          updateBehavior={updateBehavior}
          {...behavior}
        />
      ))}
      <button type="button" onClick={addChargingWindow} className="btn btn-link mb-3">
        + Add charging time
      </button>
      <button
        type="button"
        onClick={() => setIsCreatingCustom(true)}
        className="btn btn-link mb-3 float-right"
      >
        + Add custom charger
      </button>
    </>
  );
};

SelectChargingWindows.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  chargers: PropTypes.array,
};

export default SelectChargingWindows;
