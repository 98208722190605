import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';
import {formatAsDollars} from '../../utils/format';

const SlideBtmConstructionCosts = () => {
  const {btmConstructionCost, setInput} = useInputs();

  return (
    <>
      <Slider
        label="BTM Construction Costs"
        min={0}
        max={5000000}
        step={10000}
        valFormat={formatAsDollars}
        value={btmConstructionCost}
        onChange={(btmConstructionCost) =>
          setInput({btmConstructionCost: parseInt(btmConstructionCost)})
        }
      />
      <small className="mt-3">
        PG&amp;E refers to construction costs to the site as &quot;Behind the Meter.&quot; All
        construction costs &quot;To the Meter&quot; are typcially covered by PG&amp;E.
      </small>
    </>
  );
};

export default SlideBtmConstructionCosts;
