import React from 'react';
import Container from 'react-bootstrap/Container';
import useApiIncentives from '../utils/useApiIncentives';
import Meta from '@bellawatt/meta-tags';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PageHeader, PageFooter} from '../layout/PageLayout';
import {Link} from '../layout/Links';
import {Header, Step} from '../layout/Headers';
import {typography, colors} from '../styles';
import Button from '../layout/Button';
import Testimonial from '../components/Testimonial';
import IconHeader from '../layout/IconHeader';
import {Fuel, Lcfs, Co2Cloud} from '../icons';
import StickyFooter from '../layout/StickyFooter';
import {
  formatAsDollarsRounded,
  formatVehicleName,
  formatAsThousandsOfDollars,
  formatAsThousands,
} from '../utils/format';
import calcAnnualElectricityCost from '../calcs/calcAnnualElectricityCost';
import calcAnnualFossilFuelCost from '../calcs/calcAnnualFossilFuelCost';
import calcAnnualLcfsCredit from '../calcs/calcAnnualLcfsCredit';
import calcCO2SavedInTons from '../calcs/calcCO2SavedInTons';
import defaults from '../defaults';
import DEFAULT_VEHICLES from '../data/VEHICLES';
import DEFAULT_CHARGERS from '../data/CHARGERS';
import Loading from '../components/Loading';
import DetailedGrantCard from '../components/DetailedGrantCard';

const BigPromise = () => (
  <Row
    style={{
      backgroundImage: 'url(/images/home-background.jpg)',
      backgroundColor: colors.brand.darkBlue,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      color: colors.white,
      alignItems: 'center',
      fontWeight: typography.weight.bold,
      textAlign: 'center',
    }}
  >
    <Col>
      <PageHeader transparent />
      <img height="90px" width="90px" src="/images/logo.png" alt="Logo" className="mb-5" />
      <h1 style={{fontSize: typography.headers[1]}}>Electrify Your Fleet: Drive Change</h1>
      <h2 style={{fontSize: typography.headers[3]}}>
        Learn how you can start saving money and the planet.
      </h2>
      <Row className="justify-content-center pb-5 my-5">
        <Col>
          <Link href="/fuel-savings">
            <Button className="mb-3">Calculate Fuel Savings</Button>
          </Link>
          <Link href="/tco">
            <Button className="mb-3">Total Cost of Ownership</Button>
          </Link>
        </Col>
      </Row>
    </Col>
  </Row>
);

const BuildAPlan = () => (
  <Row>
    <Col className="text-center my-5 pt-5">
      <Header>Build a plan with PG&amp;E</Header>
      <Row className="my-4">
        <Col md="6" lg>
          <Step number="1">Check Your Eligibility</Step>
        </Col>
        <Col md="6" lg>
          <Step number="2">Review Available Funding</Step>
        </Col>
        <Col md="6" lg>
          <Step number="3">Calculate Fuel Savings</Step>
        </Col>
        <Col md="6" lg>
          <Step number="4">Collaborate with PG&amp;E</Step>
        </Col>
      </Row>
      <Row>
        <Col
          style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
          xl={{span: 8, offset: 2}}
        >
          <p className="lead">
            Together, let&apos;s drive savings, sustainability, and change. We've compiled the
            resources you need to understand the entire process and make an informed decision.
          </p>
          <Button href="/plan">Build A Plan</Button>
        </Col>
      </Row>
    </Col>
  </Row>
);

const FreeMoney = () => {
  const {loading, incentives} = useApiIncentives();
  const filteredIncentives = incentives
    .filter(
      (incentive) => incentive.grantor_name === 'PG&E' || incentive.grantor_name === 'Federal'
    )
    .filter((incentive) => incentive.current_funding_status === 'funded');

  return (
    <Row>
      <Col className="text-center mb-5 py-5">
        <Header>Did you know that you can get free money?</Header>

        <Container className="my-4 text-left">
          {loading ? (
            <Loading />
          ) : (
            <>
              {filteredIncentives.map((incentive) => (
                <Row key={incentive.incentive_handle}>
                  <Col>
                    <DetailedGrantCard {...incentive} />
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Container>
        <Button href="/grants">View all Grants and Incentives</Button>
      </Col>
    </Row>
  );
};

const CalcPreview = () => {
  const pickupTruck = DEFAULT_VEHICLES.find(({id}) => id === 'on_road_pick_up_truck_medium_duty');
  const cargoVan = DEFAULT_VEHICLES.find(({id}) => id === 'on_road_cargo_van_medium_duty');

  const homeVehicleSets = [
    {
      vehicle: pickupTruck,
      vehicleCount: 10,
      milesPerWorkday: 150,
      workdays: [1, 2, 3, 4, 5],
      chargingWindows: [
        {
          start: 21,
          finish: 9,
          chargingApproach: 'Even',
          type: 'Level 2 - 7.7kW [Dual Port]',
        },
      ],
    },
    {
      vehicle: cargoVan,
      vehicleCount: 3,
      milesPerWorkday: 150,
      workdays: [1, 2, 3, 4, 5],
      chargingWindows: [
        {
          start: 21,
          finish: 9,
          chargingApproach: 'Even',
          type: 'Level 2 - 7.7kW [Dual Port]',
        },
      ],
    },
  ];

  const electricityCosts = calcAnnualElectricityCost(
    DEFAULT_CHARGERS,
    homeVehicleSets,
    defaults.bevRateName,
    defaults.bevBlocks
  );
  const fossilFuelCosts = calcAnnualFossilFuelCost(
    homeVehicleSets,
    defaults.dieselPrice,
    defaults.gasolinePrice,
    defaults.lpgPrice
  );
  const savings = fossilFuelCosts - electricityCosts;
  const emissionsOffset = calcCO2SavedInTons(DEFAULT_CHARGERS, homeVehicleSets);
  const lcfsCredits = calcAnnualLcfsCredit(
    DEFAULT_CHARGERS,
    homeVehicleSets,
    defaults.gridIntensityInGramsCO2ePerMJ
  );

  return (
    <Container fluid style={{backgroundColor: colors.brand.darkBlue, color: colors.white}}>
      <Container>
        <Row>
          <Col className="my-5 py-5 text-center">
            <Header className="mb-5">
              Based on the typical activity of{' '}
              <strong>{formatVehicleName(homeVehicleSets[0])}</strong> and{' '}
              <strong>{formatVehicleName(homeVehicleSets[1])}</strong>, you can net an extra{' '}
              <strong>{formatAsThousandsOfDollars(savings + lcfsCredits)}</strong> per year.
            </Header>

            <Link href="/fuel-savings">
              <Button>Customize to your Fleet</Button>
            </Link>

            <Row className="mt-5">
              <Col className="d-flex justify-content-center">
                <IconHeader
                  header="Fuel Savings"
                  icon={<Fuel />}
                  headerStyles={{opacity: 0.75, color: colors.white}}
                >
                  {formatAsDollarsRounded(savings)}
                </IconHeader>
              </Col>
              <Col className="d-flex justify-content-center">
                <IconHeader
                  header="Low Carbon Fuel Standard Credits"
                  icon={<Lcfs />}
                  headerStyles={{opacity: 0.75, color: colors.white}}
                >
                  {formatAsDollarsRounded(lcfsCredits)}
                </IconHeader>
              </Col>
              <Col className="d-flex justify-content-center">
                <IconHeader
                  header="Green House Gas Emissions Saved"
                  icon={<Co2Cloud />}
                  headerStyles={{opacity: 0.75, color: colors.white}}
                >
                  {formatAsThousands(emissionsOffset)} Tons
                </IconHeader>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const DID_YOU_KNOW_STYLE = {
  backgroundColor: colors.grey.darker,
  padding: '60px 40px',
  width: '305px',
  fontSize: typography.body.primary,
  color: colors.white,
  borderRadius: '4px',
  height: '100%',
  margin: '0 auto',
};

const TidbitsAndTestimonials = () => (
  <Row>
    <Col className="text-center my-5 pt-5">
      <Header className="mb-4">Some fleet owners and operators don’t know that...</Header>
      <Row>
        <Col className="mb-5">
          <div style={DID_YOU_KNOW_STYLE}>
            There are many grants and incentives available to you.
          </div>
        </Col>
        <Col className="mb-5">
          <div style={DID_YOU_KNOW_STYLE}>
            You have an increasingly vast amount of vehicle and charger options to choose from.
          </div>
        </Col>
        <Col className="mb-5">
          <div style={DID_YOU_KNOW_STYLE}>
            PG&amp;E covers most of the infrastructure costs and may additionally cover your charger
            costs.
          </div>
        </Col>
      </Row>

      <Header className="mt-5 pt-5">Testimonials</Header>
      <Row>
        <Col className="py-4 px-5">
          <Testimonial
            type="School"
            href="https://www.pge.com/content/dam/pge/docs/clean-energy/electric-vehicles/pusd-case-study-part2.pdf.coredownload.pdf"
            image="/images/pusd-logo.png"
            alt="Pittsburg Unified School District Logo"
          >
            “PG&amp;E helped us be more efficient, more sustainable, and without a lot of expense.
            My experience has been nothing short of awesome.”
          </Testimonial>
        </Col>
        <Col className="py-4 px-5">
          <Testimonial
            type="Commercial"
            href="https://www.pge.com/content/dam/pge/docs/clean-energy/electric-vehicles/pepsico-case-study-distribution-delivery.pdf.coredownload.pdf"
            image="/images/pepsico-logo.png"
            alt="Pepsico Logo"
          >
            “We hope this work will become an operating model for all of our facilities across the
            U.S., and that we act as the catalyst to accelerate adoption of alternative fuel
            vehicles across the industry.”
          </Testimonial>
        </Col>
      </Row>
      <Row>
        <Col className="py-4 px-5">
          <Testimonial
            type="Shuttle Bus"
            href="https://www.pge.com/content/dam/pge/docs/clean-energy/electric-vehicles/genentech-case-study.pdf.coredownload.pdf"
            image="/images/genentech-logo.png"
            alt="Genentech Logo"
          >
            “The incentives, resources and planning assistance available through PG&amp;E’s EV Fleet
            program made it much easier, more cost-effective, and quicker for Genentech to scale up
            its deployment of electric shuttle buses.”
          </Testimonial>
        </Col>
      </Row>
    </Col>
  </Row>
);

const Footer = () => (
  <>
    <Row style={{backgroundColor: colors.brand.darkBlue, color: colors.white}}>
      <Col className="mt-5 pt-5">
        <Header size={1}>Visualize the impact of going electric.</Header>
        <Header size={4}>Learn how you can start saving money and the planet.</Header>
        <Row className="my-5 justify-content-center">
          <Col sm="auto mb-3 text-center">
            <Link href="/fuel-savings">
              <Button>Calculate Fuel Savings</Button>
            </Link>
          </Col>
          <Col sm="auto mb-3 text-center">
            <Button href="/plan">Build a Plan</Button>
          </Col>
        </Row>

        <StickyFooter />
      </Col>
    </Row>
    <PageFooter />
  </>
);

const Home = () => (
  <>
    <Meta>
      <Meta.Title>PG&amp;E Fleets</Meta.Title>
      <Meta.Description>
        Electrify your fleet and drive change. Learn how you can start saving money and the planet.
      </Meta.Description>
      <Meta.Image src="/images/home-background.jpg" />
    </Meta>
    <Container fluid>
      <BigPromise />
    </Container>

    <Container>
      <BuildAPlan />
    </Container>

    <Container style={{maxWidth: '1400px'}}>
      <FreeMoney />
    </Container>

    <CalcPreview />

    <Container>
      <TidbitsAndTestimonials />
    </Container>

    <Container fluid>
      <Footer />
    </Container>
  </>
);

export default Home;
