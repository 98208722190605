import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageLayout from '../layout/PageLayout';
import {colors} from '../styles';

const ErrorContainer = ({children}) => (
  <div
    style={{
      width: '100%',
      borderRadius: '8px',
      backgroundColor: colors.white,
      boxShadow: '0 16px 34px 7px rgba(121,121,121,0.1)',
      padding: '72px 40px',
      marginBottom: '40px',
    }}
  >
    {children}
  </div>
);

const Header = ({children}) => (
  <div
    style={{
      fontSize: '28px',
      fontWeight: 500,
      textAlign: 'center',
      margin: '40px 0',
    }}
  >
    {children}
  </div>
);

const ErrorPage = () => (
  <PageLayout>
    <Row>
      <Col className="d-flex flex-column justify-content-center" md={{span: 8, offset: 2}}>
        <Header>Oops, looks like something went wrong.</Header>
        <ErrorContainer>
          <p>
            Don't worry, our development team has already been notified. Please refresh your browser
            or <a href="/">click here to go home</a>.
          </p>
          <p>If the problem persists, check back later.</p>
        </ErrorContainer>
      </Col>
    </Row>
  </PageLayout>
);

export default ErrorPage;
