import React, {useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import moment from 'moment';
import times from 'lodash/times';
import {useInputs} from '@bellawatt/use-inputs';
import {chartColors} from '../styles';
import Select from '../inputs/Select';
import groupByChargers from '../calcs/groupByChargers';
import BaseGraph from './BaseGraph';
import {formatVehicleName} from '../utils/format';
import uniqueLabels from '../utils/uniqueLabels';

const labels = times(24, (hour) => moment(hour, 'H').format('ha'));

const MinimumChargingEquipmentTable = () => {
  const {vehicleSets} = useInputs();
  const chargingSets = groupByChargers(vehicleSets);
  const [displayType, setDisplayType] = useState(
    chargingSets.length > 0 ? chargingSets[0].type : ''
  );

  const suggestedMax = Math.max(
    ...chargingSets.map(({minimumRequired, ports}) => minimumRequired * ports)
  );

  const selectedCharger = chargingSets.find(({type}) => type === displayType) || chargingSets[0];

  const datasets = uniqueLabels(
    selectedCharger.vehicles.map((set, i) => ({
      label: `${set.vehicleCount}x ${formatVehicleName(set)}`,
      data: set.usage,
      backgroundColor: chartColors[i],
    }))
  );

  return (
    <>
      <div className="title-for-graphs-and-tables text-center">Minimum Charging Equipment</div>
      <Row>
        <Col xs="12" md={{offset: 3, span: 6}}>
          <Select
            value={selectedCharger.type}
            onChange={(value) => setDisplayType(value)}
            label="Select Charger"
            optionValues={chargingSets.map(({type}) => type)}
          />
        </Col>
      </Row>
      <BaseGraph
        key={displayType}
        stacked
        type="bar"
        title="Hourly Charger Use"
        data={{labels, datasets}}
        yAxisOptions={{ticks: {suggestedMax, precision: 0}}}
      />
    </>
  );
};

export default MinimumChargingEquipmentTable;
