import {useInputs} from '@bellawatt/use-inputs';
import React from 'react';
import Select from '../Select';

const SelectVoltage = () => {
  const {bevVoltage, setInput} = useInputs();

  return (
    <>
      <Select
        label="Voltage Level"
        value={bevVoltage}
        optionValues={['Secondary', 'Primary', 'Transmission']}
        onChange={(value) => setInput({bevVoltage: value})}
      />
      <p className="small mt-2 mb-0">
        Secondary voltage is best suited for small and medium projects. Primary and transmission
        voltages are best suited for larger projects.
        <br />
        <br />
        For more information on Primary and Transmission voltage, please visit{' '}
        <a href="https://pge.com/businessevrate">https://pge.com/businessevrate</a>
      </p>
    </>
  );
};

export default SelectVoltage;
