import React from 'react';
import PropTypes from 'prop-types';
import {useInputs} from '@bellawatt/use-inputs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectVehicleCategory from '../inputs/components/SelectVehicleCategory';
import SelectVehicleType from '../inputs/components/SelectVehicleType';
import SelectVehicleSubType from '../inputs/components/SelectVehicleSubType';
import VehicleImage from '../graphs/VehicleImage';
import ASSUMPTIONS from '../data/ASSUMPTIONS';
import {formatAsPercentRounded} from '../utils/format';
import SelectVehicleMakeModel from '../inputs/components/SelectVehicleMakeModel';

const RenderDetail = ({title, val}) => (
  <p className="mb-0">
    {val} <span style={{color: 'rgba(0,0,0,0.5)'}}>{title}</span>
  </p>
);

const SelectVehicle = ({changeParam, archetype, ...vehicle}) => {
  const {vehicles} = useInputs();
  const {category, type, subtype, name} = vehicle;

  const categoryFilteredVehicles = vehicles.filter((vehicle) => vehicle.category === category);
  const categoryTypeFilteredVehicles = categoryFilteredVehicles.filter(
    (vehicle) => vehicle.type === type
  );
  const categoryTypeSubtypeFilteredVehicles = categoryTypeFilteredVehicles.filter(
    (vehicle) => vehicle.subtype === subtype
  );

  const shouldShowType = category !== 'TRU';

  const vehicleIsOnRoad = vehicle.category === 'On-Road';
  const range = vehicleIsOnRoad ? `${vehicle.rangeInMiles} mile` : `${vehicle.rangeInHours} hour`;
  const efficiencyTitle = vehicleIsOnRoad
    ? 'miles/kWh'
    : 'hours/kWh'.concat(
        vehicle.category === 'TRU'
          ? `, including a ${formatAsPercentRounded(ASSUMPTIONS.TRU_IDLING_FACTOR)} idling factor`
          : ''
      );
  const idling = vehicle.category === 'TRU' ? 1 - ASSUMPTIONS.TRU_IDLING_FACTOR : 1;
  const efficiency = vehicleIsOnRoad
    ? (1 / (vehicle.batteryCapacityInKwh / vehicle.rangeInMiles)).toFixed(2)
    : (1 / ((vehicle.batteryCapacityInKwh * idling) / vehicle.rangeInHours)).toFixed(2);
  const fossilFuelTitle = vehicleIsOnRoad
    ? `mpg ${archetype.fossilFuelType} for an equivalent fossil vehicle`
    : `gallons ${archetype.fossilFuelType} per hour for an equivalent fossil vehicle`;
  const fossilFuel = vehicleIsOnRoad ? archetype.fossilMilesPerGallon : archetype.fossilGallonsPerHour;

  return (
    <Row>
      <Col sm={5}>
        <SelectVehicleCategory
          value={category}
          onChange={(newCategory) => {
            changeParam({
              vehicle_id: vehicles.find((vehicle) => vehicle.category === newCategory).id,
            });
          }}
        />
        {shouldShowType && (
          <SelectVehicleType
            value={type}
            vehicles={categoryFilteredVehicles}
            onChange={(newType) => {
              changeParam({
                vehicle_id: categoryFilteredVehicles.find((vehicle) => vehicle.type === newType).id,
              });
            }}
          />
        )}
        <SelectVehicleSubType
          value={subtype}
          vehicles={categoryTypeFilteredVehicles}
          onChange={(newSubtype) =>
            changeParam({
              vehicle_id: categoryTypeFilteredVehicles.find(
                (vehicle) => vehicle.subtype === newSubtype
              ).id,
            })
          }
        />
        <SelectVehicleMakeModel
          value={name}
          vehicles={categoryTypeSubtypeFilteredVehicles}
          onChange={(newName) =>
            changeParam({
              vehicle_id: categoryTypeSubtypeFilteredVehicles.find(
                (vehicle) => vehicle.name === newName
              ).id,
            })
          }
        />
      </Col>
      <Col sm={7}>
        <Row>
          <Col>
            <VehicleImage vehicle={vehicle} />
          </Col>
          <Col className="d-flex">
            <div className="align-self-center">
              <RenderDetail title="range" val={range} />
              <RenderDetail title="capacity" val={`${vehicle.batteryCapacityInKwh} kWh`} />
              <RenderDetail title={efficiencyTitle} val={efficiency} />
              <hr />
              <RenderDetail title={fossilFuelTitle} val={fossilFuel} />
              <hr />
              <p className="mb-0">
                <span style={{color: 'rgba(0,0,0,0.5)'}}>Weight Class: </span>
                {vehicle.weightClass}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SelectVehicle.header = 'Select a Vehicle';

SelectVehicle.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
  category: PropTypes.string,
  changeParam: PropTypes.func,
};

RenderDetail.propTypes = {
  title: PropTypes.string,
  val: PropTypes.node,
};

export default SelectVehicle;
