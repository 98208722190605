import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import Slider from '../Slider';

const SlideDieselPrice = () => {
  const {dieselPrice, setInput} = useInputs();

  return (
    <Slider
      label="Diesel Price ($/Gal)"
      min={1}
      max={10}
      step={0.1}
      value={dieselPrice}
      onChange={(dieselPrice) => setInput({dieselPrice})}
    />
  );
};

export default SlideDieselPrice;
