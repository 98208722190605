import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {useInputs} from '@bellawatt/use-inputs';
import Card from '../../../components/InputCard';
import VehicleSets from '../../../inputs/VehicleSets';
import SelectElectricCarbonIntensity from '../../../inputs/components/SelectElectricCarbonIntensity';
import SlideDieselPrice from '../../../inputs/components/SlideDieselPrice';
import SlideGasolinePrice from '../../../inputs/components/SlideGasolinePrice';
import SlideLpgPrice from '../../../inputs/components/SlideLpgPrice';
import calcGallonsOfFossilFuel from '../../../calcs/calcGallonsOfFossilFuel';
import ResetInputs from './ResetInputs';
import PrintButton from '../../../components/PrintButton';

const Inputs = () => {
  const {vehicleSets} = useInputs();

  return (
    <>
      <Row>
        <Col>
          <Card header="Vehicles" closeable startOpen>
            <VehicleSets />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card header="Fuel" closeable startOpen>
            {calcGallonsOfFossilFuel(vehicleSets, 'diesel') > 0 && <SlideDieselPrice />}
            {calcGallonsOfFossilFuel(vehicleSets, 'regular') > 0 && (
              <SlideGasolinePrice />
            )}
            {calcGallonsOfFossilFuel(vehicleSets, 'lpg') > 0 && <SlideLpgPrice />}
            <SelectElectricCarbonIntensity />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card header="Assumptions" closeable>
            <div
              style={{
                backgroundColor: '#dbdbdb',
                padding: '20px 20px 1px 20px',
                marginTop: '20px',
              }}
            >
              <p>
                This calculation is for vehicles for one facility, and assumes a 1-to-1 ratio when
                replacing fossil fuel vehicles with their electric counterparts.
              </p>
              <p>
                This also assumes steady year-long operations, with no variability week to week.
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <ResetInputs />
        </Col>
        <Col className="d-none d-sm-block">
          <PrintButton />
        </Col>
      </Row>
    </>
  );
};

export default Inputs;
