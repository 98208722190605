import b6Rate from './b6Rate';
import b10Rate from './b10Rate';
import b19Rate from './b19Rate';
import b20Rate from './b20Rate';

const getRates = ({voltage}) => [
  b6Rate(),
  b10Rate({voltage}),
  b19Rate({voltage}),
  b20Rate({voltage}),
];

// Returns false if a rate's max kW is met for 3 consecutive months
// according to https://docs.google.com/spreadsheets/d/194i_6H7-ckuB6uCUK5QGjWmVSG0ebVz-QK9RyI8J-sQ/edit#gid=309346728
const isRateApplicable = (rate, loadProfile, {ignoreMaxKw}) => {
  const {maxKw} = rate;
  if (ignoreMaxKw || maxKw === undefined) return true;

  const maxByMonth = loadProfile.maxByMonth();
  const exceedsMaxForThreeMonths = maxByMonth.some((month, idx, arr) => {
    // wrap around to beginning of the year for November/December
    const nextMonth = idx + 1 < arr.length ? arr[idx + 1] : arr[0];
    const monthAfterNext =
      // eslint-disable-next-line no-nested-ternary
      idx + 2 < arr.length ? arr[idx + 2] : idx + 2 === arr.length ? arr[0] : arr[1];
    return month > maxKw && nextMonth > maxKw && monthAfterNext > maxKw;
  });

  return !exceedsMaxForThreeMonths;
};

const getApplicableRates = (loadProfile, {voltage, ignoreMaxKw}) =>
  getRates({voltage}).filter((rate) => isRateApplicable(rate, loadProfile, {ignoreMaxKw}));

export default getApplicableRates;
