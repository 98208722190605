import React from 'react';

const PrintButton = () => {
  const print = () => {
    window.print();
  };

  return (
    <div className="text-center">
      <button type="button" className="btn btn-outline-secondary" onClick={print}>
        Print
      </button>
    </div>
  );
};

export default PrintButton;
