/* eslint-disable camelcase */
import React, {useState} from 'react';
import Truncate from 'react-truncate';
import {colors} from '../styles';
import {formatAsDollars, titleCase} from '../utils/format';

const LabelledDetail = ({label, children, shouldKeepFormatting}) =>
  children ? (
    <div className="mb-2">
      <div className="text-muted font-weight-lighter">{label}</div>
      <div className="font-weight-bold">
        {shouldKeepFormatting ? children : titleCase(children.replace(/_/g, ' '))}
      </div>
    </div>
  ) : null;

const DetailedGrantCard = ({
  incentive_name,
  typical_amount,
  typical_amount_description,
  grantor_name,
  incentive_description,
  incentive_url,
  incentive_application_url,
  total_funding,
  eligible_organizations,
  eligible_vehicle_type_description,
  current_funding_status,
  funding_cap_per_applicant,
  current_funding,
  expiration_date,
}) => {
  const [truncated, setTruncated] = useState(true);

  const Ellipsis = () => (
    <span>
      ...{' '}
      <button
        onClick={() => setTruncated(false)}
        className="d-inline btn btn-xs btn-link p-0"
        type="button"
      >
        more
      </button>
    </span>
  );

  const isAcceptingApplications = current_funding_status === 'funded';

  return (
    <div
      className="card my-2"
      style={{borderRadius: 0, borderLeft: `5px solid ${isAcceptingApplications ? colors.brand.blue : colors.grey.lighter}`}}
    >
      <div className="card-body p-4">
        <div className="d-flex text-uppercase justify-content-between mb-4 mt-1">
          <div className="font-weight-500">{grantor_name}</div>
          <div>
            <span className="d-none d-sm-block">
              Status / <strong>{isAcceptingApplications ? 'Accepting Applications' : current_funding_status}</strong>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12 mb-3">
            <p className="h4 mb-4">{typical_amount === 'N/A' ? null : typical_amount}</p>
            <div className="font-weight-500 h5 mb-4">{incentive_name}</div>
            <div>
              <Truncate lines={truncated && 4} ellipsis={<Ellipsis />}>
                {incentive_description}
                <br />
                <div className="text-muted font-weight-lighter mt-3">
                  Typical Amount Description
                </div>
                {typical_amount_description}
              </Truncate>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="row">
              <div className="col-md-8">
                <div className="text-uppercase font-weight-500 mb-3">Details</div>
                <LabelledDetail label="Closing Date">{expiration_date}</LabelledDetail>
                <LabelledDetail label="Industry">
                  {eligible_organizations[0] === '' ? 'All' : eligible_organizations.join(', ')}
                </LabelledDetail>
                <LabelledDetail label="Total Available">
                  {total_funding ? formatAsDollars(total_funding) : null}
                </LabelledDetail>
                <LabelledDetail label="Funding To Date">
                  {current_funding ? formatAsDollars(current_funding) : null}
                </LabelledDetail>
                <LabelledDetail label="Max Per Applicant">
                  {funding_cap_per_applicant ? formatAsDollars(funding_cap_per_applicant) : null}
                </LabelledDetail>
                <LabelledDetail label="Applicability" shouldKeepFormatting={true}>
                  {eligible_vehicle_type_description}
                </LabelledDetail>
              </div>

              <div className="col-md-4 text-right">
                <div className="text-uppercase font-weight-500 mb-3">Resources</div>
                {incentive_url && (
                  <div className="mb-2">
                    <a href={incentive_url} target="_blank" rel="noopener noreferrer">
                      More Information
                    </a>
                  </div>
                )}
                {incentive_application_url && (
                  <div className="mb-2">
                    <a href={incentive_application_url} target="_blank" rel="noopener noreferrer">
                      Application
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedGrantCard;
