import BEV from '../data/BEV';

const calcIdealBevBlocks = (rateName, weeklyLoadProfile) => {
  const currentSubscription = BEV.tariff_elements.pge_subscription_rate.find(
    (subscription) => subscription.name === rateName
  );

  return Math.ceil(Math.max(...weeklyLoadProfile) / currentSubscription.blockSizeInKw);
};

export default calcIdealBevBlocks;
