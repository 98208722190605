import React from 'react';
import {useInputs} from '@bellawatt/use-inputs';
import {formatAsDollarsRounded, formatAsDollars, formatVehicleName} from '../utils/format';
import BaseGraph from './BaseGraph';
import A from '../data/ASSUMPTIONS';
import calcAnnualInsuranceCost from '../calcs/calcAnnualInsuranceCost';
import uniqueLabels from '../utils/uniqueLabels';

const Insurance = () => {
  const {vehicleSets} = useInputs();

  const insuranceCosts = vehicleSets.map((vehicleSet) => {
    const {vehicle, archetype} = vehicleSet;

    return {
      vehicleSet,
      vehicleCost: calcAnnualInsuranceCost(vehicle.msrp),
      fossilEquivalentCost: calcAnnualInsuranceCost(archetype.fossilFuelMsrp),
    };
  });

  const data = {
    labels: ['Electric Vehicles', 'Fossil Vehicles'],
    datasets: uniqueLabels(
      insuranceCosts.map(({vehicleCost, fossilEquivalentCost, vehicleSet}, i) => ({
        label: formatVehicleName(vehicleSet),
        data: [vehicleCost, fossilEquivalentCost],
        backgroundColor: [
          A.ELECTRICITY_SET_COLORS[i % A.ELECTRICITY_SET_COLORS.length],
          A.OIL_SET_COLORS[i % A.OIL_SET_COLORS.length],
        ],
      }))
    ),
  };

  return (
    <>
      <BaseGraph
        title="Annual Insurance Costs"
        data={data}
        stacked
        yFormatCallback={formatAsDollarsRounded}
        tooltipCallback={formatAsDollars}
        options={{
          // tooltips: {
          //   filter: (tooltipItem) =>
          //     !(tooltipItem.index === 2 && tooltipItem.datasetIndex === fossilSets.length + 1),
          // },
          legend: {labels: {filter: (legendItem) => legendItem.text !== 'hidden'}},
        }}
      />
    </>
  );
};

export default Insurance;
