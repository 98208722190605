const fixedCharges = {
  Secondary: 58.30343,
  Primary: 60.18647,
  Transmission: 164.02382,
};

const demandPeakSummerCharges = {
  Secondary: 30.06,
  Primary: 32.41,
  Transmission: 20.62,
};

const demandPartPeakSummerCharges = {
  Secondary: 6.13,
  Primary: 6.31,
  Transmission: 4.91,
};

const demandSummerCharges = {
  Secondary: 28.04,
  Primary: 25.44,
  Transmission: 15.91,
};

const demandPeakWinterCharges = {
  Secondary: 2.24,
  Primary: 2.27,
  Transmission: 2.75,
};

const demandWinterCharges = {
  Secondary: 28.04,
  Primary: 25.44,
  Transmission: 15.91,
};

const energyPeakSummerCharges = {
  Secondary: 0.18675,
  Primary: 0.18268,
  Transmission: 0.16096,
};

const energyPartPeakSummerCharges = {
  Secondary: 0.15333,
  Primary: 0.14664,
  Transmission: 0.13822,
};

const energyOffPeakSummerCharges = {
  Secondary: 0.12693,
  Primary: 0.12151,
  Transmission: 0.11285,
};

const energyPeakWinterCharges = {
  Secondary: 0.16684,
  Primary: 0.15974,
  Transmission: 0.15485,
};

const energyOffPeakWinterCharges = {
  Secondary: 0.12672,
  Primary: 0.12157,
  Transmission: 0.10897,
};

const energySuperOffPeakWinterCharges = {
  Secondary: 0.07297,
  Primary: 0.06742,
  Transmission: 0.06315,
};

const b20Rate = ({voltage}) => ({
  name: 'B-20',
  title: 'B-20 Medium General Demand-Metered TOU Service',
  rateElements: [
    {
      rateElementType: 'FixedPerDay',
      classification: 'fixed',
      rateComponents: [
        {
          name: 'Total Customer Charge Rates: Customer Charge Mandatory B-20 ($ per meter per day)',
          charge: fixedCharges[voltage],
        },
      ],
    },
    {
      rateElementType: 'DemandTimeOfUse',
      classification: 'demand',
      rateComponents: [
        {
          name: 'Maximum Peak Demand Summer ($ per kW)',
          charge: demandPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Maximum Part-Peak Demand Summer ($ per kW)',
          charge: demandPartPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [14, 15, 21, 22],
        },
        {
          name: 'Maximum Demand Summer ($ per kW)',
          charge: demandSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ],
        },
        {
          name: 'Maximum Peak Demand Winter ($ per kW)',
          charge: demandPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Maximum Demand Winter ($ per kW)',
          charge: demandWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
          ],
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      classification: 'energy',
      rateComponents: [
        {
          name: 'Total Energy Rates: Peak Summer ($ per kWh)',
          charge: energyPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates: Part-Peak Summer ($ per kWh)',
          charge: energyPartPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [14, 15, 21, 22],
        },
        {
          name: 'Total Energy Rates: Off-Peak Summer ($ per kWh)',
          charge: energyOffPeakSummerCharges[voltage],
          months: [5, 6, 7, 8],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 23],
        },
        {
          name: 'Total Energy Rates: Peak Winter ($ per kWh)',
          charge: energyPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [16, 17, 18, 19, 20],
        },
        {
          name: 'Total Energy Rates: Off-Peak Winter ($ per kWh)',
          charge: energyOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [9, 10, 11, 12, 13],
        },
        {
          name: 'Total Energy Rates: Super Off-Peak Winter ($ per kWh)',
          charge: energySuperOffPeakWinterCharges[voltage],
          months: [0, 1, 2, 3, 4, 9, 10, 11],
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 14, 15, 21, 22, 23],
        },
      ],
    },
  ],
});

export default b20Rate;
