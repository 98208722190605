const dcfcLoadProfile = [
  198.9,
  138.9,
  105.6,
  92.0,
  92.0,
  131.8,
  230.8,
  403.1,
  651.8,
  1147.4,
  1269.4,
  1324.4,
  1400.1,
  1390.0,
  1209.7,
  1174.8,
  895.0,
  843.3,
  820.0,
  732.3,
  639.5,
  624.0,
  410.4,
  261.0,
];

export default dcfcLoadProfile;
