import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import uniq from 'lodash/uniq';

const SelectVehicleType = ({value, onChange, vehicles}) => {
  const types = uniq(
    vehicles
      .sort((a, b) => {
        if (a.type > b.type || a.fromApi) {
          return 1;
        }

        if (a.type < b.type || b.fromApi) {
          return -1;
        }

        return 0;
      })
      .map((vehicle) => vehicle.type)
  );

  return <Select label="Type" value={value} optionValues={types} onChange={onChange} />;
};

SelectVehicleType.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  vehicles: PropTypes.array,
};

export default SelectVehicleType;
