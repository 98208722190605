export default function uniqueLabels(datasets) {
  const countMap = {};

  const countedLabels = datasets.map(({label}) => {
    // eslint-disable-next-line no-multi-assign
    const labelCount = (countMap[label] = countMap[label] ? countMap[label] + 1 : 1);

    return `${label} (${labelCount})`;
  });

  return datasets.map((dataset, i) => {
    return {
      ...dataset,
      label: countMap[dataset.label] > 1 ? countedLabels[i] : dataset.label,
    };
  });
}
