import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {Arrow} from '../icons';
import VehicleCatalog from './VehicleCatalog';

const VehicleCatalogModal = ({isOpen, handleClose, selectVehicle}) => {
  const selectAndClose = (vehicle) => {
    selectVehicle(vehicle);
    handleClose();
  };

  return (
    <Modal
      animation={false}
      backdropClassName="transparent-backdrop"
      show={isOpen}
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton style={{borderBottom: 0}}>
        <button
          onClick={handleClose}
          className="btn btn-link text-dark text-decoration-none"
          type="button"
        >
          <div className="h5">
            <Arrow direction="left" />
            Vehicle Catalog
          </div>
        </button>
      </Modal.Header>
      <Modal.Body style={{padding: '0 2rem'}}>
        <VehicleCatalog onClick={selectAndClose} rowClassName="row row-cols-1 row-cols-sm-2" />
      </Modal.Body>
    </Modal>
  );
};

VehicleCatalogModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  selectVehicle: PropTypes.func,
};

export default VehicleCatalogModal;
