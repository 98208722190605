import {useInputs} from '@bellawatt/use-inputs';
import React from 'react';
import moment from 'moment';
import VehicleImage from '../../../graphs/VehicleImage';
import Arrow from '../../../icons/Arrow';

const VehiclesToChargers = () => {
  const {vehicleSets} = useInputs();

  return (
    <div className="table-responsive">
      <table className="table table-borderless">
        <thead className="border-bottom">
          <tr>
            <th colSpan="3">Vehicles</th>
            <th colSpan="2">Charger Type</th>
            <th>Time Range</th>
          </tr>
        </thead>
        {vehicleSets.map(
          ({type, category, subtype, vehicleCount, chargingWindows, vehicle}, i) => (
            <tbody key={i}>
              <tr>
                <td className="align-middle" rowSpan="0">
                  <VehicleImage vehicle={vehicle} />
                </td>
                <td rowSpan="0" className="align-middle text-muted">
                  <span className="text-nowrap">
                    x{vehicleCount} {type}
                  </span>{' '}
                  <span className="text-nowrap">{subtype}</span>
                </td>
                <td className="align-middle" rowSpan="0">
                  <Arrow direction="right" />
                </td>
              </tr>

              {chargingWindows.map(({start, finish, type}, j) => (
                <tr key={j} className="border-bottom">
                  <td className="align-middle">{type}</td>
                  <td className="align-middle">
                    <Arrow direction="right" />
                  </td>
                  <td className="align-middle text-nowrap">
                    {moment(start, 'H').format('ha')} - {moment(finish, 'H').format('ha')}
                  </td>
                </tr>
              ))}
            </tbody>
          )
        )}
      </table>
    </div>
  );
};

export default VehiclesToChargers;
