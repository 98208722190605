export const colors = {
  blue: {
    darkest: '#031A2D',
    darker: '#042037',
    base: '#005179',
    lighter: '#0089C4',
    lightest: '#B8D7ED',
  },
  orange: {
    darkest: '#BF7E2A',
    darker: '#E59902',
    base: '#FBBB36',
    lighter: '#FFD992',
    lightest: '#FEEDC7',
  },
  grey: {
    darkest: '#111111',
    darker: '#333333',
    base: '#666666',
    lighter: '#CCCCCC',
    lightest: '#F1F1F1',
  },
  green: {
    darkest: '#749D25',
    darker: '#97B068',
    base: '#CFDBB7',
    lighter: '#E3EBD3',
    lightest: '#FFFFFF',
  },
  red: {
    darkest: '#9E2D16'
  },
  text: {
    base: '#333333',
  },
  brand: {
    orange: '#FBBB36',
    darkBlue: '#07679B',
    blue: '#0089C4',
  },
  white: '#FFF',
};

export const chartColors = [colors.brand.darkBlue, colors.blue.lightest, colors.brand.orange];

export const typography = {
  headers: ['', '60px', '32px', '24px', '20px', '16px', '14px'],
  cta: '16px',
  menu: '14px',
  label: '14px',
  input: '14px',
  statLabel: '12px',
  body: {
    primary: '24px',
    secondary: '20px',
    tertiary: '12px',
  },
  links: {
    primary: '16px',
    secondary: '12px',
  },
  weight: {
    normal: 400,
    bold: 500,
    bolder: 'bold',
  },
};
