import calcResaleValue from './calcResaleValue/calcResaleValue';
import {sum, times, unzip} from 'lodash';
import calcAnnualElectricityCost from './calcAnnualElectricityCost';
import calcLcfsCreditsByYear from './calcLcfsCreditsByYear';
import calcMaintenanceCostByYear from './calcMaintenanceCostByYear';
import calcAnnualInsuranceCost from './calcAnnualInsuranceCost';

const calcElectricTcoByYear = (
  yearsOfOperation,
  vehicleSets,
  chargers,
  bevRateName,
  bevBlocks,
  gridIntensityInGramsCO2ePerMJ,
  btmConstructionCost,
  btmEvseCost,
  incentives
) => {
  const vehicleCosts = sum(
    vehicleSets.map(({vehicle, vehicleCount}) => vehicleCount * vehicle.msrp)
  );

  const resaleValues = sum(
    vehicleSets.map(
      ({vehicle, vehicleCount}) =>
        vehicleCount * calcResaleValue(vehicle.msrp, vehicle.duty, yearsOfOperation)
    )
  );

  const annualElectricityCost = calcAnnualElectricityCost(
    chargers,
    vehicleSets,
    bevRateName,
    bevBlocks,
    true
  );

  const lcfsByYear = calcLcfsCreditsByYear(
    chargers,
    vehicleSets,
    gridIntensityInGramsCO2ePerMJ
  );

  const maintenance = unzip(
    vehicleSets.map((vehicleSet) => calcMaintenanceCostByYear({vehicleSet, yearsOfOperation}))
  ).map((arr) => sum(arr));

  const insurance = sum(vehicleSets.map(({vehicle}) => calcAnnualInsuranceCost(vehicle.msrp)));

  const construction = btmConstructionCost + btmEvseCost;

  return times(yearsOfOperation, (year) => {
    const annualCosts =
      annualElectricityCost +
      maintenance[year] +
      insurance -
      (year < lcfsByYear.length ? lcfsByYear[year] : 0);

    if (year === 0) {
      return vehicleCosts + construction - incentives + annualCosts;
    }

    if (year === yearsOfOperation - 1) {
      return annualCosts - resaleValues;
    }

    return annualCosts;
  });
};

export default calcElectricTcoByYear;
