import React from 'react';
import VehiclesWithTheirChargersTable from '../../../graphs/VehiclesWithTheirChargersTable';

const Vehicles = () => {
  return (
    <>
      <div className="h2 text-center d-none d-print-block">Vehicles</div>
      <p className="takeaway">
        The selected vehicles and their corresponding details is shown below.
      </p>
      <VehiclesWithTheirChargersTable />
    </>
  );
};

export default Vehicles;
