import React from 'react';
import PropTypes from 'prop-types';
import Honeybadger from '@honeybadger-io/js';
import HoneybadgerErrorBoundary from '@honeybadger-io/react';
import ErrorPage from '../pages/ErrorPage';

export const ConfiguredHoneybadger = Honeybadger.configure({
  apiKey: '2c8ff712',
  environment: process.env.REACT_APP_ENV || 'development',
});

const ErrorBoundary = ({children}) => (
  <HoneybadgerErrorBoundary honeybadger={ConfiguredHoneybadger} ErrorComponent={ErrorPage}>
    {children}
  </HoneybadgerErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
