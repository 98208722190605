import React from 'react';
import PropTypes from 'prop-types';

const Down = () => (
  <svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="PGE-Fleets_Final"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="New-Vehicle-Set-Alt" transform="translate(-626.000000, -1051.000000)" stroke="#485465">
        <g id="Group-8" transform="translate(99.000000, 1034.000000)">
          <g id="ic_down" transform="translate(526.000000, 13.000000)">
            <g
              transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "
              id="ic_back"
            >
              <g strokeWidth="2">
                <g id="back" transform="translate(5.333333, 2.666667)">
                  <line x1="5.33333333" y1="0" x2="0" y2="5.33333333" id="Line"></line>
                  <line x1="0" y1="5.33333333" x2="5.33333333" y2="10.6666667" id="Line"></line>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Up = () => (
  <svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_up carat</title>
    <g
      id="PGE-Fleets_Final"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="Mobile---Calculator" transform="translate(-249.000000, -230.000000)" stroke="#485465">
        <g
          id="ic_down"
          transform="translate(256.000000, 234.000000) scale(1, -1) translate(-256.000000, -234.000000) translate(248.000000, 226.000000)"
        >
          <g
            transform="translate(8.000000, 8.000000) rotate(-90.000000) translate(-8.000000, -8.000000) "
            id="ic_back"
          >
            <g strokeWidth="2">
              <g id="back" transform="translate(5.333333, 2.666667)">
                <line x1="5.33333333" y1="0" x2="0" y2="5.33333333" id="Line"></line>
                <line x1="0" y1="5.33333333" x2="5.33333333" y2="10.6666667" id="Line"></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const Caret = ({direction}) => {
  if (direction !== 'up' && direction !== 'down') {
    console.error(`Invalid direction supplied to caret: ${direction}. Expected up or down.`);
  }

  return direction === 'up' ? <Up /> : <Down />;
};

Caret.propTypes = {
  direction: PropTypes.oneOf(['up', 'down']),
};

export default Caret;
