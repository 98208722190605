import times from 'lodash/times';
import isHourInUse from './isHourInUse';

const groupByChargers = (vehicleSets) => {
  const chargingSets = {};
  vehicleSets.forEach(({chargingWindows, ...vehicle}) => {
    chargingWindows.forEach((chargingWindow) => {
      const vehicleChargingUsage = {
        ...vehicle,
        usage: times(24, (hour) =>
          isHourInUse(hour, chargingWindow.start, chargingWindow.finish)
            ? parseInt(vehicle.vehicleCount)
            : 0
        ),
      };
      chargingSets[chargingWindow.type] = {
        ...chargingWindow,
        ...chargingWindow.charger,
        vehicles: chargingSets[chargingWindow.type]
          ? [...chargingSets[chargingWindow.type].vehicles, vehicleChargingUsage]
          : [vehicleChargingUsage],
      };
    });
  });

  Object.keys(chargingSets).forEach((key) => {
    const summedHours = chargingSets[key].vehicles.reduce(
      (sum, {usage}) => {
        return sum.map((val, i) => val + usage[i]);
      },
      times(24, () => 0)
    );

    chargingSets[key].minimumRequired = Math.ceil(
      Math.max(...summedHours) / chargingSets[key].ports
    );
  });

  return Object.values(chargingSets);
};

export default groupByChargers;
