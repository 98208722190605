import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {typography} from '../styles';

const getTrackedFields = ({type, subtype, vehicleCount, milesPerWorkday}) => {
  return {
    vehicle: `${type} ${subtype}`,
    count: parseInt(vehicleCount, 10),
    milesPerWorkday: parseInt(milesPerWorkday, 10),
  };
};

const TrackableButton = ({children, vehicleSet, onClick, ...buttonProps}) => {
  const sendEvent = (e) => {
    e.preventDefault();

    const values = getTrackedFields(vehicleSet);

    Object.keys(values).forEach((action) => {
      const payload = {
        category: 'Vehicle Set Changed',
        action: `Modified ${action}`,
      };

      if (typeof values[action] === 'number') {
        payload.value = values[action];
      } else {
        payload.label = values[action];
      }

      ReactGA.event(payload);
    });

    onClick(e);
  };

  return (
    <button
      type="button"
      className="btn btn-primary"
      style={{
        width: '100%',
        fontSize: typography.cta,
        fontWeight: typography.weight.bolder,
        padding: '10px 20px',
        marginLeft: '10px',
        whiteSpace: 'nowrap',
      }}
      {...buttonProps}
      onClick={sendEvent}
    >
      {children}
    </button>
  );
};

TrackableButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  vehicleSet: PropTypes.object,
};

export default TrackableButton;
